import React from "react";
import Flex from "../../components/flex/Flex";
import { P } from "../../components/styles/Typography.styles";

const ListingItem = ({
    name,
    weight = "rg",
    value,
    margin = ".5rem",
    color = "black",
}) => {
    return (
        <Flex.Container
            style={{
                marginBottom: margin,
            }}
            justifyContent="space-between"
            alignItems="center"
        >
            <Flex.Item flex="1" style={{ marginRight: ".1rem" }}>
                <P className="name" weight={weight} color={color}>
                    {name}
                </P>
            </Flex.Item>
            <Flex.Item>
                <P
                    lineHeight="1.3em"
                    className="value"
                    fontFamily="Montserrat"
                    size="m"
                >
                    {value}
                </P>
            </Flex.Item>
        </Flex.Container>
    );
};

export default ListingItem;
