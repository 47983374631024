import moment from "moment";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IMAGES } from "../assets";

export const getProjectDetailsData = (listing) => {
    let plants = listing?.plants || [];

    let fundLimit =
        listing.fundLimit === undefined || isNaN(listing?.fundLimit)
            ? 0
            : Number(listing?.fundLimit / 100000).toFixed(1);

    let fundRaised =
        listing.fundRaised === undefined || isNaN(listing?.fundRaised)
            ? 0
            : Number(listing?.fundRaised / 100000).toFixed(1);
    let startDate = Number(listing?.startDate) * 1000 || 0;
    let details = {
        heading: plants[0]?.name,
        about: plants[0]?.description,
        images: listing?.images.length > 0 ? listing?.images : IMAGES,
        fundLimit: fundLimit,
        fundRaised: fundRaised,
        IRR: listing?.investmentTerms[0]?.targetedIRRPercentage || 0,
        listingMinLimit: Number(listing?.listingMinLimit) || 0,
        progress: Number((fundRaised / fundLimit) * 100).toFixed(2) || 0,
    };
    let tagItem = [
        {
            value: plants[0]?.name,
            name: plants[0]?.city,
            icon: FaMapMarkerAlt,
        },
        {
            value: Number(plants[0]?.capacity) / 1000 || 0,
            name: "Solar Capacity",
            unit: "KWp",
        },
        {
            value: moment(startDate).format("Do MMM YYYY"),
            name: "Funding Date",
        },
    ];
    return { tagItem, details };
};
