import React from "react";
import { PROJECTS } from "../../constants/routes";
import useRecentNotification from "../../hooks/useRecentNotification";
import ProjectDetailsWrap from "../ProjectDetailsWrap";

const ProjectDetails = () => {
    useRecentNotification();
    // const { content, notification, setProtectNew } = useProjectsNotification();
    return (
        <>
            <ProjectDetailsWrap link={PROJECTS} />
            {/* <NotifyToast
                content={content}
                isActive={notification}
                delay={5}
                onChange={setProtectNew}
            /> */}
        </>
    );
};

export default ProjectDetails;
