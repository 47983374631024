import { AnimatePresence } from "framer-motion";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { IMAGE } from "../../assets";
import Space from "../../components/Spacing/Space";
import { NoDataMessage } from "../../components/alert/AlertMessage";
import AlertNew from "../../components/alert/AlertNew";
import Button from "../../components/button/Button";
import {
    CarouselContainer,
    FullCarousel,
} from "../../components/carousel/Carousel";
import Flex from "../../components/flex/Flex";
import Container from "../../components/styles/Container";
import { HideMobile, ShowMobile } from "../../components/styles/HideSection";
import Text from "../../components/text/Text";
import { OPPORTUNITY_ENDPOINT, PROJECTS } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useNetwork from "../../hooks/useNetwork";
import ProjectCard, { CardContainer } from "../ProjectCard";

const FUNDING = "FUNDING";
const COMPLETED = "COMPLETED";

export const getProjectData = (listingItem) => {
    let plants = listingItem?.plants || [];
    let investmentTerms = listingItem?.investmentTerms || [];
    let startDate = listingItem?.startDate * 1000 || 0;
    let fundSize =
        listingItem.fundLimit === undefined || isNaN(listingItem?.fundLimit)
            ? 0
            : Number(listingItem?.fundLimit);
    let fundLimit = Number((fundSize / 100000).toFixed(1));
    let fundRaised =
        listingItem.fundRaised === undefined || isNaN(listingItem?.fundRaised)
            ? 0
            : Number(listingItem?.fundRaised / 100000).toFixed(1);
    let minLimit =
        listingItem.listingMinLimit === undefined ||
        isNaN(listingItem?.listingMinLimit)
            ? 0
            : Number(listingItem?.listingMinLimit);
    let listingMinLimit = minLimit.toLocaleString();
    let listingMaxLimit =
        listingItem.listingMaxLimit === undefined ||
        isNaN(listingItem?.listingMaxLimit)
            ? 0
            : Number(listingItem?.listingMaxLimit);
    let details = [
        {
            name: "Total Investment",
            value: `${fundLimit} Lakhs`,
        },
        {
            name: "Investment Raised",
            value: `${fundRaised} Lakhs`,
        },

        {
            name: "Investment Term",
            value: `${
                Number(investmentTerms[0]?.installments) / 12 || 0
            } Years`,
        },
        {
            name: "Return (IRR)",
            value: `${investmentTerms[0]?.targetedIRRPercentage || 0} %`,
        },
        {
            name: "Minimum Investment",
            value: `${listingMinLimit}`,
        },
    ];
    return {
        id: listingItem?.listingId,
        name: plants[0]?.name || "",
        status: listingItem?.listingStatus || "",
        city: plants[0]?.city || "",
        state: plants[0]?.state || "",
        isOpen: Date.now() > startDate,
        startDate,
        listingMaxLimit,
        listingMinLimit: minLimit,
        fundSize,
        waitingList: listingItem?.waitingList || null,
        thumbnail: listingItem?.thumbnail || IMAGE,
        progress: Number((fundRaised / fundLimit) * 100).toFixed(2) || 0,
        tagItem: [
            {
                id: 1,
                value: (plants[0]?.capacity || 0) / 1000,
                name: "Solar Capacity",
                unit: "KWp",
            },
            {
                id: 2,
                value: `${moment(startDate).format(
                    "DD MMM YYYY"
                )}`.toLocaleUpperCase(),
                name: "Starting Date",
                unit: "",
            },
        ],
        details,
    };
};

const OurProjects = () => {
    const { state } = useContextState();

    const { error, data, loading } = useNetwork(
        OPPORTUNITY_ENDPOINT,
        null,
        null,
        state.user !== null
    );

    const [activeListing, setActiveListing] = useState(FUNDING);
    let content = null;
    let upComingContent = null;

    if (!loading && error) {
        content = (
            <Flex.Container justifyContent="center">
                <AlertNew backgroundColor="danger" color="error">
                    {error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (!loading && !error && data?.data?.assets?.length === 0) {
        content = (
            <Flex.Container justifyContent="center">
                <AlertNew backgroundColor="primary" color="white">
                    Projects Not Available
                </AlertNew>
            </Flex.Container>
        );
    }

    if (!loading && !error && data?.data?.assets?.length > 0) {
        let listItem = [];
        let upComingItem = [];
        const filterListing = () => {
            if (activeListing === FUNDING) {
                listItem = data?.data?.assets
                    .filter(
                        (item) =>
                            item.listingStatus === FUNDING &&
                            Date.now() > item?.startDate * 1000
                    )
                    .sort((a, b) => a.listingId - b.listingId)
                    .sort((a, b) => a.startDate - b.startDate);
            } else {
                listItem = data?.data?.assets
                    .filter(
                        (item) =>
                            item.listingStatus !== FUNDING &&
                            Date.now() > item?.startDate * 1000
                    )
                    .sort((a, b) => a.listingId - b.listingId);
            }
            if (listItem.length === 0) {
                return (
                    <NoDataMessage>
                        <Text>
                            No projects available. Please try after sometimes
                        </Text>
                    </NoDataMessage>
                );
            } else {
                return listItem.map((item, i) => (
                    <ProjectCard
                        key={item.listingId}
                        data={getProjectData(item)}
                        link={`${PROJECTS}/${item.listingId}`}
                    />
                ));
            }
        };

        const filterComingSoonListing = () => {
            upComingItem = data?.data?.assets
                .filter(
                    (item) =>
                        item.listingStatus === FUNDING &&
                        Date.now() < item?.startDate * 1000
                )
                .sort((a, b) => a.listingId - b.listingId)
                .sort((a, b) => a.startDate - b.startDate);

            if (upComingItem.length === 0) {
                return null;
            } else {
                return upComingItem.map((item, i) => (
                    <ProjectCard
                        key={item.listingId}
                        data={getProjectData(item)}
                        link={`${PROJECTS}/${item.listingId}`}
                    />
                ));
            }
        };
        upComingContent =
            filterComingSoonListing() === null ? null : (
                <Space xxsm={["3rem 0 0 "]}>
                    <div>
                        <FilterContainer>
                            <TabButton isActive>Upcoming Projects</TabButton>
                        </FilterContainer>
                        <HideMobile>
                            <CardContainer
                                justifyContent={
                                    upComingItem.length > 3
                                        ? "center"
                                        : "flex-start"
                                }
                            >
                                <AnimatePresence>
                                    {filterComingSoonListing()}
                                </AnimatePresence>
                            </CardContainer>
                        </HideMobile>
                        <ShowMobile>
                            <MobileWrapper>
                                <FullCarousel autoPaly={true}>
                                    {filterComingSoonListing()}
                                </FullCarousel>
                            </MobileWrapper>
                        </ShowMobile>
                    </div>
                </Space>
            );

        content = (
            <>
                <FilterContainer>
                    <TabButton
                        onClick={() => setActiveListing(FUNDING)}
                        isActive={activeListing === FUNDING}
                    >
                        Active
                    </TabButton>
                    <TabButton
                        onClick={() => setActiveListing(COMPLETED)}
                        isActive={activeListing === COMPLETED}
                    >
                        Completed
                    </TabButton>
                </FilterContainer>
                <HideMobile>
                    <CardContainer
                        justifyContent={
                            listItem.length > 3 ? "center" : "flex-start"
                        }
                    >
                        <AnimatePresence>{filterListing()}</AnimatePresence>
                    </CardContainer>
                </HideMobile>
                <ShowMobile>
                    <MobileWrapper>
                        <FullCarousel autoPaly={true}>
                            {filterListing()}
                        </FullCarousel>
                    </MobileWrapper>
                </ShowMobile>
            </>
        );
    }

    return (
        <Space xxsm={["4rem 0 10rem"]} tm={["4rem 0 4rem"]}>
            <div>
                <Container>
                    {content}
                    {upComingContent}
                </Container>
            </div>
        </Space>
    );
};

export default OurProjects;

const MobileWrapper = styled.div`
    .card {
        margin: 0 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.23);
        border-radius: 0.3rem;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
    }

    .carousel-root .carousel.carousel-slider {
        overflow: unset;
    }

    /* .carousel-root .carousel .carousel-slider .control-dots {
        margin: 0 0 -17px !important;
    } */

    ${CarouselContainer} {
        .control-dots {
            margin: 0 0 -17px;
        }
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 0px 30px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.grey200};
`;
const TabButton = styled(Button)`
    background: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};

    color: ${(props) =>
        props.isActive ? props.theme.color.white : props.theme.color.black800};
    border-width: 0 0 3px 0;
    border-radius: 0;
    border-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey200};
    :hover,
    :active,
    :focus {
        box-shadow: none;
    }
`;
