const useRecentNotification = () => {
    // const { state, dispatch } = useContextState();

    // const sendRequest = useCallback(() => {
    //     let promise = request.get({
    //         endpoint: RECENT_NOTIFICATION_QUEUE_ENDPOINT,
    //     });

    //     promise
    //         .then((data) => {
    //             const investorId = state?.user?.investorId;

    //             let newNotifyResponse = data?.data?.data;
    //             let allNotifications = [...newNotifyResponse];

    //             const removeDuplicates = (arr) => {
    //                 let unique = [];
    //                 arr.forEach((element) => {
    //                     let isSameInvestor =
    //                         element?.investorId &&
    //                         element?.investorId === investorId;
    //                     if (!isSameInvestor) {
    //                         let isExisting = unique.find((item) => {
    //                             return item?.id === element?.id;
    //                         });

    //                         if (!isExisting) {
    //                             unique.push(element);
    //                         }
    //                     }
    //                 });
    //                 return unique;
    //             };

    //             let totalNewsNotify = removeDuplicates(allNotifications);

    //             dispatch({
    //                 type: PUSH_ALL_RECENT_NOTIFICATION_QUEUE,
    //                 payload: totalNewsNotify,
    //             });
    //         })
    //         .catch((err) => {});
    // }, [dispatch, state?.user?.investorId]);

    // useEffect(() => {
    //     if (!state.hideNotification && state.projectsNotifyQueue.length < 1) {
    //         sendRequest();
    //     }
    // }, [sendRequest, state.hideNotification, state.projectsNotifyQueue.length]);

    return null;
};

export default useRecentNotification;
