import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    // Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import useWindowDimensions from "../hooks/useWindowDimensions";
import theme, { BIG_TAB_BREAKPOINT, MOBILE_BREAKPOINT } from "../theme";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ChartDataLabels
);

const BarChart = ({ investment, finalAsset, monthlyPayouts }) => {
    const { red, primary, lightGrey } = theme.color;
    const { width } = useWindowDimensions();
    const iteration =
        width <= MOBILE_BREAKPOINT ? 5 : width <= BIG_TAB_BREAKPOINT ? 10 : 20;
    const duration = 60;

    const calculateXAxis = () => {
        let label = ["0 Y"];
        let start = 0;
        [...Array(iteration).keys()].forEach(() => {
            // start = start + 0.5;
            start = start + duration / iteration;
            label.push(start / 12 + " Y");
        });
        return label;
    };
    const calculateMiddleAmount = () => {
        let label = [null];
        [...Array(iteration).keys()].forEach((el, index) => {
            let start = monthlyPayouts * (duration / iteration) * (index + 1);
            label.push(start);
        });
        return label;
    };
    const calculateFinalAmount = () => {
        let label = [null];
        [...Array(iteration - 1).keys()].forEach(() => {
            label.push(null);
        });
        label.push(finalAsset);
        return label;
    };
    const getBackgroundColor = () => {
        const color = [];
        [...Array(iteration + 1).keys()].forEach((item, index) => {
            if (item === 0) {
                color.push(red);
            } else {
                color.push(primary);
            }
        });
        return color;
    };
    const data = {
        labels: calculateXAxis(),
        datasets: [
            {
                label: "Rental Income from Solar Asset",
                data: calculateMiddleAmount(),
                backgroundColor: getBackgroundColor(),
                barPercentage: 0.5,
                // borderWidth: 0.5,
            },
            // {
            //     label: "Funds from selling Assets to other Investor",
            //     data: calculateFinalAmount(),
            //     backgroundColor: primary,
            // },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                // top: 30,
                // right: 30,
            },
        },
        plugins: {
            title: {
                display: false,
                text: "",
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            // yAxes: {
            //     display: true,
            //     min: 0,
            //     max: investment * 2,
            //     ticks: {
            //         stepSize: parseInt(investment / 2),
            //     },
            // },
        },
        animation: {
            duration: 200,
            easing: "easeInCubic",
        },
    };

    return (
        <BarWrapper>
            <Bar options={options} data={data} />
        </BarWrapper>
    );
};

export default BarChart;

const BarWrapper = styled.div`
    height: 250px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 150px;
    }
`;
