import styled from "styled-components";

const HideTablet = styled.div`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: none;
    }
`;
const HideMobile = styled.div`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: none;
    }
`;

const ShowTablet = styled.div`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: block;
    }
`;
const ShowMobile = styled.div`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: block;
    }
`;

export { HideMobile, HideTablet, ShowMobile, ShowTablet };
