import React from "react";
import Space from "../../components/space/Space";
import Text from "../../components/text/Text";
import ProjectProgress from "../ProjectProgress";
import { TagItem, TagsContainer } from "../Tag";
import ListingItem from "./ListingItem";

const ProjectAbout = (props) => {
    const {
        about,
        heading,
        fundLimit,
        fundRaised,
        IRR,
        listingMinLimit,
        tagItem,
        progress = 0,
    } = props;

    return (
        <div>
            <div>
                <Space margin={["1rem 0"]}>
                    <Text
                        lineHeight="1.2em"
                        size="l"
                        fontFamily="Montserrat"
                        weight="700"
                    >
                        {heading}
                    </Text>
                </Space>
                <Text
                    lineHeight="1.3em"
                    fontFamily="Montserrat"
                    color="black800"
                    size="s"
                >
                    {about}
                </Text>
            </div>

            <Space margin={["2rem 0"]}>
                <TagsContainer borderRadius=".5rem" bg="grey300">
                    {tagItem.map((item, index) => (
                        <TagItem
                            name={item.name}
                            value={item.value}
                            unit={item.unit}
                            icon={item.icon}
                            size="s"
                            sizeS="xs"
                            margin=".5rem 0"
                            key={index}
                        />
                    ))}
                </TagsContainer>
            </Space>
            <Space margin={[" 0 0 2rem"]}>
                <div>
                    <ProjectProgress value={progress} padding="1rem 0" />
                </div>
            </Space>

            <div className="valuesContainer">
                <ListingItem
                    name="Total Investment"
                    value={`Rs. ${fundLimit} Lakhs`}
                />
                <ListingItem
                    name="Investment Raised"
                    value={`${fundRaised} Lakhs`}
                />
                {/* <ListingItem name="Lock-in Period" value={"12 Months"} /> */}
                <ListingItem name="Return (IRR)" value={IRR + " %"} />
                <ListingItem
                    name="Minimum Investment"
                    value={listingMinLimit}
                />
            </div>
        </div>
    );
};
export default ProjectAbout;
