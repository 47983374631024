import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import {
    authBurgerNavLinks,
    authNavLinks,
    navLinks,
} from "../../constants/routes";
import useAuth from "../../hooks/useAuth";
import Header from "./Header";

const HeaderContainer = (props) => {
    const {
        location: { pathname },
    } = props;
    const { isAuth } = useAuth(pathname);
    const links = isAuth === true ? authNavLinks : navLinks;
    const burgerLinks = isAuth === true ? authBurgerNavLinks : navLinks;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    return (
        <div>
            <Header
                isAuthenticated={isAuth}
                burgerLinks={burgerLinks}
                links={links}
            />
        </div>
    );
};

HeaderContainer.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            path: PropTypes.string,
        })
    ),
    homeLink: PropTypes.shape({
        icon: PropTypes.string,
        path: PropTypes.string,
    }),
};

export default withRouter(HeaderContainer);
