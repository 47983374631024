import React from "react";
import styled from "styled-components";
import Text from "../components/text/Text";

const ProgressWrap = styled.div`
    width: 100%;
    padding: ${(props) => props.padding};
    background: ${(props) =>
        `linear-gradient(to right, ${props.theme.color.cyan} ${props.value}%, ${props.theme.color.grey500} ${props.value}% 100%)`};
    border-radius: 5px;
    margin-top: 1rem;
`;
const InvestWrap = styled.div`
    width: 100%;
    padding: ${(props) => props.padding};
    background: ${(props) => props.theme.color.cyan};
    border-radius: 5px;
    margin-top: 1rem;
`;

const ProjectProgress = ({ value, size = "s", padding = ".5rem" }) => {
    return (
        <ProgressWrap padding={padding} value={value}>
            <Text
                align="center"
                size={size}
                weight="700"
                fontFamily="Montserrat"
            >
                {value}% Reserved
            </Text>
        </ProgressWrap>
    );
};

export const ProjectInvestProgress = ({
    name,
    value,
    size = "s",
    sizeS = "xs",
    padding = ".5rem",
}) => {
    return (
        <InvestWrap padding={padding} value={value}>
            <Text
                align="center"
                size={size}
                weight="700"
                fontFamily="Montserrat"
            >
                {value}
            </Text>
            <Text
                align="center"
                size={sizeS}
                weight="700"
                fontFamily="Montserrat"
            >
                {name}
            </Text>
        </InvestWrap>
    );
};

export default ProjectProgress;
