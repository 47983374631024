import { AnimatePresence, motion } from "framer-motion";
import { FaPlay } from "react-icons/fa";
import styled from "styled-components";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { twoDecimalLocal } from "../helpers/helpers";
import useWindowDimensions from "../hooks/useWindowDimensions";
import InvestmentProgress from "./InvestmentProgress";

export const TableMain = styled.div`
    padding-top: 25px;
    table {
        width: 100%;
        text-align: center;
        background-color: #f5f3f3;
        border-radius: 10px;
        border-collapse: collapse;
        font-family: ${(props) =>
            props.fontFamily || "'Montserrat', sans-serif"};
        thead {
            tr {
                :first-child {
                    th:first-child {
                        border-top-left-radius: 10px;
                    }
                    th:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }
        }

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
        th {
            border-bottom: none;
            border-top: none;
            color: ${(props) => props.theme.color.darkGrey};
            background-color: #e0dddd;
            :first-child {
                border-left: none;
            }
            :last-child {
                border-right: none;
            }
        }
    }
`;

const mobileTableHeader = [
    {
        id: 1,
        name: "Id / Date",
    },

    {
        id: 2,
        name: "Amount",
    },
    {
        id: 3,
        name: "Solar",
    },
    {
        id: 4,
        name: "Monthly / Lifetime",
    },
    {
        id: 5,
        name: "Status",
    },
];
const tableHeader = [
    {
        id: 1,
        name: "Id",
    },
    {
        id: 2,
        name: "Date",
    },
    {
        id: 3,
        name: "Amount",
    },
    {
        id: 4,
        name: "Solar",
    },
    {
        id: 5,
        name: "Monthly",
    },
    {
        id: 6,
        name: "Lifetime",
    },
    {
        id: 7,
        name: "Status",
    },
    {
        id: 8,
        name: "",
    },
];
const Investments = ({
    data,
    onClick,
    activeCol,
    onCancelClick,
    onPayoutsClick,
    onKycClick,
}) => {
    const { width } = useWindowDimensions();
    return (
        <TableMain>
            <table>
                <thead>
                    <tr>
                        {width > 940
                            ? tableHeader.map((item) => (
                                  <th key={item.id} style={{ padding: "10px" }}>
                                      <Text color="darkGrey" size="s">
                                          {item.name}
                                      </Text>
                                  </th>
                              ))
                            : mobileTableHeader.map((item) => (
                                  <th key={item.id} style={{ padding: "10px" }}>
                                      <Text color="darkGrey" size="s">
                                          {item.name}
                                      </Text>
                                  </th>
                              ))}
                    </tr>
                </thead>

                <tbody>
                    {data.map((item) => (
                        <RenderTableRow
                            key={data.id}
                            data={item}
                            onClick={onClick}
                            activeCol={activeCol}
                            width={width}
                            onCancelClick={onCancelClick}
                            onPayoutsClick={onPayoutsClick}
                            onKycClick={onKycClick}
                        />
                    ))}
                </tbody>
            </table>
        </TableMain>
    );
};

export default Investments;

const Cell = styled.td`
    padding: 10px;
    display: ${(props) => (props.isHidden ? "none" : "")};
`;

const HideDesk = styled(Text)`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        display: block;
    }
`;

const RenderTableRow = ({
    data,
    onClick,
    activeCol,
    width,
    onCancelClick,
    onPayoutsClick,
    onKycClick,
}) => {
    const isTablet = width <= 940;
    return (
        <>
            <tr role="button">
                <Cell style={{ padding: "10px" }}>
                    <Text fontFamily="Montserrat" color="darkGrey" size="s">
                        {data.id}
                    </Text>
                    <HideDesk fontFamily="Montserrat" color="darkGrey" size="s">
                        {data.date}
                    </HideDesk>
                </Cell>

                <Cell isHidden={isTablet} style={{ padding: "10px" }}>
                    <Text fontFamily="Montserrat" color="darkGrey" size="s">
                        {data.date}
                    </Text>
                </Cell>

                <Cell style={{ padding: "10px" }}>
                    <Text
                        fontFamily="Montserrat"
                        align="center"
                        color="darkGrey"
                        size="s"
                    >
                        {twoDecimalLocal(data.amount)}
                    </Text>
                </Cell>

                <Cell style={{ padding: "10px" }}>
                    <Text fontFamily="Montserrat" color="darkGrey" size="s">
                        {twoDecimalLocal(data.solar)}
                    </Text>
                </Cell>

                <Cell style={{ padding: "10px" }}>
                    <Text
                        fontFamily="Montserrat"
                        align="center"
                        color="darkGrey"
                        size="s"
                    >
                        {twoDecimalLocal(data.monthlyPayout)}
                    </Text>
                    <HideDesk fontFamily="Montserrat" color="darkGrey" size="s">
                        / {twoDecimalLocal(data.lifetimePayout)}
                    </HideDesk>
                </Cell>

                <Cell isHidden={isTablet} style={{ padding: "10px" }}>
                    <Text fontFamily="Montserrat" color="darkGrey" size="s">
                        {twoDecimalLocal(data.lifetimePayout)}
                    </Text>
                </Cell>

                <Cell style={{ padding: "10px" }}>
                    <StatusBtn
                        onClick={isTablet ? () => onClick(data) : null}
                        role="button"
                        color="darkGrey"
                        size="s"
                    >
                        {data.status}
                    </StatusBtn>
                </Cell>
                <Cell isHidden={isTablet}>
                    <PlayIcon
                        color="white"
                        size="s"
                        role="button"
                        onClick={() => onClick(data)}
                        isActive={activeCol?.id === data.id}
                    >
                        <FaPlay />
                    </PlayIcon>
                </Cell>
            </tr>
            <AnimatePresence>
                {activeCol?.id === data.id && (
                    <motion.tr
                        initial={{ opacity: 0, height: 0, overflow: "hidden" }}
                        animate={{
                            opacity: 1,
                            height: "100%",
                            overflow: "auto",
                        }}
                        exit={{ opacity: 0, height: 0, overflow: "hidden" }}
                    >
                        <InvestmentProgress
                            onCancelClick={onCancelClick}
                            data={activeCol}
                            onPayoutsClick={onPayoutsClick}
                            onKycClick={onKycClick}
                        />
                    </motion.tr>
                )}
            </AnimatePresence>
        </>
    );
};
const PlayIcon = styled(InlineText)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.primary};
    height: 30px;
    width: 30px;
    padding-left: 2px;
    transition: all ease-in 0.3s;
    transform: ${(props) => (props.isActive ? "rotate(90deg)" : "")};
    :hover {
        cursor: pointer;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    }
`;

const StatusBtn = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        text-decoration: underline;
        text-decoration-color: ${(props) => props.theme.color.primary};
        cursor: pointer;
        font-weight: 700;
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
    }
`;
