import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Space from "../components/Spacing/Space";
import Accordion from "../components/accordion/Accordion";
import { Flex } from "../components/styles/Elements.styles";
import { P } from "../components/styles/Typography.styles";
import Text from "../components/text/Text";
import {
    investFaq,
    investMiscellaneousFaq,
    investRiskFaq,
    kycFaq,
    opportunityFaq,
    payoutTaxesFaq,
    propositionFaq,
    referralProgramFaq,
} from "../constants/faqData";
import { FAQ } from "../constants/routes";

let allFaqs = [
    ...investFaq,
    ...investMiscellaneousFaq,
    ...investRiskFaq,
    ...kycFaq,
    ...opportunityFaq,
    ...payoutTaxesFaq,
    ...propositionFaq,
    ...referralProgramFaq,
];

const FaqTab = ({ id, visibilityHandler }) => {
    const history = useHistory();
    const faqRef = useRef();
    useEffect(() => {
        let target = faqRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [faqRef, visibilityHandler]);

    const [active, setActive] = useState(filteredItem[0]);
    const [displayFaq, setDisplayFaq] = useState(investFaq);

    const handleFilterFaq = (data) => {
        setActive(data);
        let newFaqs = allFaqs.filter((item) => item?.tags?.includes(data.name));
        setDisplayFaq(newFaqs.slice(0, 10));
    };
    return (
        <div
            ref={faqRef}
            id={id}
            data-title="FAQ"
            style={{ marginTop: "10rem" }}
        >
            <Space lgm={["10% 0px 3rem 0px"]} xxsm={["40px 0px"]}>
                <Text
                    color="grey"
                    textTransform="capitalize"
                    weight="bold"
                    size="xl"
                >
                    Frequently Asked Question
                </Text>
            </Space>

            <Space xxsm={["0 0 1rem"]} stm={["0 0 3rem"]}>
                <Flex.Container gap="1rem">
                    {filteredItem.map((item, index) => (
                        <TabItem
                            key={item.id}
                            onClick={() => handleFilterFaq(item)}
                            active={item.id === active.id}
                        >
                            <P xxs="m" st="ml">
                                {item.name}
                            </P>
                        </TabItem>
                    ))}

                    <TabItem linked={true} onClick={() => history.push(FAQ)}>
                        <P xxs="m" st="ml">
                            More
                        </P>
                    </TabItem>
                </Flex.Container>
            </Space>

            <TabContent data={displayFaq} />
        </div>
    );
};

export default FaqTab;

const renderInsideContent = (data, num) => {
    return data.map(
        (faq, i) =>
            i % 2 === num && (
                <Accordion key={faq.question} label={faq.question} size="s">
                    {faq.answer ? (
                        <div
                            style={{
                                marginBottom: faq.list ? "5px" : "0px",
                            }}
                        >
                            {faq.answer}
                        </div>
                    ) : null}
                    {faq.format ? (
                        <Text
                            dangerouslySetInnerHTML={faq.format}
                            size="s"
                            color="sharkGrey"
                            style={{
                                marginBottom: faq.list ? "5px" : "0px",
                                marginTop: faq.answer ? "5px" : "0px",
                            }}
                        ></Text>
                    ) : null}
                    {faq.list
                        ? faq.list.map((item, index) => (
                              <Flex.Container
                                  key={index}
                                  style={{
                                      marginBottom: "5px",
                                  }}
                              >
                                  <Flex.Item>
                                      <Text
                                          size="s"
                                          color="sharkGrey"
                                          style={{
                                              width: "15px",
                                              marginRight: "10px",
                                          }}
                                      >
                                          {`${index + 1}.`}
                                      </Text>
                                  </Flex.Item>
                                  <Flex.Item style={{ flex: "1" }}>
                                      <Text color="sharkGrey" size="s">
                                          {item}
                                      </Text>
                                  </Flex.Item>
                              </Flex.Container>
                          ))
                        : null}
                </Accordion>
            )
    );
};

const TabContent = ({ data }) => {
    return (
        <>
            <Flex.Container justify="space-between">
                <Flex.Item xxs="100%" lg="48%">
                    {renderInsideContent(data, 0)}
                </Flex.Item>
                <Flex.Item xxs="100%" lg="48%">
                    {renderInsideContent(data, 1)}
                </Flex.Item>
            </Flex.Container>
        </>
    );
};

const TabItem = styled.div`
    cursor: pointer;
    display: inline-block;

    ${P} {
        color: ${(props) =>
            props.active
                ? props.theme.color.primaryUpdated
                : props.theme.color.grey550};
        background-color: ${(props) =>
            props.active ? props.theme.color.grey250 : props.theme.color.white};
        padding: 8px 16px;
        border-radius: 5rem;
        border: 1px solid ${(props) => props.theme.color.grey550};
        text-decoration: ${(props) => (props.linked ? "underline" : "")};
        text-decoration-color: ${(props) => props.theme.color.primary};
        text-underline-offset: 3px;
    }
`;

const filteredItem = [
    {
        id: 1,
        name: "Invest",
    },
    {
        id: 2,
        name: "Opportunity",
    },
];
