import React from "react";
import styled from "styled-components";
import TypewriterComponent from "typewriter-effect";
import { InlineText } from "../text/HeadingText";

const Container = styled(InlineText)`
    font-family: ${(props) => props.fontFamily || "'Karla', sans-serif"};
    color: ${(props) => props.theme.color[props.color]};
    text-transform: capitalize;
    font-weight: 100;
    /* min-height: 105px; */
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        /* min-height: 90px; */
        .Typewriter {
            text-align: center;
        }
    }
`;

const TypeWriter = ({
    strings,
    size = "s",
    color = "black",
    cursorColor = "#E67628",
    cursor,
}) => {
    let cursorType =
        cursor !== undefined
            ? cursor
            : `<span style="width: 1px; overflow: hidden;  border-radius: 5px; color: ${cursorColor};  background-color: ${cursorColor}; padding: 0px; ">|</span>`;
    return (
        <Container color={color} size={size}>
            <TypewriterComponent
                options={{
                    loop: true,
                    autoStart: true,
                    pauseFor: 3000,
                    deleteSpeed: 10,
                    cursor: cursorType,
                    strings: strings,
                }}
            />
        </Container>
    );
};

export default TypeWriter;
