import { useCallback, useMemo } from "react";
import styled from "styled-components";
import Text from "../../components/text/Text";
import { TableMain } from "../../dashboard/Investments";
import { twoDecimalLocal } from "../../helpers/helpers";

const tableHeader = [
    {
        id: 1,
        name: "Month",
    },
    {
        id: 2,
        name: "Capital Payout",
    },
    {
        id: 3,
        name: "Profit Payout",
    },
    {
        id: 4,
        name: "Remuneration Payout",
    },
    {
        id: 5,
        name: "Total Payout",
    },
];

const PaymentTable = ({
    monthlyPayout,
    termDetails,
    totalMonths,
    paymentAmount,
}) => {
    let sum = useMemo(() => {
        return {
            capitalPayout: 0,
            profitPayout: 0,
            remunerationPayout: 0,
        };
    }, []);
    const getColumnValues = useCallback(
        (index, key) => {
            let values =
                (termDetails[parseInt(index / 12)][key] * paymentAmount) /
                100000;
            sum[key] = sum[key] + values;
            return twoDecimalLocal(values);
        },
        [paymentAmount, sum, termDetails]
    );

    return (
        <div style={{ height: "60vh", overflow: "auto", position: "relative" }}>
            <TableMain style={{ position: "relative" }}>
                <table>
                    <thead>
                        <tr>
                            {tableHeader.map((item) => (
                                <th key={item.id} style={{ padding: "10px" }}>
                                    <Text color="darkGrey" size="s">
                                        {item.name}
                                    </Text>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {[...Array(totalMonths).keys()].map((item) => (
                            <tr key={item}>
                                <Cell style={{ padding: "10px" }}>
                                    <Text fontFamily="Montserrat" size="s">
                                        {item + 1}
                                    </Text>
                                </Cell>

                                <Cell style={{ padding: "10px" }}>
                                    <Text fontFamily="Montserrat" size="s">
                                        {getColumnValues(item, "capitalPayout")}
                                    </Text>
                                </Cell>
                                <Cell style={{ padding: "10px" }}>
                                    <Text fontFamily="Montserrat" size="s">
                                        {getColumnValues(item, "profitPayout")}
                                    </Text>
                                </Cell>
                                <Cell style={{ padding: "10px" }}>
                                    <Text fontFamily="Montserrat" size="s">
                                        {getColumnValues(
                                            item,
                                            "remunerationPayout"
                                        )}
                                    </Text>
                                </Cell>
                                <Cell style={{ padding: "10px" }}>
                                    <Text fontFamily="Montserrat" size="s">
                                        {twoDecimalLocal(monthlyPayout)}
                                    </Text>
                                </Cell>
                            </tr>
                        ))}

                        <tr>
                            <Cell style={{ padding: "10px" }}>
                                <Text
                                    weight="700"
                                    fontFamily="Montserrat"
                                    size="s"
                                >
                                    Total
                                </Text>
                            </Cell>

                            <Cell style={{ padding: "10px" }}>
                                <Text
                                    weight="700"
                                    fontFamily="Montserrat"
                                    size="s"
                                >
                                    {twoDecimalLocal(sum.capitalPayout)}
                                </Text>
                            </Cell>
                            <Cell style={{ padding: "10px" }}>
                                <Text
                                    weight="700"
                                    fontFamily="Montserrat"
                                    size="s"
                                >
                                    {twoDecimalLocal(sum.profitPayout)}
                                </Text>
                            </Cell>
                            <Cell style={{ padding: "10px" }}>
                                <Text
                                    weight="700"
                                    fontFamily="Montserrat"
                                    size="s"
                                >
                                    {twoDecimalLocal(sum.remunerationPayout)}
                                </Text>
                            </Cell>
                            <Cell style={{ padding: "10px" }}>
                                <Text
                                    weight="700"
                                    fontFamily="Montserrat"
                                    size="s"
                                >
                                    {twoDecimalLocal(monthlyPayout * 60)}
                                </Text>
                            </Cell>
                        </tr>
                    </tbody>
                </table>
            </TableMain>
        </div>
    );
};

export default PaymentTable;

const Cell = styled.td`
    padding: 10px;
    display: ${(props) => (props.isHidden ? "none" : "")};

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 5px;
    }
`;
