import styled from "styled-components";
import Flex from "../flex/Flex";
import { HeaderHeight } from "../header/Header";

const footerHeight = {
    default: "343px",
    tablet: "502px",
    mobile: "512px",
    smallMobile: "553px",
};
const Container = styled.div`
    max-width: 1300px;
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;

    @media (max-width: ${(props) => props.theme.breakPoints.bigTablet}) {
        padding: 0 2rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 0 1.5rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 0 1rem;
    }
`;

export const ContainerMain = styled(Flex.Container)`
    padding: calc(${HeaderHeight.smallLaptop} + 80px)
        ${(props) => props.theme.spacing.default.horizontal} 80px;
    background: ${(props) => (props.bg ? `url(${props.bg})` : "")};
    background-size: cover;
    min-height: calc(100vh - ${footerHeight.default});
    max-height: 100%;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        padding: calc(${HeaderHeight.smallLaptop} + 80px)
            ${(props) => props.theme.spacing.default.horizontal} 80px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        align-items: flex-start;
        padding: calc(${HeaderHeight.tablet} + 80px)
            ${(props) => props.theme.spacing.default.horizontal} 80px;
        min-height: calc(100vh - ${footerHeight.tablet});
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding-top: calc(${HeaderHeight.tablet} + 50px);
        min-height: calc(100vh - ${footerHeight.mobile});
        padding-bottom: 50px;
        flex-direction: column;
        justify-content: flex-start;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        min-height: calc(100vh - ${footerHeight.smallMobile});
    }
`;

export const ContainerStyled = styled(Flex.Container)`
    padding-top: ${(props) => props.paddingTop || ""};
    padding-bottom: ${(props) => props.paddingBottom || ""};
    padding-left: ${(props) => props.paddingLeft || ""};
    padding-right: ${(props) => props.paddingRight || ""};
    flex-direction: ${(props) => props.direction || "row"};
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        justify-content: ${(props) => props.justifyTablet || ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        justify-content: ${(props) => props.justifyMobile || ""};
        display: ${(props) => props.displayMobile || ""};
    }
`;

export default Container;
