import fileDownload from "js-file-download";
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Circle from "../components/circle/Circle";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { SIGN_DOCUMENT_DOWNLOAD_ENDPOINT } from "../constants/routes";
import request from "../services/request";
import ProgressStepper from "./ProgressStepper";
import StatusStepper from "./StatusStepper";

const InvestmentProgress = ({
    data,
    onCancelClick,
    onPayoutsClick,
    onKycClick,
}) => {
    return (
        <td colSpan="10" style={{ padding: " 0 20px " }}>
            <ProgressStepper padding="40px 0 90px" data={data} />

            <StatusStepper data={data} padding="20px 0 90px" icon />

            <Status
                data={data}
                onCancelClick={onCancelClick}
                onPayoutsClick={onPayoutsClick}
                onKycClick={onKycClick}
            />
        </td>
    );
};

export default InvestmentProgress;

const Status = ({ data, onCancelClick, onPayoutsClick, onKycClick }) => {
    const downloadHandler = useCallback(
        async (e) => {
            e.stopPropagation();
            try {
                const response = await request.authPost({
                    endpoint: SIGN_DOCUMENT_DOWNLOAD_ENDPOINT,
                    body: {
                        investmentId: data.id,
                    },
                    checkStatusCode: true,
                });
                const file = await response.blob();
                fileDownload(file, `investment-${data.id}-doc.pdf`);
                // toast.success("Document downloaded successfully!");
            } catch (err) {
                toast.error(typeof error === "string" ? err : ERROR_MSG);
            }
        },
        [data.id]
    );

    // DetailsBtn
    let sorted =
        data.payoutOrders.length !== 0
            ? data.payoutOrders.sort(
                  (a, b) => Number(a?.payout_number) - Number(b?.payout_number)
              )
            : [];

    return (
        <Space margin={["1.5rem 0 .5rem"]}>
            <div>
                <Flex.Container justifyContent="space-between">
                    <StatusButton
                        name="KYC"
                        color="primary"
                        onClick={onKycClick}
                    />
                    <StatusButton
                        name="Payouts"
                        color="orange"
                        onClick={() =>
                            onPayoutsClick(
                                sorted.length !== 0
                                    ? { order: sorted, id: data.id }
                                    : {
                                          message:
                                              "Payout scheduled is not generated.",
                                          id: data?.id,
                                      }
                            )
                        }
                    />
                    <StatusButton
                        name="Agreements"
                        color="primary"
                        onClick={downloadHandler}
                    />
                    <StatusButton
                        name="Cancel"
                        color="error"
                        onClick={() => onCancelClick({ investmentId: data.id })}
                        disabled={data?.status !== "PAID"}
                    />
                </Flex.Container>
            </div>
        </Space>
    );
};

const StatusButton = ({ name, onClick, color, disabled = false }) => {
    return (
        <StatusBtn disabled={disabled} onClick={onClick} color={color}>
            <CircleStyled
                size="20px"
                backgroundColor={disabled ? "lightGrey" : color}
                margin="0 10px 0 0"
            />
            <Text weight="700" size="s">
                {name}
            </Text>
        </StatusBtn>
    );
};

const StatusBtn = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 3px 10px;
    border-radius: 10px;
    border: 1px solid;
    font-size: ${(props) => props.theme.fontSize.tablet.s};
    border-color: ${(props) =>
        props.disabled
            ? props.theme.color.lightGrey
            : props.theme.color[props.color]};
    :hover {
        box-shadow: ${(props) =>
            props.disabled ? "none" : "2px 2px 4px rgba(0, 0, 0, 0.25)"};
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }
`;
const CircleStyled = styled(Circle)`
    @media (max-width: 700px) {
        display: none;
    }
`;
