import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../components/flex/Flex";
import YoutubePlayer from "../components/youtube-palyer";
import { useContextState } from "../context/ContextProvider";

const VideoWrapper = styled(Flex.Item)`
    border-radius: 10px;
    background-color: ${(props) => props.theme.color.darkSecondary};
    height: fit-content;
    box-sizing: border-box;
    padding: ${(props) => props.padding};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: ${(props) => props.mobilepadding};
    }
`;

const VideoPlayerBox = ({
    Header,
    Footer,
    padding = "1rem",
    mobilepadding = ".5rem",
}) => {
    const { state, dispatch } = useContextState();
    const [isDisable, setIsDisable] = useState(false);
    return (
        <VideoWrapper padding={padding} mobilepadding={mobilepadding}>
            {Header && <Header />}
            <YoutubePlayer />
            {Footer && <Footer />}
        </VideoWrapper>
    );
};

export default VideoPlayerBox;
