import styled from "styled-components";
import { bgBubble } from "../assets";
import TypeWriter from "../components/animation/TypeWriter";
import Button from "../components/button/Button";
import Carousel from "../components/carousel/Carousel";
import Flex from "../components/flex/Flex";
import { HeaderHeight } from "../components/header/Header";
import Section from "../components/section/Section";
import Space from "../components/space/Space";
import Container from "../components/styles/Container";
import { H1, H2, H3, Span } from "../components/styles/Typography.styles";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { SHOW_SIGNUP_FORM } from "../constants/contexConstant";
import landingData from "../constants/landingData";
import { useContextState } from "../context/ContextProvider";
import useWindowDimensions from "../hooks/useWindowDimensions";
import CarbonOffsetContainer from "../offset/CarbonOffsetContainer";
import Scroller from "../services/scroller";
import FaqTab from "./FaqTab";
import HowItWorks from "./HowItWorks";
import SrotInvestment from "./SrotInvestment";
import SrotPlatFormView from "./SrotPlatFormView";
import VisualizeEarning from "./VisualizeEarning";
import InvOpportunity from "./opportunity/InvOpportunity";

const CenterMobile = styled.div`
    text-align: left;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        text-align: ${(props) => props.centerText || ""};
    }
`;

const ContainerMain = styled(Section)`
    background-image: url(${bgBubble});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: border-box;
    padding-top: 10rem;
    padding-bottom: 5%;
    display: flex;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        /* padding-top: calc(${HeaderHeight.smallLaptop}); */
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        /* padding-top: calc(${HeaderHeight.tablet} + 80px); */
        /* padding-top: 40px; */
        height: auto;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        /* padding-top: calc(${HeaderHeight.tablet} + 60px); */
        height: auto;
    }
`;
const ScrollerContainerMain = styled.div`
    padding: 0 ${(props) => props.theme.spacing.default.horizontal} 50px 0;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 0 ${(props) => props.theme.spacing.default.horizontal} 50px;
    }
`;
const ButtonStyled = styled(Button)`
    padding: 0.5em;
    margin: 0 0.5rem 0 0;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        margin: 0.5rem 0 0;
    }
`;

const TextWriterText = styled(H2)`
    box-sizing: border-box;
    display: inline-block;
    text-align: left;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
    }
`;

const howwork = "how-it-works";
const srotinvest = "srot-invest";
const calcarbon = "carbon-footprint-cal";
const visualize = "returns-cal";
const overview = "srot-overview";
const investOpportunity = "investment-opportunity";
const faq = "faq";

const LANDING_HEADINGS = [
    {
        name: "INVESTMENT OPPORTUNITY",
        id: investOpportunity,
    },
    {
        name: "SROT OVERVIEW",
        id: overview,
    },
    {
        name: "HOW IT WORKS",
        id: howwork,
    },
    {
        name: "SROT INVESTMENT COMPARISON",
        id: srotinvest,
    },
    {
        name: "RETURNS CALCULATOR",
        id: visualize,
    },
    {
        name: "CARBON FOOTPRINT CALCULATOR",
        id: calcarbon,
    },

    {
        name: "FAQ",
        id: faq,
    },
];

const { banner, works, investment, carousel, invOpportunity } = landingData;
const Landing = () => {
    const { state, dispatch } = useContextState();
    return (
        <Section height="auto">
            <ContainerMain>
                <Container>
                    <Flex.Container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Flex.Item
                            width="52%"
                            tabletWidth="100%"
                            mobileWidth="100%"
                        >
                            <CenterMobile centerText="center">
                                <Space margin={["0 0 10px 0"]}>
                                    <H3>
                                        Invest in Pre-leased{" "}
                                        <InlineText color="primary">
                                            Solar Assets
                                        </InlineText>
                                    </H3>
                                </Space>
                            </CenterMobile>
                            <CenterMobile centerText="center">
                                <Space margin={["0 0 10px 0"]}>
                                    <H1>
                                        Earn upto{" "}
                                        <Span
                                            fontFamily="Montserrat"
                                            color="primary"
                                        >
                                            20% IRR
                                        </Span>{" "}
                                        Returns
                                    </H1>
                                </Space>
                            </CenterMobile>
                            <Space
                                margin={["2rem 0"]}
                                mobilepadding={["0 24px"]}
                            >
                                <TextWriterText
                                    color="darkGrey"
                                    weight="bold"
                                    size="xl"
                                >
                                    <TypeWriter
                                        size="xl"
                                        color="darkGrey"
                                        strings={banner.textEffects}
                                    />
                                </TextWriterText>
                            </Space>
                            <BannerTitle />
                        </Flex.Item>
                        <Flex.Item
                            width="45%"
                            tabletWidth="100%"
                            mobileWidth="100%"
                        >
                            <Carousel
                                data={carousel}
                                padding="0 0 10px"
                                autoPlay
                                interval="3000"
                            />
                        </Flex.Item>
                    </Flex.Container>
                </Container>
            </ContainerMain>

            <Scroller navSectionHeaders={LANDING_HEADINGS}>
                {(visibilityHandler) => (
                    <ScrollerContainerMain>
                        <InvOpportunity
                            id={investOpportunity}
                            visibilityHandler={visibilityHandler}
                            data={invOpportunity}
                        />
                        <SrotPlatFormView
                            id={overview}
                            visibilityHandler={visibilityHandler}
                        />
                        <HowItWorks
                            id={howwork}
                            data={works}
                            visibilityHandler={visibilityHandler}
                        />
                        <SrotInvestment
                            id={srotinvest}
                            data={investment}
                            visibilityHandler={visibilityHandler}
                        />
                        <VisualizeEarning
                            id={visualize}
                            data={works}
                            visibilityHandler={visibilityHandler}
                        />
                        <CarbonOffsetContainer
                            id={calcarbon}
                            visibilityHandler={visibilityHandler}
                        />

                        <FaqTab
                            id={faq}
                            visibilityHandler={visibilityHandler}
                        />
                    </ScrollerContainerMain>
                )}
            </Scroller>
            {/* <VideoPlayerModal
                // active={state.videoGuide}
                onClick={() => dispatch({ type: DISABLE_VIDEO_GUIDE })}
            /> */}

            {/* @TODO => Hiding Earning footer Wed May 29,2024 */}
            {/* <EarningFooter /> */}
        </Section>
    );
};

export const FooterContainer = styled(Flex.Container)`
    background: ${(props) => props.theme.color.primary};
    padding: 1rem ${(props) => props.theme.spacing.default.horizontal};
`;
export const TextCenter = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.default.s};
    }
`;

export default Landing;
export const Break = styled.br`
    display: none;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: block;
    }
`;
export const ButtonSingUp = styled(Button)`
    padding: 0.5rem;
    margin-left: 10px;
    display: block;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        line-height: 1.2rem;
        padding: 0.3rem;
        font-size: ${(props) => props.theme.fontSize.default.s};
    }
`;

export const ButtonView = styled(Flex.Container)`
    text-align: left;
    margin: 0 0 0.5rem 0;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 1.5rem 0 0.5rem 0;
    }
`;

const ThinkGreenTxt = styled(H3)`
    margin: 0 0 2rem;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        text-align: center;
        margin: 0 0 1.5rem;
    }
`;
const BannerTitle = () => {
    const { dispatch } = useContextState();
    const { width } = useWindowDimensions();
    return (
        <>
            <ThinkGreenTxt weight="rg" size="l">
                <ButtonStyled
                    onClick={() => dispatch({ type: SHOW_SIGNUP_FORM })}
                    fontSize="m"
                    size="s"
                    type="solid"
                    color="white"
                    backgroundColor="primary"
                    fontWeight="bold"
                    fullWidth={width > 570 ? false : true}
                >
                    Signup Now
                </ButtonStyled>{" "}
            </ThinkGreenTxt>
        </>
    );
};
