import React from "react";
import styled from "styled-components";
const VideoContainer = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;
const YoutubePlayer = ({
    src = `https://www.youtube.com/embed/ZjvXRNjtldE`,
}) => {
    return (
        <VideoContainer className="video-responsive">
            <iframe
                src={src}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </VideoContainer>
    );
};

export default YoutubePlayer;
