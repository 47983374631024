import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AlertMessage from "../components/alert/AlertMessage";
import Card from "../components/card/Card";
import Flex from "../components/flex/Flex";
import { Form, Label } from "../components/form/Form";
import Image from "../components/image/Image";
import Input from "../components/input/Input";
import Line from "../components/line/Line";
import Loading from "../components/loading/Loading";
import OtpInput from "../components/otp-input/OtpInput";
import Space from "../components/space/Space";
import { ContainerMain, ContainerStyled } from "../components/styles/Container";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { loginData } from "../constants/loginData";
import { passRegEX } from "../constants/regularExpression";
import {
    ERROR_SCREEN,
    FORGET,
    FORGET_PASSWORD_ENDPOINT,
    LANDING,
} from "../constants/routes";
import { getURLQueryParams } from "../helpers/helpers";
import { ButtonStyled } from "../profile/ChangePassword";
import request from "../services/request";

const ResetScreen = () => {
    const OTP_LENGTH = 6;
    const [formError, setFormError] = useState({});
    const [showPass, setShowPass] = useState(false);
    const location = useLocation();

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
        otp: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [firstAttempt, setFirstAttempt] = useState(false);
    const [isTfaEnable, setIsTfaEnable] = useState(null);

    const history = useHistory();

    const code = getURLQueryParams(location, "code", true);
    const credentials = getURLQueryParams(location, "credentials", true);
    const tfa = getURLQueryParams(location, "tfa", true);

    /*  @TODO: redirect to error Mar 01  */
    if (
        code === undefined ||
        code === "" ||
        credentials === undefined ||
        credentials === "" ||
        tfa === undefined ||
        tfa === ""
    )
        history.push(ERROR_SCREEN);

    useEffect(() => {
        const endpoint = FORGET_PASSWORD_ENDPOINT;

        const body = { code, credentials };
        setLoading(true);
        setMessage("");
        setError(false);
        const sentOTPTouser = () => {
            request
                .put({ endpoint, body })
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        setLoading(false);
                        setShowAlert(false);
                        setMessage("");
                        setFirstAttempt(true);
                    }
                })
                .catch((err) => {
                    setShowAlert(true);
                    setError(true);
                    setMessage(err);
                    setLoading(false);
                    setFirstAttempt(false);
                });
        };

        if (tfa === "enabled") {
            sentOTPTouser();
            setIsTfaEnable(true);
        } else {
            setLoading(false);
            setShowAlert(false);
            setMessage("");
            setFirstAttempt(true);
            setIsTfaEnable(false);
        }
    }, [code, credentials, history, isTfaEnable, tfa]);

    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.passwordError = "Password is required!";
        } else if (!passRegEX.test(values.password)) {
            errors.passwordError =
                "Password should be min 8 char, with at least 1  special char, 1 upper and 1 lower case letters and a number!";
        }
        if (!values.confirmPassword) {
            errors.confirmPassError = "Confirm password is required!";
        } else if (
            formData.confirmPassword &&
            formData.confirmPassword !== values.password
        ) {
            errors.confirmPassError = "Password doesn't matched!";
        }

        if (isTfaEnable && !values.otp) {
            errors.otpError = "OTP is required!";
        } else if (isTfaEnable && values.otp.length !== OTP_LENGTH) {
            errors.otpError = "OTP is incomplete!";
        }

        return errors;
    };

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const getValidFormData = validate(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            setError(false);
            setMessage("");
            const endpoint = FORGET_PASSWORD_ENDPOINT;
            const body = {
                code,
                credentials,
                password: formData.password,
            };

            if (isTfaEnable) {
                body.otp = formData.otp;
            }

            request
                .put({ endpoint, body })
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        if (
                            data?.message !==
                            "Investor Password has been reset successfully"
                        ) {
                            setError(true);
                            setMessage("Password hasn't updated!");
                            setTimeout(() => {
                                history.push(FORGET);
                            }, 3000);
                        } else {
                            setError(false);
                            setMessage(
                                "Password has been updated successfully!"
                            );
                            setTimeout(() => {
                                history.push(LANDING);
                            }, 3000);
                        }

                        const tempt = {
                            password: "",
                            confirmPassword: "",
                            otp: "",
                        };
                        setFormData({ ...formData, tempt });
                    }
                })
                .catch((err) => {
                    setError(true);
                    setMessage(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <ContainerMain
            bg={loginData.background.bg}
            justifyContent="center"
            alignItems="center"
        >
            <Flex.Item width="70%" tabletWidth="100%" mobileWidth="100%">
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl" weight="bold">
                        {loginData.subHeadings[0]}
                        <InlineText textTransform="uppercase" color="primary">
                            {loginData.inlineText}{" "}
                        </InlineText>
                        {loginData.subHeadings[1]}
                        <InlineText
                            textTransform="uppercase"
                            color="primary"
                            weight="bold"
                        >
                            {loginData.inlineText}
                        </InlineText>
                    </Text>
                </Space>
                <Space
                    bigtabletpadding={["0px 5% 0px 0px"]}
                    tabletpadding={["0px 10% 0px 0px"]}
                >
                    <Text size="xl">{loginData.subHeadings[2]}</Text>
                </Space>

                <ContainerStyled
                    displayMobile="none"
                    justifyMobile="center"
                    justifyTablet="center"
                >
                    <Space
                        margin={["5% 0px"]}
                        tabletmargin={["8% 0"]}
                        mobilemargin={["10% 0px"]}
                    >
                        <Image
                            src={loginData.background.image}
                            borderRadius="5%"
                            alt="banner_image"
                            width="60%"
                            tabletWidth="100%"
                        />
                    </Space>
                </ContainerStyled>
            </Flex.Item>
            <Flex.Item width="30%" tabletWidth="100%" mobileWidth="100%">
                <Space tabletmargin={["10% 0"]} mobilemargin={["10% 0"]}>
                    <div>
                        {!firstAttempt && loading ? (
                            <Text align="center" color="primary" size="xl">
                                <Loading />
                            </Text>
                        ) : !firstAttempt && error ? (
                            <div>
                                {showAlert && (
                                    <AlertMessage
                                        message={message}
                                        error={error}
                                        onClick={() => setShowAlert(false)}
                                    />
                                )}
                            </div>
                        ) : (
                            <Space mobilemargin={["50px 0px 0px 0px"]}>
                                <Card
                                    borderRadius="10px"
                                    backgroundColor="white"
                                    shadow="rgba(17, 17, 26, 0.2) 0px 0px 16px"
                                >
                                    <>
                                        <ContainerStyled
                                            justifyContent="space-between"
                                            alignItems="center"
                                            paddingTop="20px"
                                            paddingLeft="20px"
                                            paddingRight="20px"
                                        >
                                            <Flex.Item>
                                                <Text>Reset Password</Text>
                                            </Flex.Item>
                                        </ContainerStyled>
                                        <Line
                                            height="1px"
                                            backgroundColor="lightGrey"
                                            margin="10px 0"
                                            borderRadius="5px"
                                        />
                                        <ContainerStyled
                                            paddingBottom="20px"
                                            paddingLeft="20px"
                                            paddingRight="20px"
                                        >
                                            <Form width="100% ">
                                                <Label
                                                    label="Password"
                                                    required
                                                    size="s"
                                                    marginBottom="5px"
                                                />
                                                <Input
                                                    name="password"
                                                    value={formData.password}
                                                    errorMessage={
                                                        formError.passwordError
                                                    }
                                                    onChange={
                                                        handleChangeFunction
                                                    }
                                                    margin="0 0 20px 0"
                                                    type={
                                                        showPass
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    onIconClick={() =>
                                                        setShowPass(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                    icon={
                                                        showPass
                                                            ? faEyeSlash
                                                            : faEye
                                                    }
                                                />

                                                <Label
                                                    label="Confirm Password"
                                                    required
                                                    size="s"
                                                    marginBottom="5px"
                                                />

                                                <Input
                                                    name="confirmPassword"
                                                    value={
                                                        formError.confirmPassword
                                                    }
                                                    errorMessage={
                                                        formError.confirmPassError
                                                    }
                                                    onChange={
                                                        handleChangeFunction
                                                    }
                                                    margin="0 0 20px 0"
                                                    type={
                                                        showPass
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    onIconClick={() =>
                                                        setShowPass(
                                                            (prev) => !prev
                                                        )
                                                    }
                                                    icon={
                                                        showPass
                                                            ? faEyeSlash
                                                            : faEye
                                                    }
                                                />

                                                {isTfaEnable && (
                                                    <OtpInput
                                                        value={formData.otp}
                                                        onChange={
                                                            handleChangeFunction
                                                        }
                                                        errorMessage={
                                                            formError.otpError
                                                        }
                                                        size={OTP_LENGTH}
                                                    />
                                                )}

                                                <ButtonStyled
                                                    onClick={handleSubmit}
                                                    type="solid"
                                                    color="white"
                                                    backgroundColor="primary"
                                                    size="s"
                                                    textTransform="uppercase"
                                                    disabled={loading}
                                                    isLoading={loading}
                                                >
                                                    Update Password
                                                </ButtonStyled>
                                                {message && (
                                                    <Space
                                                        margin={["15px 0 0 0"]}
                                                    >
                                                        <Text
                                                            size="s"
                                                            color={
                                                                error
                                                                    ? "error"
                                                                    : "primary"
                                                            }
                                                            weight="bold"
                                                            align="center"
                                                        >
                                                            {message}
                                                        </Text>
                                                    </Space>
                                                )}
                                            </Form>
                                        </ContainerStyled>
                                    </>
                                </Card>
                            </Space>
                        )}
                    </div>
                </Space>
            </Flex.Item>
        </ContainerMain>
    );
};

export default ResetScreen;
