import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Space from "../space/Space";
import { P } from "../styles/Typography.styles";
import Text from "../text/Text";

const AccordionWrapper = styled.div`
    padding: 15px 1rem;
    background-color: ${(props) => props.theme.color.white};
    margin-bottom: 1rem;
    border-radius: 1rem;
    font-size: ${(props) => props.theme.fontSize.default.m};
`;

const CollapsibleContainer = styled.div`
    overflow: hidden;
    padding: 0 10px;
    transition: all 0.5s ease-in-out;
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    ${P} {
        flex: 1;
        margin-right: 1rem;
    }

    &:hover {
        cursor: pointer;
    }
`;

const Arrow = styled.div`
    transform: rotate(45deg);
    margin-right: 20px;
    transition: all 0.6s cubic-bezier(0.08, 1.09, 0.32, 1.275);
`;

const Accordion = (props) => {
    const { isControlled } = props;

    const [openState, setOpenState] = useState(props.isOpen || false);

    const clickHandler = useCallback(() => {
        if (isControlled) return;
        setOpenState(!openState);
    }, [isControlled, openState]);

    const isOpen = isControlled ? props.isOpen : openState;
    return (
        <AccordionWrapper>
            <LabelContainer onClick={clickHandler}>
                <P
                    xxs={props.size}
                    weight={props.weight || "sb"}
                    st={props.size}
                    lg={props.size}
                    color="primaryUpdated"
                >
                    {props.label}
                </P>
                {isControlled ? null : (
                    <Arrow
                        style={{
                            transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
                        }}
                    >
                        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
                    </Arrow>
                )}
            </LabelContainer>
            <CollapsibleContainer
                style={{
                    maxHeight: isOpen ? "5000px" : 0,
                }}
            >
                <Space padding={["10px 0px 0px 20px"]}>
                    <Text size={props.size} color="black800">
                        {props.children}
                    </Text>
                </Space>
            </CollapsibleContainer>
        </AccordionWrapper>
    );
};

export default Accordion;
