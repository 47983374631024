import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import { DetailsCardOverride } from "./DetailsSidebar";

const EscapeButton = styled(Button)`
    padding: 5px;
    display: inline-block;
    border: none;
    margin-right: 15px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-right: 35px;
        padding: 20px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-right: 15px;
        padding: 5px;
    }
    :hover,
    :focus {
        box-shadow: none;
    }
`;

const DrawerBox = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    ${DetailsCardOverride} {
        box-shadow: none !important;
    }

    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${(props) => (props.active ? "100%" : "auto")};
    overflow-y: ${(props) => (props.active ? "" : "hidden")};
    background: rgba(0, 0, 0, 0.35);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .content {
        padding: 10px 0px;
        background-color: ${(props) => props.theme.color.pureWhite};
        box-shadow: 0px -3px 4px rgb(0 0 0 / 20%);
        transition: all ease 0.3s;
        min-height: ${(props) => (props.active ? "300px" : "58px")};
        overflow-y: auto;
        .initial {
            flex-direction: column;
            height: ${(props) => (props.active ? "0px" : "auto")};
            overflow: ${(props) => (props.active ? "hidden" : "visible")};
        }
        .card {
            background: ${(props) => props.theme.color.pureWhite};
            height: ${(props) => (props.active ? "auto" : "0px")};
            overflow: ${(props) => (props.active ? "visible" : "hidden")};
        }
    }
`;

const ProjectDrawer = ({ children }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (active) {
            document.body.style.overflow = "hidden";
        }
        return () => (document.body.style.overflow = "unset");
    }, [active]);
    return (
        <DrawerBox active={active}>
            <div className="content">
                <Flex.Container
                    className="initial"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        type="solid"
                        backgroundColor="primary"
                        color="white"
                        onClick={() => setActive((active) => !active)}
                    >
                        Invest Now
                    </Button>
                </Flex.Container>
                <div className="card">
                    <Flex.Container justifyContent="flex-end">
                        <Flex.Item>
                            <EscapeButton
                                onClick={() => setActive((active) => !active)}
                                fontSize="l"
                            >
                                <FaTimes />
                            </EscapeButton>
                        </Flex.Item>
                    </Flex.Container>
                    <Flex.Container>
                        <Flex.Item style={{ width: "100%" }} width="100%">
                            {children}
                        </Flex.Item>
                    </Flex.Container>
                </div>
            </div>
        </DrawerBox>
    );
};

export default ProjectDrawer;
