import React from "react";
import styled from "styled-components";
import Flex from "../components/flex/Flex";
import ModalComponent from "../components/modal/Modal";
import Text from "../components/text/Text";

const ModalContainer = styled.div`
    background: ${(props) => props.theme.color.white};
    border-radius: 8px;
`;

const Header = styled.div`
    background: ${(props) => props.theme.color.darkSecondary};
    border-radius: 8px 8px 0 0;
    padding: 1em 2em;
`;
const Content = styled(Flex.Container)`
    flex-direction: column;
    padding: 3rem 2rem;
`;
const ListingSuccessModal = ({ onClose, message }) => {
    return (
        <ModalComponent isOpen={message} onClose={onClose}>
            <ModalContainer>
                <Header>
                    <Text color="white">Hurray !!!!</Text>
                </Header>
                <Content>
                    <Text>
                        {`You have successfully invested ${message?.invested} for ${message?.term}Y term`}
                    </Text>
                </Content>
            </ModalContainer>
        </ModalComponent>
    );
};

export default ListingSuccessModal;
