import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
*, *::after,*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html{
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Raleway', sans-serif;
    scroll-behavior: smooth;
}
body{
    overflow-x:hidden;
    min-height: 100vh;
    background: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.darkGrey};
    &::-webkit-scrollbar {
        width: 15px;
    }

    &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.color.white};
        border-left: 1px solid ${(props) => props.theme.color.grey300};
    }

    &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.color.lightGrey};
        border: 3px solid ${(props) => props.theme.color.white};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb{
        :hover {
            background: ${(props) => props.theme.color.grey600};
        } 
    }

     ;
}
img{
    max-width: 100%;
    height: auto;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}

a,
img,
data,
button,
ion-icon { display: block; }

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: none;
}

address { font-style: normal; }



`;
export default GlobalStyles;
