import React from "react";
import useProjectsNotification from "../hooks/useProjectsNotification";
import useRecentNotification from "../hooks/useRecentNotification";
import OurProjects from "./OurProjects/OurProjects";
import ProjectsHero from "./hero/ProjectsHero";

const Projects = () => {
    useRecentNotification();
    const { content, notification, setProtectNew } = useProjectsNotification();

    return (
        <div>
            <ProjectsHero />
            <OurProjects />
            {/* <NotifyToast
                content={content}
                isActive={notification}
                delay={5}
                onChange={setProtectNew}
            /> */}
        </div>
    );
};

export default Projects;
