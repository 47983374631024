import React, { useState } from "react";
import YourInvestment from "./investment/YourInvestment";
import PortfolioHero from "./portfolio-hero/Portfolio-Hero";

const Portfolio = () => {
    const [investedAssets, setInvestedAssets] = useState([]);

    return (
        <div>
            <PortfolioHero investedAssets={investedAssets} />
            <YourInvestment onSetAssets={setInvestedAssets} />
        </div>
    );
};

export default Portfolio;
