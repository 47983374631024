import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { BANK_VERIFICATION_POST_MSG, ERROR_MSG } from "../constants/common";
import { SET_ERROR } from "../constants/contexConstant";
import { BANK_VERIFICATION_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { validBankInfo } from "../helpers/fieldValidation";
import auth from "../services/auth";
import request from "../services/request";
import BottomLineInput from "./BottomLineInput";
import { CheckBox } from "./DocumentInfo";

const BankInfo = () => {
    const { state, dispatch } = useContextState();
    const [loading, setLoading] = useState(false);

    const [details, setDetails] = useState({
        fullName: state?.user?.name || "",
        ifsc: state?.user?.bankDisplay?.ifsc || "",
        accNumber: state?.user?.bankDisplay?.accountNumber || "",
        tos: false,
    });
    const [error, setError] = useState({});
    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDetails({ ...details, [name]: value });
    };

    const handleSubmit = async () => {
        // @TODO -- Details to BackEnd -- Monday,17
        const getValidFormData = validBankInfo(details);
        setError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            /* @TODO ==> Verification needed from front end side  Sun Mar 27  */
            try {
                const bankData = await request.authPost({
                    endpoint: BANK_VERIFICATION_ENDPOINT,
                    body: {
                        accountName: details.fullName,
                        ifsc: details.ifsc,
                        accountNumber: details.accNumber,
                    },
                });
                setDetails({
                    ...details,
                    accountNumber: bankData?.data?.bank_kyc?.accountNumber,
                    ifsc: bankData?.data?.bank_kyc?.ifsc,
                    name: details.fullName,
                });

                auth.getUserProfile(dispatch);

                setLoading(false);
                toast.success(BANK_VERIFICATION_POST_MSG);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: error || ERROR_MSG,
                });
            }
        }
    };
    return (
        <div>
            <Space margin={["6% 0 0 0"]}>
                <Flex.Container justifyContent="space-between">
                    <Flex.Item
                        width="45%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Space
                            margin={["0 0 40px 0"]}
                            tabletmargin={["0 0 20px 0"]}
                        >
                            <div>
                                <BottomLineInput
                                    label="Full Name"
                                    placeholder="Full Name"
                                    type="text"
                                    margin="7px 0 0 0 "
                                    value={details.fullName}
                                    name="fullName"
                                    onChange={handleOnChange}
                                    error={error.fullName}
                                />
                            </div>
                        </Space>
                        <Space
                            margin={["0 0 40px 0"]}
                            tabletmargin={["0 0 20px 0"]}
                        >
                            <div>
                                <BottomLineInput
                                    label="IFSC Code"
                                    placeholder="Enter your IFSC Code"
                                    onChange={handleOnChange}
                                    name="ifsc"
                                    margin="7px 0 0 0 "
                                    value={details.ifsc}
                                    error={error.ifsc}
                                />
                            </div>
                        </Space>
                    </Flex.Item>
                    <Flex.Item
                        width="45%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Space
                            margin={["0 0 40px 0"]}
                            tabletmargin={["0 0 20px 0"]}
                        >
                            <div>
                                <BottomLineInput
                                    label="Account Number"
                                    placeholder="Enter Account Details"
                                    onChange={handleOnChange}
                                    name="accNumber"
                                    margin="7px 0 0 0 "
                                    value={details.accNumber}
                                    error={error.accNumber}
                                />
                            </div>
                        </Space>
                    </Flex.Item>
                </Flex.Container>
            </Space>
            <Flex.Container justifyContent="space-between" alignItems="center">
                <Space margin={["0px 0 20px 0 "]}>
                    <Flex.Item
                        width="100%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Flex.Container
                            style={{ flexWrap: "nowrap" }}
                            alignItems="center"
                        >
                            <CheckBox
                                style={{
                                    marginRight: "5px",
                                    height: "auto",
                                }}
                                type="checkbox"
                                name="tos"
                                id="tos"
                                value={details.tos}
                                checked={details.tos}
                                onChange={(e) => {
                                    setDetails({
                                        ...details,
                                        tos: !details.tos,
                                    });
                                }}
                            />

                            <Text size="m">
                                <InlineText color="primary">
                                    I agree.{" "}
                                </InlineText>
                                Please upload non-password protected file only
                                Max size 3MB
                            </Text>
                        </Flex.Container>
                        {error.tos && (
                            <Space margin={["7px 0 0 0"]}>
                                <Text size="xs" color="error">
                                    {error.tos}
                                </Text>
                            </Space>
                        )}
                    </Flex.Item>
                </Space>

                <Flex.Item width="100%" tabletWidth="100%" mobileWidth="100%">
                    <Flex.Container justifyContent="flex-end">
                        {/* <Space mobilemargin="0 0 20px 0">
                            <Flex.Item mobileWidth="100%">
                                <Button
                                    fontSize="m"
                                    backgroundColor="grey"
                                    type="solid"
                                    style={{ width: "100%" }}
                                >
                                    Prev
                                </Button>
                            </Flex.Item>
                        </Space> */}

                        <Flex.Item mobileWidth="100%">
                            <Button
                                onClick={handleSubmit}
                                fontSize="m"
                                backgroundColor="primary"
                                type="solid"
                                style={{ width: "100%" }}
                                disabled={loading || !details.tos}
                                isLoading={loading}
                            >
                                Submit
                            </Button>
                        </Flex.Item>
                    </Flex.Container>
                </Flex.Item>
            </Flex.Container>
        </div>
    );
};

export default BankInfo;
