import { FaMapMarkerAlt } from "react-icons/fa";
import { portfolio } from "../assets";
import { PORTFOLIO } from "./routes";

export const portfolioHero = {
    heading: "Be the Change you want to see !!",
    subHead:
        "Join virtual solar project and get monthly payouts for the power your solar delivers to consumers across the country",
    img: portfolio,
    tags: [
        {
            id: 1,
            value: "15",
            name: "Total Investment",
        },
        {
            id: 2,
            value: "10",
            unit: "Cr",
            name: "Amount Invested",
        },
        {
            id: 3,
            value: "2.5",
            unit: "MWp",
            name: "Solar Asset Owned",
        },
        {
            id: 4,
            value: "0.25",
            unit: "Tons",
            name: "Carbon Reduced",
        },
        {
            id: 4,
            value: "15",
            unit: "Trees",
            name: "Trees Planted",
        },
    ],
};

export const portfolioCardData = {
    heading: "Your Investment",
    cards: [
        {
            id: 1,
            img: "https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            isOpen: true,
            status: "Active",
            location: "Pune",
            name: "F Residency",
            link: PORTFOLIO,
            tags: [
                {
                    id: 1,
                    value: "150",
                    unit: "KWp",
                    name: "Solar Capacity",
                },
                {
                    id: 2,
                    value: "10 MAR 2023",
                    unit: "",
                    name: "Funding Date",
                },
            ],
            values: [
                {
                    id: 1,
                    name: "Lock-in Period",
                    value: "12 Months",
                },
                {
                    id: 2,
                    name: "Return (IRR)",
                    value: "16.5%",
                },
            ],
            progress: 100,
            button: {
                head: "50 L (100 KWp)",
                subHead: "Investment Amount",
            },
        },
        {
            id: 2,
            img: "https://images.pexels.com/photos/1475938/pexels-photo-1475938.jpeg",
            isOpen: true,
            status: "Songsbirds",
            location: "Pune",
            name: "F Residency",
            link: PORTFOLIO,
            tags: [
                {
                    id: 1,
                    value: "100",
                    unit: "KWp",
                    name: "Solar Capacity",
                },
                {
                    id: 2,
                    value: "05 DEC 2022",
                    unit: "",
                    name: "Investment Date",
                },
            ],
            values: [
                {
                    id: 1,
                    name: "Lock-in Period",
                    value: "12 Months",
                },
                {
                    id: 2,
                    name: "Return (IRR)",
                    value: "16.5%",
                },
            ],
            progress: 100,
            button: {
                head: "10 L (20 KWp)",
                subHead: "Investment Amount",
            },
        },

        {
            id: 3,
            img: "https://images.pexels.com/photos/5331879/pexels-photo-5331879.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            isOpen: true,
            status: "Panchwati",
            location: "Pune",
            name: "F Residency",
            link: PORTFOLIO,
            tags: [
                {
                    id: 1,
                    value: "100",
                    unit: "KWp",
                    name: "Solar Capacity",
                },
                {
                    id: 2,
                    value: "05 DEC 2022",
                    unit: "",
                    name: "Investment Date",
                },
            ],
            values: [
                {
                    id: 1,
                    name: "Lock-in Period",
                    value: "12 Months",
                },
                {
                    id: 2,
                    name: "Return (IRR)",
                    value: "16.5%",
                },
            ],
            progress: 100,
            button: {
                head: "50 L (100 KWp)",
                subHead: "Investment Amount",
            },
        },
    ],
};

export const portfolioDetails = {
    timeline: [
        {
            id: 1,
            isCompleted: true,
            status: "Paid",
            date: "12 Oct 2020",
            summary: "Hosting site survey and Host financial vetting completed",
        },
        {
            id: 2,
            isCompleted: true,
            status: "Procuring",
            date: "16 Nov 2020",
            summary: "Project constructed and installed by our EPC partner.",
        },
        {
            id: 3,
            isCompleted: true,
            status: "Installing",
            date: "12 Oct 2020",
            summary:
                "Project Activation and Integration of project monitoring services.",
        },
        {
            id: 4,
            isCompleted: true,
            status: "Active",
            date: "05 Dec 2020",
            summary:
                "This digital solar project is open for reserving capacity.",
        },
        {
            id: 5,
            isCompleted: false,
            status: "Closed",
            date: "13 Dec 2030",
            summary:
                "This digital solar project is open for reserving capacity.",
        },
    ],

    details: {
        heading: "About the Project",
        about: "Pioneer is our pilot project, the one that started it all. The 5KW system is hosted on the rooftops of a residential space in North Bangalore. Being closer to our place of work, made it ideal for testing and an initial release.",
        img: "https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        isOpen: true,
        status: "Funding",
        location: "Pune",
        name: "Songsbirds",
        tags: [
            {
                id: 1,
                value: "Songsbirds",
                name: "Pune",
                icon: FaMapMarkerAlt,
            },
            {
                id: 2,
                value: "100",
                unit: "KWp",
                name: "Solar Capacity",
            },
            {
                id: 2,
                value: "DEC 2022",
                unit: "KWp",
                name: "Solar Capacity",
            },
        ],
        values: [
            {
                id: 1,
                name: "Total Investment",
                value: "Rs. 56.9 L",
            },
            {
                id: 2,
                name: "Investment Raised",
                value: "12 Lacs",
            },
            {
                id: 3,
                name: "Lock-in Period",
                value: "12 Months",
            },
            {
                id: 4,
                name: "Return (IRR)",
                value: "16.5%",
            },
            {
                id: 5,
                name: "Minimum Investment",
                value: "10,000",
            },
        ],
        progress: 100,
    },
};
