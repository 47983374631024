import React from "react";
import styled from "styled-components";
import Circle from "../components/circle/Circle";
import Flex from "../components/flex/Flex";
import Line from "../components/line/Line";
import { InlineText } from "../components/text/HeadingText";
import theme from "../theme";

const CircleExtend = styled(Circle)`
    position: relative;
    span {
        position: absolute;
        top: 30px;
        color: black;
    }
`;
const InlineLabel = styled(InlineText)`
    display: inline-block;
    color: ${(props) => props.theme.color.primary};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: ${(props) => (props.isActive ? "inline-block" : "none")};
    }
`;

const ProgressStepper = ({ data, padding = "40px 0px 20px" }) => {
    const { activeStatus } = data;
    return (
        <div style={{ padding }}>
            <Flex.Container alignItems="center">
                {steps.map((item, index) => (
                    <>
                        <CircleExtend
                            backgroundColor={
                                activeStatus >= item.step ? "cyan" : "grey600"
                            }
                            size="20px"
                        >
                            {/* <InlineLabel
                                style={{
                                    left: index === 0 ? 0 : null,
                                    right: data.length - 1 === index ? 0 : null,
                                    top: -30,
                                    color: theme.color.black800,
                                }}
                                align={
                                    index === 0
                                        ? "left"
                                        : index === data.length - 1
                                        ? "right"
                                        : "center"
                                }
                                size="s"
                            >
                                {item.paidAt}
                            </InlineLabel> */}
                            <InlineLabel
                                isActive={item.step === activeStatus}
                                size="s"
                                color="cyan"
                                style={{
                                    left: index === 0 ? 0 : null,
                                    right:
                                        steps.length - 1 === index ? 0 : null,
                                    color: theme.color.black800,
                                }}
                                align={
                                    index === 0
                                        ? "left"
                                        : index === data.length - 1
                                        ? "right"
                                        : "center"
                                }
                            >
                                {item.label}
                            </InlineLabel>
                        </CircleExtend>
                        {index < steps.length - 1 && (
                            <Line
                                backgroundColor={
                                    activeStatus - 1 >= item.step
                                        ? "cyan"
                                        : "grey600"
                                }
                                flex="1"
                                height="3px"
                            />
                        )}
                    </>
                ))}
            </Flex.Container>
        </div>
    );
};

export default ProgressStepper;

const steps = [
    {
        step: 1,
        label: "Paid",
        paidAt: "02/12/22",
    },
    {
        step: 2,
        label: "Procuring",
        paidAt: "02/12/22",
    },
    {
        step: 3,
        label: "Installing",
        paidAt: "",
    },
    {
        step: 4,
        label: "Active",
        paidAt: "",
    },
    {
        step: 5,
        label: "Closed",
        paidAt: "",
    },
];
