import React from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container";
import FlexImg from "../../components/styles/FlexImg";
import { HideMobile, ShowMobile } from "../../components/styles/HideSection";
import Text from "../../components/text/Text";
import { portfolioHero as data } from "../../constants/portfolioConstants";
import {
    getCurrencyAmount,
    getSolarAssetsWithUnit,
} from "../../helpers/helpers";
import { TagItem, TagsContainer } from "../../projects/Tag";
import ListingItem from "../../projects/project-details/ListingItem";
const Section = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.color.black800};
    min-height: 70vh;
    align-items: flex-end;
    padding: 3rem 0;

    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        min-height: auto;
        padding: 3rem 0 0;
        .removeMobile {
            display: none;
        }
        background-color: ${(props) => props.theme.color.white};
    }
`;

const SROT_CARBON_VALUE = 1.39;
const getAssetsData = (investedAssets) => {
    let totalCount = 0;
    let solarAssets = 0;
    let totalAmountInvested = 0;
    let trees = 0;

    investedAssets.forEach((item) => {
        totalCount += 1;
        solarAssets += Number(item.solar_asset_owned);
        totalAmountInvested += Number(item.investment_amount);
    });

    let currencyWithUnit = getCurrencyAmount(totalAmountInvested);
    let currencyValue = "";
    let currencyUnit = "";
    if (currencyWithUnit.includes("Cr")) {
        currencyValue = currencyWithUnit.slice(0, currencyWithUnit.length - 2);
        currencyUnit = currencyWithUnit.slice(currencyWithUnit.length - 2);
    } else {
        currencyValue = currencyWithUnit.slice(0, currencyWithUnit.length - 1);
        currencyUnit = currencyWithUnit.slice(currencyWithUnit.length - 1);
    }
    let solarWithUnit = getSolarAssetsWithUnit(solarAssets);

    let carbonReduced = (solarAssets * SROT_CARBON_VALUE).toFixed(2);

    return [
        {
            id: 1,
            value: totalCount,
            name: "Total Investment",
        },
        {
            id: 2,
            value: currencyValue,
            unit: currencyUnit,
            name: "Amount Invested",
        },
        {
            id: 3,
            value: solarWithUnit.slice(0, solarWithUnit.length - 3),
            unit: solarWithUnit.slice(solarWithUnit.length - 3),
            name: "Solar Asset Owned",
        },
        {
            id: 4,
            value: carbonReduced,
            unit: "Tons",
            name: "Carbon Reduced",
        },
        // @TODO ==>   Fix This Mon May  29
        {
            id: 5,
            value: trees,
            unit: "Trees",
            name: "Trees Planted",
        },
    ];
};
const PortfolioHero = ({ investedAssets }) => {
    getAssetsData(investedAssets);
    let content = null;

    if (investedAssets.length > 0) {
        content = (
            <>
                <HideMobile>
                    <Space margin={["3rem 0 0"]}>
                        <TagsContainer bg="white">
                            {getAssetsData(investedAssets).map((tag) => (
                                <TagItem
                                    {...tag}
                                    sizeS="s"
                                    color="primary"
                                    size="l"
                                />
                            ))}
                        </TagsContainer>
                    </Space>
                </HideMobile>
                <ShowMobile>
                    <Space margin={["3rem 0 1rem"]}>
                        <ListingContainer>
                            {getAssetsData(investedAssets).map((tag) => (
                                <ListingItem
                                    key={tag.id}
                                    name={tag.name}
                                    value={`${tag.value} ${tag?.unit || ""}`}
                                    margin="1rem"
                                    color="white"
                                />
                            ))}
                        </ListingContainer>
                    </Space>
                </ShowMobile>
            </>
        );
    }

    return (
        <div>
            <div>
                <Section>
                    <Container>
                        <Space
                            padding={["4rem 0 "]}
                            mobilepadding={["4rem 0 0"]}
                        >
                            <Flex.Container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <FlexImg
                                    width="40%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                    className="removeMobile"
                                >
                                    <Image
                                        src={data.img}
                                        alt="portfolio_hero"
                                    />
                                </FlexImg>
                                <Flex.Item
                                    width="48%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                    className="removeMobile"
                                >
                                    <Space margin={["1rem 0"]}>
                                        <Text
                                            lineHeight="1.2em"
                                            size="xml"
                                            align="center"
                                            color="white"
                                            fontFamily="Montserrat"
                                            weight="700"
                                        >
                                            {data.heading}
                                        </Text>
                                    </Space>
                                    <Text
                                        lineHeight="1.2em"
                                        size="m"
                                        align="center"
                                        color="white"
                                        fontFamily="Montserrat"
                                    >
                                        {data.subHead}
                                    </Text>
                                </Flex.Item>
                                <Flex.Item
                                    width="100%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    {content}
                                </Flex.Item>
                            </Flex.Container>
                        </Space>
                    </Container>
                </Section>
            </div>
        </div>
    );
};

export default PortfolioHero;

const ListingContainer = styled.div`
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    color: black;
    padding: 1rem 1rem;
    border-radius: 1rem;
    background-color: ${(props) => props.theme.color.darkGrey};
    .value {
        color: ${(props) => props.theme.color.orange};
        font-weight: 600;
    }
`;
