import { useEffect, useState } from "react";
import { SHIFT_PROJECTS_NOTIFICATION_QUEUE } from "../constants/contexConstant";
import { useContextState } from "../context/ContextProvider";

const useProjectsNotification = () => {
    const { state, dispatch } = useContextState();
    const list = state?.projectsNotifyQueue;

    const [notification, setNotification] = useState(null);
    const [activate, setActivate] = useState(true);
    const [delay, setDelay] = useState(false);
    const [preventRender, setPreventRender] = useState(true);
    const [content, setContent] = useState(null);
    const [protectNew, setProtectNew] = useState(true);

    useEffect(() => {
        if (
            list &&
            list?.length > 0 &&
            activate &&
            !preventRender &&
            protectNew
        ) {
            let newLists = [...list];
            let sliced = newLists.shift();
            setNotification(sliced);
            setContent(sliced);
            setActivate(false);
            dispatch({
                type: SHIFT_PROJECTS_NOTIFICATION_QUEUE,
                payload: newLists,
            });
            setProtectNew(false);
        }

        if (notification) {
            setTimeout(() => {
                setNotification(null);
                setDelay(true);
            }, 5000);
        }

        if (delay) {
            setTimeout(() => {
                setActivate(true);
                setDelay(false);
            }, 5000);
        }

        if (preventRender) {
            setTimeout(() => {
                setPreventRender(false);
            }, 5000);
        }
    }, [
        list,
        dispatch,
        activate,
        delay,
        preventRender,
        notification,
        protectNew,
    ]);

    return { content, notification, setProtectNew };
};

export default useProjectsNotification;
