import React from "react";
import Circle from "../circle/Circle";
import Flex from "../flex/Flex";
import Space from "../space/Space";
import { P } from "../styles/Typography.styles";

const BulletList = ({
    title,
    color = "lightGrey",
    margin = "2% 0",
    fontSize = "m",
    size = "7px",
}) => {
    return (
        <Flex.Container
            alignItems="flex-start"
            style={{ flexWrap: "nowrap", margin: margin }}
        >
            <Space margin={[".7em 10px 0 10px"]}>
                <Circle
                    size={size}
                    backgroundColor={color}
                    style={{ flexShrink: 0 }}
                />
            </Space>
            <P align="left" color={color} size={fontSize}>
                {title}
            </P>
        </Flex.Container>
    );
};

export default BulletList;
