import { FiCheck } from "react-icons/fi";
import styled from "styled-components";
import Circle from "../components/circle/Circle";
import Flex from "../components/flex/Flex";
import Line from "../components/line/Line";
import Space from "../components/space/Space";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import useWindowDimensions from "../hooks/useWindowDimensions";
import theme, { MOBILE_BREAKPOINT } from "../theme";

const CircleExtend = styled(Circle)`
    position: relative;
    span {
        position: absolute;
        top: 30px;
        color: black;
    }
`;
const InlineLabel = styled(InlineText)`
    width: max-content;
    /* display: inline-block; */
    position: absolute;
    color: ${(props) => props.theme.color.primary};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        /* display: ${(props) => (props.isActive ? "inline-block" : "none")}; */
    }
`;
const StatusStepper = ({ data, padding = "40px 0px 20px" }) => {
    const { activeStatus } = data;

    const { width } = useWindowDimensions();
    const isMobile = width <= MOBILE_BREAKPOINT;

    return (
        <div>
            <div style={{ padding }}>
                <Flex.Container
                    style={
                        isMobile
                            ? {
                                  height: "400px",
                                  width: "20px",
                              }
                            : {}
                    }
                    direction={isMobile ? "column" : ""}
                    alignItems="center"
                    justifyContent={isMobile ? "center" : ""}
                >
                    {steps.map((item, index) => (
                        <>
                            <CircleExtend
                                backgroundColor={
                                    activeStatus > item.step
                                        ? "cyan"
                                        : "grey600"
                                }
                                size="20px"
                            >
                                <InlineLabel
                                    style={{
                                        left: isMobile
                                            ? 40
                                            : index === 0
                                            ? 0
                                            : null,
                                        top: isMobile ? 0 : -30,
                                        right: isMobile
                                            ? null
                                            : item.step === 3
                                            ? 0
                                            : null,
                                        color: theme.color.black800,
                                    }}
                                    align={
                                        index === 0
                                            ? "left"
                                            : index === data.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                    size="s"
                                    weight="bold"
                                >
                                    {item.label}
                                </InlineLabel>

                                <InlineLabel
                                    isActive={item.step === activeStatus}
                                    size="s"
                                    color="cyan"
                                    style={{
                                        left: isMobile
                                            ? 40
                                            : index === 0
                                            ? 0
                                            : null,
                                        right:
                                            steps.length - 1 === index
                                                ? 0
                                                : null,
                                        color: theme.color.black800,
                                    }}
                                    align={
                                        isMobile
                                            ? "left"
                                            : index === 0
                                            ? "left"
                                            : index === steps.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                >
                                    <StatusButton
                                        name={item.btn}
                                        active={false}
                                        // onClick={}
                                    />
                                    <Space margin={["10px 0 0"]}>
                                        <Text align="left" size="s" color="red">
                                            Click to Download Agreement
                                        </Text>
                                    </Space>
                                </InlineLabel>
                                <FiCheck />
                            </CircleExtend>
                            {index < steps.length - 1 && (
                                <Line
                                    backgroundColor={
                                        activeStatus - 1 >= item.step
                                            ? "cyan"
                                            : "grey600"
                                    }
                                    flex="1"
                                    width={isMobile ? "5px" : ""}
                                    height={isMobile ? "100%" : "3px"}
                                />
                            )}
                        </>
                    ))}
                </Flex.Container>
            </div>
        </div>
    );
};

export default StatusStepper;

const steps = [
    {
        step: 1,
        label: "KYC Status",
        btn: "KYC Completed",
    },
    {
        step: 2,
        label: "Investment Agreement",
        btn: "Agreement Signed",
    },
    {
        step: 3,
        label: "Investment Payouts",
        btn: "Payouts Generated",
    },
];

const StatusButton = ({ name, onClick, active, disabled = false }) => {
    return (
        <StatusBtn
            disabled={disabled}
            onClick={onClick}
            color={disabled ? "lightGrey" : "primary"}
        >
            <CircleStyled
                size="13px"
                backgroundColor={disabled ? "lightGrey" : "primary"}
                margin="0 10px 0 0"
            />
            <Text weight="700" size="s">
                {name}
            </Text>
        </StatusBtn>
    );
};

const CircleStyled = styled(Circle)`
    @media (max-width: 700px) {
        display: none;
    }
`;

const StatusBtn = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 10px;
    border-radius: 10px;
    border: 1px solid;
    font-size: ${(props) => props.theme.fontSize.tablet.s};
    border-color: ${(props) =>
        props.disabled
            ? props.theme.color.lightGrey
            : props.theme.color[props.color]};
    :hover {
        box-shadow: ${(props) =>
            props.disabled ? "none" : "2px 2px 4px rgba(0, 0, 0, 0.25)"};
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }
`;
