let desktopNewFont = {
    xxl: "4.2rem",
    xml: "3.6rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.6rem",
    xs: "0.4rem",
};
let extraLargeNewFont = {
    xxl: "3.6rem",
    xml: "3rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.6rem",
    xs: "1.3rem",
};

let largeNewFont = {
    xxl: "3.2rem",
    xml: "2.8rem",
    xl: "2.4rem",
    l: "2rem",
    m: "1.8rem",
    s: "1.4rem",
    xs: "1.2rem",
};

let tabletNewFont = {
    xxl: "3.6rem",
    xml: "2.8rem",
    xl: "2.2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
};

let mobileNewFont = {
    xxl: "3.4rem",
    xml: "2.4rem",
    xl: "2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
};

let smallMobile = {
    xxl: "2.8rem",
    xml: "2.4rem",
    xl: "2rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
};

let globalFont = {
    xxl: "4rem",
    xml: "3.6rem",
    xl: "3.2rem",
    l: "2.4rem",
    ml: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
    xxs: "1rem",
};
const spacing = {
    horizontal: "8%",
    xs: ".5rem",
    sm: "1rem",
    s: "1.2rem",
    m: "1.5rem",
    l: "2rem",
    xl: "3rem",
    xml: "4rem",
    xxl: "6rem",
};
const mobileSpacing = {
    horizontal: "5%",
    xs: ".5rem",
    sm: "1rem",
    s: "1.2rem",
    m: "1.5",
    l: "2rem",
    xl: "3rem",
    xml: "4rem",
    xxl: "6rem",
};
const sizes = {
    horizontal: "8%",
    n: 0,
    xxs: ".5rem",
    xs: ".8rem",
    sm: "1rem",
    s: "1.2rem",
    m: "1.6rem",
    l: "1.8rem",
    xl: "2rem",
    xl3: "3rem",
    xl4: "4rem",
    xl5: "5rem",
    xl6: "6rem",
    xl7: "7rem",
    xl8: "8rem",
    xl9: "9rem",
    xl10: "10rem",
};

const colours = {
    red: "#ec2227",
    red100: "#FE0000",
    babyPink: "#F4C2C2",
    white: "#ffffff",
    lightGrey: "#989898",
    grey: "#333333",
    greyBg: "#f2f2f2",
    midGrey: "#dcdcdc",
    darkGrey: "#4d4d4d",
    blue: "#25689C",
    main: "#171D39",
    lightGreen: "#5cb946",
    green: "#3b8940",
    lightMehndiGreen: "#4e8e41",
    mehndiGreen: "#0d4c25",
    darkMehndiGreen: "#0b2123",
    black: "#000",
    pureWhite: "#fff",
    orange: "#F59120",
    lighterGrey: "#f9f9f9",
    darkGreen: "#01393e",
    blackFont: "#072123",
    btnLightGreen: "#5BB947",
    sharkGrey: "#808080",
    iconBgGrey: "#e7e8ea",
    orangeLight: "#E67628",
    redAlert: "#ff00002e",
    logoGreen: "#28b473",
    bannerGreen: "#ecffd5",
    success: "#d1e7dd",
    successBorder: "#badbcc",
    warning: "#fff3cd",
    warningBorder: "#ffecb5",
    danger: "#f8d7da",
    dangerBorder: "#f5c2c7",
    primaryUpdated: "#171D39",
    background: "#FBFBFB",
    // new colors
    black800: "#424242",
    indigo: "#6B5EFF",
    grey200: "#f1f1f2",
    grey250: "#EDF3FA",
    grey300: "#F6F6F6",
    grey350: "#E6E6E6",
    grey400: "#e5eaf0",
    grey450: "#F5F3F3",
    grey500: "#edf0f3",
    grey550: "#5B5B5B",
    grey600: "#c8c8c8",
    grey650: "#C1C2C3",
    cyan: "#71CFD1",

    red400: "#EE5460",
    teal: "#647D83",
};

const theme = {
    default: {
        spacing: {
            smallMobile: mobileSpacing,
            mobile: mobileSpacing,
            tablet: spacing,
            bigTablet: spacing,
            smallLaptop: spacing,
            default: spacing,
        },
        color: {
            lightPrimary: colours.lightGreen,
            primary: colours.blue,
            secondary: colours.mehndiGreen,
            darkSecondary: colours.darkMehndiGreen,
            error: colours.red,
            active: colours.lightGreen,
            ...colours,
        },
        fontSize: {
            smallMobile: smallMobile,
            mobile: mobileNewFont,
            smallTablet: tabletNewFont,
            tablet: tabletNewFont,
            large: largeNewFont,
            laptop: extraLargeNewFont,
            desktop: desktopNewFont,
            default: globalFont,
        },
        fontWeight: {
            th: "300",
            rg: "400",
            m: "500",
            sb: "600",
            b: "700",
        },
        sizes: sizes,
        gradient: {
            highlight:
                "linear-gradient(90deg, rgba(255, 255, 255, 0.102) 0px 77%, rgba(255, 255, 255, 0.502) 92%, transparent)",
        },
        transition: {
            xs: "0.25s ease",
            s: "0.5s ease",
            m: "1s ease",
            c1: "cubic-bezier(0.33, 0.85, 0.4, 0.96)",
        },
        breakPoints: {
            smallMobile: "425px",
            mobile: "570px",
            smallTablet: "768px",
            tablet: "992px",
            large: "1024px",
            laptop: "1200px",
            desktop: "1600px",
        },
    },
};

export default theme.default;

export const SMALL_MOBILE_BREAKPOINT = 350;
export const MOBILE_BREAKPOINT = 540;
export const TAB_BREAKPOINT = 940;
export const BIG_TAB_BREAKPOINT = 1030;
export const LAPTOP_BREAKPOINT = 1500;
