import React, { useEffect, useRef } from "react";
import BulletList from "../components/bullet-list/BulletList";
import Carousel from "../components/carousel/Carousel";
import Flex from "../components/flex/Flex";
import Link from "../components/link/Link";
import Space from "../components/space/Space";
import { H2, P, Span } from "../components/styles/Typography.styles";
import { carouselData } from "../constants/carouselData";
import { FAQ } from "../constants/routes";

const HowItWorks = ({ id, data, visibilityHandler }) => {
    const howItWorksRef = useRef();
    useEffect(() => {
        let target = howItWorksRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [howItWorksRef, visibilityHandler]);

    return (
        <Space padding={["50px 0"]}>
            <Flex.Container
                justifyContent="space-between"
                alignItems="center"
                id={id}
                ref={howItWorksRef}
                data-title="HOW IT WORKS"
            >
                <Flex.Item width="44%" tabletWidth="100%">
                    <Space padding={["0 0 0.5em"]}>
                        <H2 color="grey" textTransform="capitalize">
                            {data.heading}{" "}
                            <Span color="lightGrey">{data.inlineText}</Span>
                        </H2>
                    </Space>
                    <P color="grey" weight="b">
                        To Get Started, follow these steps:
                    </P>
                    <BulletList title="Complete Your Profile KYC" />
                    <BulletList title="Load Funds into the Wallet" />
                    <BulletList title="Invest into Opportunities" />
                    <Space margin={["0.5em 0 0"]}>
                        <P color="black">
                            If you still have open questions, go to{" "}
                            <Link isInline to={FAQ} color="primary">
                                FAQ
                            </Link>{" "}
                            section.
                        </P>
                    </Space>
                </Flex.Item>
                <Flex.Item width="45%" tabletWidth="100%">
                    <Space tabletpadding={["25px 0 0"]}>
                        <Flex.Container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Carousel
                                data={carouselData}
                                autoPlay
                                interval="10000"
                                padding="0 0 20px"
                            />
                        </Flex.Container>
                    </Space>
                </Flex.Item>
            </Flex.Container>
        </Space>
    );
};

export default HowItWorks;
