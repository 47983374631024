import styled from "styled-components";
import Button from "../../components/button/Button";
import { InputComponent } from "../../components/input/Input";
import Line from "../../components/line/Line";
import Space from "../../components/space/Space";
import Text from "../../components/text/Text";
import { decimalNumberRegEx } from "../../constants/regularExpression";
import { useContextState } from "../../context/ContextProvider";
import { numberWithCommas, twoDecimalLocal } from "../../helpers/helpers";
import useAuthHistory from "../../hooks/useAuthHistory";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { TAB_BREAKPOINT } from "../../theme";
import ListingItem from "./ListingItem";
import PaymentScheduleBtn from "./PaymentScheduleBtn";

const Input = styled(InputComponent)`
    background: #f7f7f7;
    border: 1px solid #c1c2c3;
    color: ${(props) => props.theme.color.black800};
    margin: 1rem 0;
    padding: 0.2rem 0.8rem;
    font-size: ${(props) => props.theme.fontSize.default[props.size]};
    line-height: 1.3em;
    ::placeholder {
        color: ${(props) => props.theme.color.lightGrey};
        font-size: ${(props) => props.theme.fontSize.desktop.m};
    }

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet[props.size]};
        ::placeholder {
            font-size: ${(props) => props.theme.fontSize.tablet.m};
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.mobile[props.size]};
        ::placeholder {
            font-size: ${(props) => props.theme.fontSize.mobile.m};
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        font-size: ${(props) => props.theme.fontSize.smallMobile[props.size]};
        ::placeholder {
            font-size: ${(props) => props.theme.fontSize.smallMobile.m};
        }
    }
`;
export const CardContainer = styled.div`
    padding: 1.5rem;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.33);
    border-radius: 0.61rem;
    .card {
        height: 300px;
        width: 100%;
        overflow: hidden;
        :hover {
            cursor: pointer;
        }
    }

    .valuesContainer {
        background-color: ${(listing) => listing.theme.color.grey300};
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 2rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        .valuesContainer {
            margin-bottom: 0;
        }
    }
`;
export const DetailsCardOverride = styled(CardContainer)`
    .cardImg {
        max-height: 300px;

        img {
            max-height: 300px;
            width: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: 1rem 1rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 1rem 0.8rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        padding: 1rem 0.6rem;
    }
`;

const getCalculatedSum = (termDetails, paymentAmount) => {
    let sum = {
        capitalPayout: 0,
        profitPayout: 0,
        remunerationPayout: 0,
    };

    for (let i = 0; i < 60; i++) {
        let capitalPayout =
            (termDetails[parseInt(i / 12)].capitalPayout * paymentAmount) /
            100000;

        let profitPayout =
            (termDetails[parseInt(i / 12)].profitPayout * paymentAmount) /
            100000;

        let remunerationPayout =
            (termDetails[parseInt(i / 12)].remunerationPayout * paymentAmount) /
            100000;
        sum.capitalPayout += capitalPayout;
        sum.profitPayout += profitPayout;
        sum.remunerationPayout += remunerationPayout;
    }
    return sum;
};

const DetailsSideBar = (props) => {
    const {
        setPaymentAmount,
        paymentAmount,
        walletBalance,
        solarAsset,
        IRR,
        investmentTerm,
        monthlyPayout,
        grossReturns,
        handleShowModal,
        tds,
        netPayout,
        isInvestmentAvailable,
        createOrderHandler,
        setShowPaymentTable,
        listingMaxLimit,
        listingMinLimit,
        setPaymentAmountError,
        paymentAmountError,
        onKycStatusClick,
        investmentTermDetails,
    } = props.data;
    const { state } = useContextState();
    const { setPath } = useAuthHistory();

    const handleChange = (e) => {
        const value = `${e.target.value}`.replace(/\,/g, "");
        if (decimalNumberRegEx.test(value)) {
            setPaymentAmount(value);
            handleInputValue(value);
        } else {
            setPaymentAmount(paymentAmount);
            handleInputValue(paymentAmount);
        }
    };

    const handleInputValue = (value) => {
        if (isNaN(value) || Number(value) < listingMinLimit) {
            setPaymentAmountError(
                `Minimum value is ${listingMinLimit.toLocaleString()}`
            );
        } else if (Number(value) > listingMaxLimit) {
            setPaymentAmountError(
                `Maximum amount is ${listingMaxLimit.toLocaleString()}`
            );
        } else {
            setPaymentAmountError("");
        }
    };

    const isKycCompleted =
        state?.user?.kycTableDisplay?.isAadharUploaded === true &&
        state?.user?.kycTableDisplay?.isPanUploaded === true &&
        (state?.user?.kycTableDisplay?.bankKYCStatus === "COMPLETED" ||
            state?.user?.kycTableDisplay?.bankKYCStatus === "CREATED") &&
        state?.user?.kycTableDisplay?.aadharNumber !== null &&
        state?.user?.kycTableDisplay?.panNumber !== null;

    const isInvestEnabled = process.env.REACT_APP_INVEST_DISABLED === "true";

    const getTotalSum = getCalculatedSum(investmentTermDetails, paymentAmount);
    const { width } = useWindowDimensions();

    return (
        <DetailsCardOverride>
            <Text lineHeight="1.2em" fontFamily="Montserrat" color="lightGrey">
                Investment Amount
            </Text>
            <Input
                onChange={handleChange}
                value={numberWithCommas(paymentAmount)}
                placeholder="Enter Investment Amount"
                size="l"
            />
            {paymentAmountError && (
                <Text color="error" size="s" lineHeight="1.2">
                    {paymentAmountError}
                </Text>
            )}
            <ListingItem
                weight="b"
                name="Solar Assets"
                value={`${twoDecimalLocal(solarAsset)}KWp`}
            />
            <ListingItem
                weight="b"
                name="Return (IRR)"
                value={`${twoDecimalLocal(IRR)}%`}
            />
            <ListingItem
                weight="b"
                name="Investment Term"
                value={`${investmentTerm}Years`}
            />
            <Line height="1px" backgroundColor="grey400" margin="1rem 0" />
            <ListingItem
                weight="b"
                name="Monthly Payout"
                value={`${twoDecimalLocal(monthlyPayout)}`}
            />
            <ListingItem
                weight="b"
                name="Total Payouts"
                value={`${twoDecimalLocal(grossReturns)}`}
            />
            <ListingItem
                weight="b"
                name="Non-Taxable Payouts"
                value={`${twoDecimalLocal(
                    getTotalSum.capitalPayout + getTotalSum.profitPayout
                )}`}
            />{" "}
            <ListingItem
                weight="b"
                name="Taxable Payouts "
                value={`${twoDecimalLocal(getTotalSum.remunerationPayout)}`}
            />
            <Space margin={["1rem 0 .3rem"]}>
                <Button
                    onClick={
                        isInvestEnabled ? handleShowModal : createOrderHandler
                    }
                    type="solid"
                    fullWidth
                    fontFamily="Montserrat"
                >
                    {width <= TAB_BREAKPOINT ? "Continue" : "Invest Now"}
                </Button>
            </Space>
            <PaymentScheduleBtn onClick={() => setShowPaymentTable(true)} />
        </DetailsCardOverride>
    );
};

export default DetailsSideBar;
