import React, { useEffect, useRef } from "react";
import { logoWhite } from "../assets";
import BulletList from "../components/bullet-list/BulletList";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import { H2, Span } from "../components/styles/Typography.styles";
import VideoPlayerBox from "./VideoPlayerBox";
import VideoPlayerHeader from "./VideoPlayerHeader";

const SrotPlatFormView = ({ id, visibilityHandler }) => {
    const srotPlatformRef = useRef();
    useEffect(() => {
        let target = srotPlatformRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [srotPlatformRef, visibilityHandler]);

    return (
        <div ref={srotPlatformRef} id={id} data-title="SROT_OVERVIEW">
            <Space margin={["5% 0 10%  0"]} mobilemargin={["0"]}>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Space mobilemargin={"0 0 1.5rem 0"}>
                        <Flex.Item
                            width="49%"
                            tabletWidth="100%"
                            mobileWidth="100%"
                        >
                            <Space
                                margin={["0 0px 3% 0px"]}
                                mobilemargin={["10% 0px"]}
                            >
                                <H2
                                    color="grey"
                                    textTransform="capitalize"
                                    // weight="bold"
                                    // size="xl"
                                >
                                    SROT Platform{" "}
                                    <Span color="lightGrey">Overview</Span>
                                </H2>
                            </Space>
                            <BulletList title="Calculate your carbon footprint" />
                            <BulletList title="Offset your carbon footprint" />
                            <BulletList title="Invest into pre-leased revenue generating solar asset" />
                            <BulletList title="Low risk & Asset-backed investment" />
                            <BulletList title="Earn up to 20% IRR returns as rental yield from the solar asset while we maintain your solar plant" />
                        </Flex.Item>
                    </Space>
                    <Flex.Item
                        width="49%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <VideoPlayerBox
                            padding="0.54rem 1rem 1.5rem 1rem"
                            Header={() => (
                                <VideoPlayerHeader logo={logoWhite} />
                            )}
                        />
                    </Flex.Item>
                </Flex.Container>
            </Space>
        </div>
    );
};

export default SrotPlatFormView;
