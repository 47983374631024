import squareLines from "../assets/images/squareLines.png";
import avatarMan from "./icons/avatarMan.png";
import avatarWoman from "./icons/avatarWoman.png";
import carIcon from "./icons/car _1.svg";
import circularRupee from "./icons/circularRupee.png";
import cutleryIcon from "./icons/cutlery _1.svg";
import docs from "./icons/docs.png";
import file from "./icons/file.png";
import flash from "./icons/flash.svg";
import flashIcon from "./icons/flash_1.svg";
import handPhone from "./icons/handPhoneIcon.svg";
import headPhone from "./icons/headPhoneIcon.svg";
import lock from "./icons/lockIcon.svg";
import moneyIcon from "./icons/moneyIcon.png";
import pdfDownload from "./icons/pdf-download.svg";
import semicolonFill from "./icons/semicolonFill.svg";
import semicolonRounded from "./icons/semicolonRounded.svg";
import semicolonStroke from "./icons/semicolon_stroke.svg";
import sheets from "./icons/sheets.png";
import solarIcon from "./icons/solarIcon.png";
import stampIcon from "./icons/stamp.png";
import moon from "./images/404_moon.png";
import mountain from "./images/404_mountain.png";
import industry from "./images/Industry&internaltrade.png";
import logo from "./images/Srot-New-Logo.png";
import logoWhite from "./images/Srot-White-New-Logo.png";
import advantageBg from "./images/advantage_bg.svg";
import bank_incomplete from "./images/bank_incomplete.png";
import desktopBgHero from "./images/bg.svg";
import bgBubble from "./images/bubble.png";
import cardTop from "./images/card-top-01.svg";
import { default as city, default as cityBig } from "./images/city-01.png";
import {
    default as cityMobile,
    default as citySmall,
} from "./images/city-mobile-01-alt.png";
import coinImg from "./images/coinImg.png";
import completeKyc from "./images/completKyc.png";
import electricity from "./images/electricity_hill.png";
import firstSlide from "./images/first_slide-01.png";
import greenSlate from "./images/green-slate.jpg";
import coinImgMobile from "./images/handJar-mobile.png";
import heartbeat from "./images/heartbeat.png";
import howItWorks from "./images/home_how_it_works.png";
import homePhone from "./images/home_phone.png";
import investOpportunities from "./images/investOpportunities.png";
import kyc_done from "./images/kyc_done.png";
import kyc_rejected from "./images/kyc_rejected.png";
import kyc_underprocess from "./images/kyc_underprocess.png";
import loadFunds from "./images/loadFunds.png";
import mobileBgHero from "./images/mobileBgHero.svg";
import msmeWhite from "./images/msme-white.png";
import greetings from "./images/offset_greetings.svg";
import offsetModal from "./images/offset_modal_bg.jpg";
import portfolio from "./images/portfolio.png";
import risingGraph from "./images/risingGraph.png";
import secondSlide from "./images/secondSlide.png";
import bgStars from "./images/stars_background.png";
import startUpIndia from "./images/startupindia-white.png";
import thirdSlide from "./images/third_slide-01.png";
import virtualAdvantage from "./images/virtualAdvantage.svg";
import virtualSolar from "./images/virtual_solar.png";
import walletBg from "./images/walletBg.png";
import walletMobileBg from "./images/walletMobileBg.png";
import webinar from "./images/webinar.png";
export const IMAGE =
    "https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
export const IMAGES = [
    {
        id: 1,
        path: IMAGE,
        purpose: "DISPLAY",
    },
];
/* @DESC::  Images  */
export {
    advantageBg,
    bank_incomplete,
    bgBubble,
    bgStars,
    cardTop,
    city,
    cityBig,
    cityMobile,
    citySmall,
    coinImg,
    coinImgMobile,
    completeKyc,
    desktopBgHero,
    electricity,
    firstSlide,
    flash,
    greenSlate,
    greetings,
    handPhone,
    headPhone,
    heartbeat,
    homePhone,
    howItWorks,
    industry,
    investOpportunities,
    kyc_done,
    kyc_rejected,
    kyc_underprocess,
    loadFunds,
    lock,
    logo,
    logoWhite,
    mobileBgHero,
    moon,
    mountain,
    msmeWhite,
    offsetModal,
    portfolio,
    risingGraph,
    secondSlide,
    solarIcon,
    squareLines,
    startUpIndia,
    thirdSlide,
    virtualAdvantage,
    virtualSolar,
    walletBg,
    walletMobileBg,
    webinar,
};
/* @DESC::  Icons  */
export {
    avatarMan,
    avatarWoman,
    carIcon,
    circularRupee,
    cutleryIcon,
    docs,
    file,
    flashIcon,
    moneyIcon,
    pdfDownload,
    semicolonFill,
    semicolonRounded,
    semicolonStroke,
    sheets,
    stampIcon,
};
