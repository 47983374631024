import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import { ErrorText } from "../components/input/Input";
import Space from "../components/space/Space";
import { H2, P, Span } from "../components/styles/Typography.styles";
import { convertInteger, twoDecimalLocal } from "../helpers/helpers";
import theme from "../theme";
import BarChart from "./BarChart";

const InvestmentWrapper = styled(Flex.Container)`
    border: 1px solid ${(props) => props.theme.color.lightGrey};
    border-radius: 3px;
    margin-bottom: 5%;
`;
const Box = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.theme.color[props.color]};
    margin-right: 10px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 15px;
        height: 15px;
    }
`;
const InputWrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    font-family: "Karla", sans-serif;
    border: none;
    font-size: ${(props) => props.theme.fontSize.default.s};
    width: 100%;

    input {
        outline: none;
        padding: 8px 20px 8px 20px;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid
            ${(props) =>
                props.isError
                    ? props.theme.color.error
                    : props.theme.color.lightGrey};
        text-align: right;
        font-size: ${(props) => props.theme.fontSize.default.s};
        width: 100%;
    }
    .money {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 30px;
    }
`;

const ButtonStyled = styled(Button)`
    width: 100%;
    margin-top: 5%;
    text-transform: uppercase;
    padding: 0.5rem;
`;
const Result = styled.div`
    transition: all ease-in-out 0.2s;
`;
const expectedPercentage = [
    11, 11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13, 13.5,
];

const VisualizeEarning = ({ id, visibilityHandler }) => {
    const visualizeEarningRef = useRef();
    useEffect(() => {
        let target = visualizeEarningRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [visualizeEarningRef, visibilityHandler]);

    const initial = 10000;
    const [investment, setInvestment] = useState(initial.toLocaleString());
    const [period] = useState(5);
    const [assetPercentage, setAssetPercentage] = useState(0);

    const [sliderValue, setSliderValue] = useState(
        12
        /* @TODO => Change slider values @habib610 Thu June 27,2024 */
    );
    const [rangeError, setRangeError] = useState(null);

    const [prevInvestment, setPrevInvestment] = useState(initial);
    const [interest, setInterest] = useState(
        prevInvestment * (sliderValue / 100) * period
    );
    const [total, setTotal] = useState(prevInvestment + interest);
    const [finalAsset, setFinalAsset] = useState(
        prevInvestment * assetPercentage
    );

    const handleInvestment = (e) => {
        const commaSeparated = convertInteger(e.target.value);
        if (commaSeparated < 10000 || commaSeparated > 1000000) {
            setPrevInvestment(prevInvestment);
        } else {
            setPrevInvestment(commaSeparated);
        }
        setInvestment(commaSeparated.toLocaleString());
    };

    useEffect(() => {
        const getRange = convertInteger(investment);
        let newInterest = prevInvestment * (sliderValue / 100) * period;
        setInterest(newInterest);
        setTotal(prevInvestment + newInterest);
        setFinalAsset(prevInvestment * assetPercentage);

        if (getRange < 10000) {
            setRangeError("Minimum investment amount is  10K");
        } else if (getRange > 1000000) {
            setRangeError("Maximum investment amount is  10L");
        } else {
            setRangeError(null);
        }
    }, [
        prevInvestment,
        investment,
        sliderValue,
        period,
        interest,
        assetPercentage,
    ]);

    const handleBlur = () => {
        const investAmount = convertInteger(investment);
        if (investAmount < 10000) {
            setInvestment((10000).toLocaleString());
            setPrevInvestment(10000);
        } else if (investAmount > 1000000) {
            setInvestment((1000000).toLocaleString());
            setPrevInvestment(1000000);
        }
    };

    const monthlyPayouts = (total - finalAsset) / (period * 12);
    const totalPayouts = monthlyPayouts * period * 12 + finalAsset;
    return (
        <div id={id} ref={visualizeEarningRef} data-title="RETURNS CALCULATOR">
            <Space
                padding={["10% 0px 25px 0px"]}
                mobilepadding={["60px 0px 40px"]}
            >
                <H2 color="grey">
                    Visualize Your Earning{" "}
                    <Span color="lightGrey">Use Below Calculator</Span>
                </H2>
            </Space>

            <InvestmentWrapper justifyContent="space-between">
                <Flex.Item width="48%" tabletWidth="100%" mobileWidth="100%">
                    <Space padding={["4%"]}>
                        <div>
                            <Flex.Container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Flex.Item
                                    width="100%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    <Space margin={["0px 0px 20px"]}>
                                        <P weight="b" align="center">
                                            Investment
                                        </P>
                                    </Space>
                                </Flex.Item>
                                <Flex.Item
                                    width="50%"
                                    tabletWidth="40%"
                                    mobileWidth="40%"
                                >
                                    <Space margin={["0px 0px 1% 0px"]}>
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            Enter the Investment Amount
                                        </P>
                                    </Space>
                                </Flex.Item>
                                <Flex.Item
                                    width="40%"
                                    tabletWidth="55%"
                                    mobileWidth="55%"
                                >
                                    <InputWrapper isError={rangeError}>
                                        <input
                                            onChange={handleInvestment}
                                            onBlur={handleBlur}
                                            value={investment}
                                            name="investment"
                                            autoComplete="off"
                                            style={{
                                                borderColor: theme.color.green,
                                            }}
                                        />
                                        <div className="money">&#8377;</div>
                                    </InputWrapper>
                                </Flex.Item>
                            </Flex.Container>
                            {rangeError && (
                                <ErrorText
                                    align="right"
                                    color="error"
                                    size="xs"
                                >
                                    {rangeError}
                                </ErrorText>
                            )}
                            <Space margin={["25px 0px"]}>
                                <Flex.Container
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Flex.Item
                                        width="50%"
                                        tabletWidth="40%"
                                        mobileWidth="40%"
                                    >
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            Investment Time Period
                                        </P>
                                    </Flex.Item>
                                    <Flex.Item
                                        width="40%"
                                        tabletWidth="55%"
                                        mobileWidth="55%"
                                    >
                                        <Flex.Container
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <InputWrapper>
                                                <input
                                                    value={"5 Years"}
                                                    disabled
                                                />
                                            </InputWrapper>
                                        </Flex.Container>
                                    </Flex.Item>
                                </Flex.Container>
                            </Space>

                            <Flex.Container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Flex.Item
                                    width="50%"
                                    tabletWidth="40%"
                                    mobileWidth="40%"
                                >
                                    <P weight="b" xxs="s" st="s" lg="s">
                                        Investment Returns IRR
                                    </P>
                                </Flex.Item>
                                <Flex.Item
                                    width="40%"
                                    tabletWidth="55%"
                                    mobileWidth="55%"
                                >
                                    <Flex.Container
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <InputWrapper>
                                            <input value={`20.88 %`} disabled />
                                        </InputWrapper>
                                    </Flex.Container>
                                </Flex.Item>
                            </Flex.Container>
                        </div>
                    </Space>
                </Flex.Item>
                <Flex.Item width="48%" tabletWidth="100%" mobileWidth="100%">
                    <Space padding={["4%"]}>
                        <div>
                            <Space margin={["0px 0px 20px"]}>
                                <P
                                    weight="b"
                                    xxs="s"
                                    st="s"
                                    lg="s"
                                    align="center"
                                >
                                    Investment Return
                                </P>
                            </Space>
                            <Result>
                                <Space margin={["32px 0px"]}>
                                    <Flex.Container justifyContent="space-between">
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            Monthly Payouts
                                        </P>
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            &#8377;{" "}
                                            {Math.floor(
                                                monthlyPayouts
                                            ).toLocaleString()}
                                        </P>
                                    </Flex.Container>
                                </Space>

                                <Flex.Container justifyContent="space-between">
                                    <P weight="b" xxs="s" st="s" lg="s">
                                        Total Payouts
                                    </P>
                                    <P weight="b" xxs="s" st="s" lg="s">
                                        &#8377;{" "}
                                        {Number(totalPayouts).toLocaleString()}
                                    </P>
                                </Flex.Container>

                                <Space margin={["32px 0px"]}>
                                    <Flex.Container justifyContent="space-between">
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            Solar Assets Owned
                                        </P>
                                        <P weight="b" xxs="s" st="s" lg="s">
                                            {twoDecimalLocal(
                                                Number(
                                                    convertInteger(investment) /
                                                        55000
                                                )
                                            )}{" "}
                                            KWp
                                        </P>
                                    </Flex.Container>
                                </Space>
                            </Result>
                        </div>
                    </Space>
                </Flex.Item>
            </InvestmentWrapper>

            <Flex.Container justifyContent="space-between" alignItems="center">
                <Flex.Item width="100%" tabletWidth="100%" mobileWidth="100%">
                    <BarChart
                        rate={sliderValue}
                        period={period}
                        investment={prevInvestment}
                        finalAsset={finalAsset}
                        monthlyPayouts={monthlyPayouts}
                    />
                </Flex.Item>
                <Flex.Item width="35%" tabletWidth="100%" mobileWidth="100%">
                    <Space
                        tabletmargin={["1% 0 0"]}
                        mobilemargin={["2% 0px 0px"]}
                    >
                        <Flex.Container justifyContent="center">
                            <Flex.Container
                                style={{ width: "100%" }}
                                justifyContent="space-between"
                            >
                                <Space
                                    margin={["0 0 20px 0"]}
                                    tabletmargin={[" 0"]}
                                    mobilemargin={["0"]}
                                >
                                    <Flex.Item
                                        width="100%"
                                        // tabletWidth="100%"
                                        // mobileWidth="100%"
                                    >
                                        <BarChartLegend
                                            color="primary"
                                            title="Rental Income "
                                        />
                                    </Flex.Item>
                                </Space>
                            </Flex.Container>
                        </Flex.Container>
                    </Space>
                </Flex.Item>
            </Flex.Container>
        </div>
    );
};

export default VisualizeEarning;

const MobileContainer = styled(Flex.Container)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        align-items: flex-start;
    }
`;
const BarChartLegend = ({ color, title }) => {
    return (
        <Space margin={["0% 0px"]}>
            <MobileContainer alignItems="center">
                <Box color={color} />
                <P xxs="s" st="s" lg="s" color="lightGrey">
                    {title}
                </P>
            </MobileContainer>
        </Space>
    );
};
