import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Space from "../../components/space/Space";
import { HideTablet, ShowTablet } from "../../components/styles/HideSection";
import Text from "../../components/text/Text";
import { faqs } from "../../constants/faqData";
import { FAQ } from "../../faq/FAQSections";

import { docs, file, pdfDownload, sheets } from "../../assets";
import { FullCarousel } from "../../components/carousel/Carousel";
import { DetailsCardOverride } from "./DetailsSidebar";
import ProjectAbout from "./ProjectAbout";

const ProjectDetailsCard = (props) => {
    const [activeTab, setActiveTab] = useState(1);
    const { details, onDocumentClick, tagItem } = props.data;
    let activeContent = null;
    if (activeTab === 1) {
        activeContent = <ProjectAbout {...details} tagItem={tagItem || []} />;
    }
    if (activeTab === 2) {
        activeContent = (
            <Space margin={["1rem 0 0"]}>
                <div>
                    {faqs.slice(1, 2).map((item) => (
                        <FAQ item={item} key={item.title} />
                    ))}
                </div>
            </Space>
        );
    }
    if (activeTab === 3) {
        activeContent = <ProjectDocuments onClick={onDocumentClick} />;
    }
    const handleTabButton = (index) => {
        setActiveTab(index);
        let element = document.getElementById("projectDetailsCard");
        if (element) {
            element.scrollIntoView(true, {
                behavior: "smooth",
                block: "top",
            });
        }
    };
    return (
        <Space tabletmargin={["0 0 3rem"]} mobilemargin={["0 0 2rem"]}>
            <Flex.Item width="57%" tabletWidth="100%" mobileWidth="100%">
                <DetailsCardOverride>
                    <Space margin={["0 0 1rem"]}>
                        <ShowTablet>
                            <TabButtons
                                onClick={handleTabButton}
                                activeTab={activeTab}
                            />
                        </ShowTablet>
                    </Space>

                    <FullCarousel
                        showIndicators={false}
                        showArrows={true}
                        autoPlay
                        interval={3000}
                    >
                        {details.images.map((item, index) => (
                            <div key={index} className="cardImg">
                                <img
                                    src={item.path}
                                    alt="project_details_image"
                                />
                            </div>
                        ))}
                    </FullCarousel>

                    {activeContent}
                    <HideTablet>
                        <TabButtons
                            onClick={handleTabButton}
                            activeTab={activeTab}
                        />
                    </HideTablet>
                </DetailsCardOverride>
            </Flex.Item>
        </Space>
    );
};

export default ProjectDetailsCard;

const TabButton = styled(Button)`
    background-color: ${(props) =>
        props.isActive ? props.theme.color.primary : props.theme.color.grey350};
    color: ${(props) =>
        props.isActive ? props.theme.color.white : props.theme.color.black800};
    border: none;
    border-bottom: 4px solid
        ${(props) =>
            props.isActive
                ? props.theme.color.cyan
                : props.theme.color.grey350};
    :hover,
    :active,
    :focus {
        box-shadow: none;
    }
    @media (max-width: 390px) {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        min-width: 50px;
    }
    @media (max-width: 300px) {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        min-width: 40px;
    }
    @media (max-width: 280px) {
        margin-bottom: 0.5rem;
    }
`;

const TabButtons = ({ activeTab, onClick }) => {
    return (
        <Flex.Container justifyContent="space-between" alignItems="center">
            <TabButton
                type="solid"
                isActive={activeTab === 1}
                onClick={() => onClick(1)}
            >
                About Project
            </TabButton>
            <TabButton
                type="solid"
                color="black"
                isActive={activeTab === 2}
                onClick={() => onClick(2)}
            >
                FAQ
            </TabButton>
            {/* <TabButton
                type="solid"
                color="black"
                isActive={activeTab === 3}
                onClick={() => onClick(3)}
            >
                Documents
            </TabButton> */}
        </Flex.Container>
    );
};

const DocumentsWrapper = styled.div`
    background: ${(props) => props.theme.color.grey200};
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: 300px;
    border-radius: 0.8rem;
    padding: 1rem;
    margin-bottom: 1rem;
    .name {
        word-wrap: break-word;
    }
    .iconWrap {
        width: 80px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        :hover {
            cursor: pointer;
        }
        :hover .name {
            cursor: pointer;
            color: ${(props) => props.theme.color.primary};
        }
    }
`;

let documents = [
    {
        id: 6,
        path: "some_path/leasing-agreements.pdf",
        purpose: "PDF",
    },
    {
        id: 8,
        path: "something/signed-agreements.doc",
        purpose: "OTHER",
    },
    {
        id: 9,
        path: "something/excel-agreements.xlxs",
        purpose: "EXCEL",
    },
    {
        id: 9,
        path: "something/someDoc_file.xlxs",
        purpose: "DOC",
    },
];

const doctype = {
    PDF: "PDF",
    EXCEL: "EXCEL",
    DOC: "DOC",
};

const getPurposeAndName = (docItem) => {
    let image;
    let name;

    switch (docItem.purpose) {
        case doctype.DOC:
            image = docs;
            break;
        case doctype.PDF:
            image = pdfDownload;
            break;

        case doctype.EXCEL:
            image = sheets;
            break;

        default:
            image = file;
            break;
    }
    let pathName = `${docItem.path}`;
    let lastIndexOfSlash = pathName.lastIndexOf("/");
    if (lastIndexOfSlash) {
        name = pathName.slice(lastIndexOfSlash + 1).split(".")[0];
    } else {
        name = name = pathName.slice(pathName.length - 25).split(".")[0];
    }
    if (name.length > 18) {
        name = `${name.slice(0, 16)}...`;
    }
    return { name: name.replace(/-|_/g, " ").trim(), image };
};
const ProjectDocuments = ({ onClick }) => {
    return (
        <DocumentsWrapper>
            {documents.map((item, index) => {
                const { name, image } = getPurposeAndName(item);
                return (
                    <div
                        key={index}
                        role="button"
                        onClick={() => onClick(item.path)}
                        className="iconWrap"
                    >
                        <div className="icon">
                            <Image src={image} alt={name} />
                        </div>
                        <Text
                            className="name"
                            lineHeight="1.2em"
                            align="center"
                            size="xs"
                            textTransform="capitalize"
                        >
                            {name}
                        </Text>
                    </div>
                );
            })}
        </DocumentsWrapper>
    );
};
