import React from "react";
import { FaMapMarkerAlt, FaStar } from "react-icons/fa";
import styled from "styled-components";
import Icon from "../components/Icon/Icon";
import ButtonMain from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Space from "../components/space/Space";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import useAuthHistory from "../hooks/useAuthHistory";
import CardComingSoon from "./CardComingSoon";
import ProjectProgress, { ProjectInvestProgress } from "./ProjectProgress";
import { TagItem, TagsContainer } from "./Tag";

export const Button = styled.button`
    background-color: ${(props) => props.theme.color.cyan};
    outline: none;
    border: none;
    border-radius: 3px;
    width: 100%;
    margin-top: 1rem;
    padding: 0.3rem;
    :hover {
        cursor: pointer;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
`;

export const CardContainer = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "center"};
    flex-wrap: wrap;
    position: relative;
    .card {
        max-width: 350px;
        width: 100%;
        margin: 30px 30px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
        position: relative;
        transition-duration: 0.32s;
        :hover {
            cursor: pointer;
            transform: scale(1.041);
        }
    }

    @media (max-width: 883px) {
        justify-content: center;
        .card {
            width: 300px;
            margin: 30px 20px;
        }
    }
    @media (max-width: 750px) {
        justify-content: center;
        .card {
            width: 450px;
            margin: 30px 30px;
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        justify-content: center;
        .card {
            width: 100%;
            max-width: 300px;
            margin: 30px 0px;
        }
    }
`;
const CardInnerWrap = styled.div`
    position: relative;

    .overly {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all linear 0.4s;
        }
        .projectStatus {
            position: absolute;
            right: 10px;
            bottom: 5px;
        }
        .idStatus {
            position: absolute;
            left: 10px;
            bottom: 5px;
            z-index: 6;
        }

        ::before {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            right: 0;
            opacity: 0.5;
            width: 0;
            height: 0;
            border-left: 45px solid transparent;
            border-bottom: 41px solid black;
            border-right: 120px solid black;
        }
        ::after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            opacity: 0.3;
            width: 0;
            height: 0;
            border-left: 60px solid black;
            border-bottom: 41px solid black;
            border-right: 45px solid transparent;
        }
    }
`;

const ProjectCard = (props) => {
    const { setPath } = useAuthHistory();
    const {
        name,
        status,
        city,
        isOpen,
        progress,
        tagItem,
        details,
        invested,
        thumbnail,
    } = props?.data || {};

    const handleSetPath = () => {
        setPath(props.link);
    };
    if (isOpen) {
        return (
            <CardInnerWrap className="card">
                <div className="overly">
                    <img src={thumbnail} alt={name} />
                    {props.keyValue ? (
                        <Flex.Container align="center" justify="space-between">
                            <Text
                                color="cyan"
                                fontFamily="Montserrat"
                                className="idStatus"
                                size="s"
                                weight="700"
                                transform="capitalize"
                            >
                                # {props.keyValue}
                            </Text>
                            <Text
                                className="projectStatus"
                                color="cyan"
                                fontFamily="Montserrat"
                                size="s"
                                weight="700"
                                transform="capitalize"
                            >
                                <Icon component={FaStar} size="xs" /> {status}
                            </Text>
                        </Flex.Container>
                    ) : (
                        <Text
                            className="projectStatus"
                            color="cyan"
                            fontFamily="Montserrat"
                            size="s"
                            weight="700"
                            transform="capitalize"
                        >
                            <Icon component={FaStar} size="xs" /> {status}
                        </Text>
                    )}
                </div>
                <Space padding={[".8rem"]}>
                    <div>
                        <Flex.Container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text fontFamily="Montserrat" size="m" weight="600">
                                {name}
                            </Text>
                            <Flex.Container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Icon component={FaMapMarkerAlt} size="xs" />
                                <InlineText
                                    style={{ marginLeft: "2px" }}
                                    size="xs"
                                    weight="600"
                                >
                                    {city}
                                </InlineText>
                            </Flex.Container>
                        </Flex.Container>
                        <Space margin={["1rem 0"]}>
                            <TagsContainer borderRadius=".5rem" bg="grey300">
                                {tagItem.map((item) => (
                                    <TagItem
                                        key={item.id}
                                        name={item.name}
                                        value={item.value}
                                        unit={item.unit}
                                        size="s"
                                        sizeS="xs"
                                        margin=".5rem 0"
                                    />
                                ))}
                            </TagsContainer>
                        </Space>
                        {details.map((item) => (
                            <ListingItem
                                key={item.name}
                                name={item.name}
                                value={item.value}
                            />
                        ))}
                        {/* <ListingItem name={"id"} value={id} /> */}
                        {invested ? (
                            <ProjectInvestProgress
                                name={invested.name}
                                value={invested.owned}
                            />
                        ) : (
                            <ProjectProgress value={progress} />
                        )}

                        <Space margin={["1rem 0 0"]}>
                            <ButtonMain
                                onClick={handleSetPath}
                                type="solid"
                                fullWidth
                                fontFamily="Montserrat"
                            >
                                View Details
                            </ButtonMain>
                        </Space>
                    </div>
                </Space>
            </CardInnerWrap>
        );
    } else {
        return <CardComingSoon data={props?.data} />;
    }
};

export default ProjectCard;

const ListingItem = ({ name, value }) => {
    return (
        <Flex.Container
            style={{ marginBottom: ".5rem" }}
            justifyContent="space-between"
            alignItems="center"
        >
            <Flex.Item flex="1">
                <Text
                    align="left"
                    weight="500"
                    fontFamily="Montserrat"
                    size="s"
                >
                    {name}
                </Text>
            </Flex.Item>
            <Flex.Item>
                <Text
                    align="right"
                    weight="500"
                    fontFamily="Montserrat"
                    size="s"
                >
                    {value}
                </Text>
            </Flex.Item>
        </Flex.Container>
    );
};
