import React from "react";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import Flex from "../flex/Flex";
import Image from "../image/Image";
import Space from "../space/Space";

export const CarouselContainer = styled.div`
    .carousel .slide {
        background: transparent;
    }

    .carousel .slide .legend {
        background: transparent;
    }

    .carousel .control-dots .dot {
        background: ${(props) => props.theme.color.secondary};
        opacity: 0.5;
    }

    .carousel .control-dots .dot.selected,
    .carousel .control-dots .dot:hover {
        opacity: 1;
    }

    .carousel .control-dots {
        margin: 0 0 5px;
    }
`;

const FlexContainer = styled(Flex.Container)`
    height: 100%;
`;

const Carousel = ({ data, padding = "0", ...rest }) => (
    <CarouselContainer>
        <CarouselComponent
            interval={5000}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={100}
            autoPlay
            showThumbs={false}
            infiniteLoop={true}
            stopOnHover={false}
            showStatus={false}
            {...rest}
        >
            {data.map((slide, index) => (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                >
                    <Space padding={["0 0 20px"]}>
                        <Flex.Item
                            width={slide.width[0]}
                            tabletWidth={slide.width[1]}
                            mobileWidth={slide.width[2]}
                            style={{ padding: padding }}
                        >
                            <Image src={slide.background.link} />
                        </Flex.Item>
                    </Space>
                </FlexContainer>
            ))}
        </CarouselComponent>
    </CarouselContainer>
);

export default Carousel;

export const FullCarousel = ({ data, interval = 10000, children, ...rest }) => {
    return (
        <CarouselContainer>
            <CarouselComponent
                interval={interval}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={100}
                // autoPlay
                showThumbs={false}
                infiniteLoop={true}
                stopOnHover={false}
                showStatus={false}
                {...rest}
            >
                {children}
            </CarouselComponent>
        </CarouselContainer>
    );
};
