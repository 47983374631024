import styled from "styled-components";
import Icon from "../components/Icon/Icon";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";

export const TagsContainer = styled.div`
    background-color: ${(props) => props.theme.color[props.bg]};
    flex-wrap: wrap;
    border-radius: ${(props) => props.borderRadius || "1rem"};
    display: flex;
    justify-content: center;
    div {
        :last-child {
            border-right: none;
        }
    }
`;

export const TagItem = ({
    name,
    unit,
    value,
    color = "black800",
    size = "m",
    sizeS = "xs",
    margin = "1rem 0",
    unitSize = "s",
    icon,
}) => {
    return (
        <Tag margin={margin}>
            <div>
                <Text
                    size={size}
                    align="center"
                    color={color}
                    fontFamily="Montserrat"
                    weight="600"
                >
                    {value}
                    {unit && (
                        <InlineText
                            size={unitSize}
                            style={{ marginLeft: "3px" }}
                        >
                            {unit}
                        </InlineText>
                    )}
                </Text>
                <Text
                    size={sizeS}
                    align="center"
                    color="black800"
                    fontFamily="Montserrat"
                >
                    {icon && <Icon component={icon} />} {name}
                </Text>
            </div>
        </Tag>
    );
};

const Tag = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    border-right: 2px solid ${(props) => props.theme.color.grey400};
    margin: ${(props) => props.margin};
`;
