import { useCallback, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Icon from "../components/Icon/Icon";
import AlertNew from "../components/alert/AlertNew";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";

import Card from "../components/card/Card";
import Line from "../components/line/Line";
import LoaderBox from "../components/loading/LoaderBox";
import ModalAlt from "../components/modal/ModalAlt";
import Container from "../components/styles/Container";
import { HideTablet, ShowTablet } from "../components/styles/HideSection";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import {
    ERROR_MSG,
    MINIMUM_INVESTMENT_AMOUNT,
    OPPORTUNITY_DETAILS_SUCCESS,
} from "../constants/common";
import {
    CHECK_WALLET_BALANCE_ENDPOINT,
    CREATE_ORDER_ENDPOINT,
    GENERATE_DOC_ENDPOINT,
    INVESTOR_CREATE_ORDER_WALLET_ENDPOINT,
    INVESTOR_FUND_STATUS_ENDPOINT,
    MAKE_INVESTMENT_API_ENDPOINT,
    OPPORTUNITY_DETAILS_ENDPOINT,
    PORTFOLIO,
    PROJECTS,
    SIGN_INVESTMENT_ENDPOINT,
} from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import ListingSuccessModal from "../dashboard/ListingSuccessModal";
import { convertInteger, twoDecimalLocal } from "../helpers/helpers";
import { getProjectDetailsData } from "../helpers/projectHelper";
import useNetwork from "../hooks/useNetwork";
import { signDocument } from "../services/digio";
import { createRazorPayOrder, displayRazor } from "../services/payment";
import request from "../services/request";

import { IMAGE } from "../assets";
import Space from "../components/Spacing/Space";
import { P, Span } from "../components/styles/Typography.styles";
import DetailsSideBar from "./project-details/DetailsSidebar";
import PaymentTable from "./project-details/PaymentTable";
import ProjectDetailsCard from "./project-details/ProjectDetailsCard";
import ProjectDrawer from "./project-details/ProjectDrawer";
import QuickKYComplete from "./project-details/QuickKYComplete";
const BackButton = styled.button`
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    :hover {
        cursor: pointer;
    }
`;

export const ReturnButton = ({ onClick }) => {
    return (
        <BackButton onClick={onClick} color="black" type="solid">
            <Icon component={BsArrowLeft} size="m" />
            <InlineText weight="700" size="m" style={{ marginLeft: "5px" }}>
                Back
            </InlineText>
        </BackButton>
    );
};

let TDS_PERCENTAGE = 0;

const ProjectDetailsWrap = () => {
    const history = useHistory();
    const [paymentAmount, setPaymentAmount] = useState(50000);
    const [showPaymentTable, setShowPaymentTable] = useState(false);
    const [showPaymentSummary, setShowPaymentSummary] = useState(false);
    const [investSuccessMsg, setInvestSuccessMsg] = useState(null);
    const [openKycModal, setOpenKycModal] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { state } = useContextState();

    const handleShowModal = () => {
        setShowModal(!showModal);
    };
    const [paymentAmountError, setPaymentAmountError] = useState("");
    const [walletBalance, setWalletBalance] = useState({
        deposit: 0,
        bonus: 0,
    });

    const { id } = useParams();
    const [body] = useState({
        id,
    });

    const downloadHandler = useCallback(async (link) => {
        try {
            const element = document.createElement("a");
            element.href = link;
            element.download = link.split("/")[2];
            document.body.appendChild(element);
            element.click();
        } catch (err) {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        }
    }, []);

    const { error, data, loading } = useNetwork(
        OPPORTUNITY_DETAILS_ENDPOINT,
        "POST",
        body,
        OPPORTUNITY_DETAILS_SUCCESS
    );
    const onKycStatusClick = () => {
        setOpenKycModal((openKycModal) => !openKycModal);
    };

    // 6th call
    const signInvest = useCallback(
        (investmentId) => async () => {
            try {
                const response = await request.authPost({
                    endpoint: SIGN_INVESTMENT_ENDPOINT,
                    body: {
                        investmentId: investmentId,
                    },
                });

                if (response?.status === "SUCCESS") {
                    let term = response?.data?.investment_term
                        ? Number(response?.data?.investment_term) / 12
                        : 0;
                    let invested = response?.data?.investment_amount
                        ? Number(response?.data?.investment_amount)
                        : 0;
                    setShowPaymentSummary(false);
                    // setSelectedInterestId(null);
                    setPaymentAmount(null);
                    // fetchInvestments();
                    setInvestSuccessMsg({ term, invested });
                }
            } catch (err) {
                toast.error(ERROR_MSG);
                setInvestSuccessMsg(null);
            }
        },
        []
    );

    // 5ths call ===>>>>
    const singDocumentHandler = useCallback(async () => {
        if (paymentAmount) {
            try {
                const status = await request.authPost({
                    endpoint: INVESTOR_FUND_STATUS_ENDPOINT,
                    body: {
                        id: data?.data?.listing?.listingId,
                    },
                });
                if (
                    status?.status === "SUCCESS" &&
                    Number(status?.data?.fund_remaining) < paymentAmount
                ) {
                    toast.error("Sorry! Oppurtunity is no longer available.");
                    return;
                } else if (
                    status?.status === "SUCCESS" &&
                    Number(status?.data?.fund_remaining) > paymentAmount
                ) {
                    const response = await request.authPost({
                        endpoint: GENERATE_DOC_ENDPOINT,
                        body: {
                            listingId: data?.data?.listing?.listingId,
                            term: data?.data?.listing?.investmentTerms[0]
                                .installments,
                            amount: paymentAmount,
                            signType: "electronic",
                            // interestId: selectedInterestId,
                        },
                        errorData: true,
                    });
                    const identifier = response?.data?.investor_identifier;

                    await signDocument(
                        response?.data?.document_id,
                        identifier,
                        signInvest(response?.data?.investment_id)
                    );
                }
                return;
            } catch (error) {
                const maxLimit =
                    data?.data?.listing?.listingMaxLimit === undefined ||
                    isNaN(data?.data?.listing?.listingMaxLimit)
                        ? 0
                        : Number(data?.data?.listing?.listingMaxLimit);

                if (error?.data?.code === "EXCEEDED") {
                    toast.error(
                        `Exceeded MAX Investment Limit, you can max invest ₹${(
                            maxLimit - Number(error?.data?.amountInvested)
                        ).toLocaleString()} only`
                    );

                    toast.info(
                        `Want to invest above ${maxLimit}, drop a mail to founders@srot.io`
                    );
                    return;
                }
                toast.error(
                    error?.message && typeof error?.message === "string"
                        ? error?.message
                        : ERROR_MSG
                );
                return;
            }
        }
        setPaymentAmountError(
            `Minimum investment amount is ${
                data?.data?.listing?.listingMaxLimit ||
                MINIMUM_INVESTMENT_AMOUNT
            }.`
        );
    }, [
        data?.data?.listing?.investmentTerms,
        data?.data?.listing?.listingId,
        data?.data?.listing?.listingMaxLimit,
        paymentAmount,
        signInvest,
    ]);

    // 0th / 4th optional call
    const getWalletData = useCallback(
        (orderPaymentAmount) => () => {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };

            request
                .authGet({
                    endpoint: CHECK_WALLET_BALANCE_ENDPOINT,
                    headers,
                })
                .then((res) => {
                    let deposit = res?.data?.wallet?.withdrawableBalance
                        ? Number(res.data.wallet.withdrawableBalance)
                        : 0;
                    let bonus = res?.data?.wallet?.nonWithdrawableBalance
                        ? Number(res.data.wallet.nonWithdrawableBalance)
                        : 0;
                    let newBalance = { deposit, bonus };
                    setWalletBalance(newBalance);
                    if (
                        orderPaymentAmount &&
                        convertInteger(orderPaymentAmount) <= deposit + bonus
                    ) {
                        setShowPaymentSummary(true);
                        setPaymentAmount(orderPaymentAmount);
                    }
                })
                .catch((err) => {
                    toast.error(ERROR_MSG);
                    let newBalance = { deposit: 0, bonus: 0 };
                    setWalletBalance(newBalance);
                });
        },
        [setShowPaymentSummary]
    );
    // 3rd optional call
    const handleLoadMoney = useCallback(
        async (amount, orderPaymentAmount) => {
            let data = {
                investorId: state?.user?.investorId,
                amount: amount,
            };
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
            };
            const endpoint = CREATE_ORDER_ENDPOINT;

            try {
                const res = await request.authPost({
                    endpoint,
                    body: data,
                    headers,
                });
                await displayRazor(
                    res.data.order_display.razorpayOrderId,
                    state.user,
                    getWalletData(orderPaymentAmount)
                );
            } catch (error) {}
        },
        [state?.user, getWalletData]
    );
    // 2nd call
    const orderPlacementHandler = useCallback(
        (amount) => {
            if (
                convertInteger(amount) >
                walletBalance.deposit + walletBalance.bonus
            ) {
                handleLoadMoney(
                    convertInteger(amount) -
                        Math.floor(walletBalance.deposit + walletBalance.bonus),
                    amount
                );
            } else {
                setShowPaymentSummary(true);
                setPaymentAmount(amount);
            }
        },
        [handleLoadMoney, walletBalance]
    );

    // 1st call ==>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const buyNowActionHandler = useCallback(() => {
        // @TODO ==>   Verification of input amount Thu May  18
        // verify Listing minimum limit
        if (
            !paymentAmount ||
            convertInteger(paymentAmount) < Number(MINIMUM_INVESTMENT_AMOUNT)
        ) {
            setPaymentAmountError(
                `Minimum investment amount is ${MINIMUM_INVESTMENT_AMOUNT}.`
            );
            return;
        }
        // setSavingAmountError("");
        orderPlacementHandler(paymentAmount);
    }, [orderPlacementHandler, paymentAmount]);

    // useEffect(() => {
    //     getWalletData()();
    // }, [getWalletData]);

    /* @DESC:: New API Implementation Sat June 15,2024 */

    /* @DESC:: Migration 4th call Sat June 15,2024 */
    const makePaymentHandler = useCallback(
        async (paymentId, orderId, signature) => {
            try {
                const endpoint = MAKE_INVESTMENT_API_ENDPOINT;

                let term =
                    data?.data?.listing?.investmentTerms[0]?.installments;
                let res = await request.authPost({
                    endpoint,
                    body: {
                        paymentId: paymentId,
                        orderId: orderId,
                        signature: signature,
                        term,
                    },
                });
                setShowPaymentSummary(false);
                setInvestSuccessMsg({
                    term: res?.data?.investment_term / 12,
                    invested: res?.data?.investment_amount,
                });
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
            }
        },
        [data?.data?.listing?.investmentTerms]
    );

    /* @DESC:: Migration 3rd Sat June 15,2024 */
    const createOrderInvestorWallet = useCallback(async () => {
        if (paymentAmount) {
            try {
                setPaymentLoading(true);

                const res = await request.authPost({
                    endpoint: INVESTOR_CREATE_ORDER_WALLET_ENDPOINT,
                    body: {
                        listingId: data?.data?.listing?.listingId,
                        amount: paymentAmount,
                        term: data?.data?.listing?.investmentTerms[0]
                            .installments,
                    },
                });
                await createRazorPayOrder(
                    res?.data?.order_display?.razorpayOrderId,
                    state.user,
                    makePaymentHandler
                );

                setPaymentLoading(false);
                return;
            } catch (error) {
                /* @TODO => Handle Sat June 15,2024 */
                setPaymentLoading(false);
                // const maxLimit =
                //     data?.data?.listing?.listingMaxLimit === undefined ||
                //     isNaN(data?.data?.listing?.listingMaxLimit)
                //         ? 0
                //         : Number(data?.data?.listing?.listingMaxLimit);

                // if (error?.data?.code === "EXCEEDED") {
                //     toast.error(
                //         `Exceeded MAX Investment Limit, you can max invest ₹${(
                //             maxLimit - Number(error?.data?.amountInvested)
                //         ).toLocaleString()} only`
                //     );

                //     toast.info(
                //         `Want to invest above ${maxLimit}, drop a mail to founders@srot.io`
                //     );
                //     return;
                // }
                toast.error(
                    error?.message && typeof error?.message === "string"
                        ? error?.message
                        : ERROR_MSG
                );
                return;
            }
        }
    }, [
        data?.data?.listing?.listingId,
        data?.data?.order_display?.razorpayOrderId,
        makePaymentHandler,
        paymentAmount,
        state.user,
    ]);

    /* @DESC:: Migration 2nd Sat June 15,2024 */
    const handlePlaceOrderHandler = useCallback((amount) => {
        setShowPaymentSummary(true);
        setPaymentAmount(amount);
    }, []);

    /* @DESC:: Migration 1st call Sat June 15,2024 */
    const createOrderHandler = useCallback(() => {
        // check min amount from asset minimum amount

        let listing = data?.data?.listing;
        if (
            !paymentAmount ||
            Number(listing?.listingMinLimit) > Number(paymentAmount)
        ) {
            setPaymentAmountError(
                `Minimum investment amount is ${listing?.listingMinLimit}.`
            );
            return;
        } else if (Number(listing?.listingMaxLimit) < Number(paymentAmount)) {
            setPaymentAmountError(
                `Maximum investment amount is ${listing?.listingMaxLimit}.`
            );
            return;
        }

        handlePlaceOrderHandler(paymentAmount);
    }, [data?.data?.listing, handlePlaceOrderHandler, paymentAmount]);

    const closeSuccessModal = () => {
        setInvestSuccessMsg(null);
        history.push(PORTFOLIO);
    };

    let content = null;
    if (loading) {
        content = (
            <Flex.Container jusTifyContent="center">
                <LoaderBox />
            </Flex.Container>
        );
    }

    if (!loading && error) {
        content = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    {error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (!loading && !error && !data?.data?.listing?.listingId) {
        content = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    Projects Details Not Available
                </AlertNew>
            </Flex.Container>
        );
    }

    if (!loading && !error && data?.data?.listing?.listingId) {
        const listing = data?.data?.listing || {};

        let plants = listing?.plants || [];
        let investmentTerms = listing?.investmentTerms || [];
        let IRR = investmentTerms[0]?.targetedIRRPercentage || 0;
        let listingMaxLimit =
            listing.listingMaxLimit === undefined ||
            isNaN(listing?.listingMaxLimit)
                ? 0
                : Number(listing?.listingMaxLimit);
        let listingMinLimit =
            listing.listingMinLimit === undefined ||
            isNaN(listing?.listingMinLimit)
                ? 0
                : Number(listing?.listingMinLimit);
        let investmentTerm = investmentTerms[0]?.installments
            ? Number(investmentTerms[0]?.installments) / 12
            : 0;
        let isInvestmentAvailable = listing?.listingStatus === "FUNDING";

        let capacity = Number(plants[0]?.capacity) / 1000 || 0;

        let solarAsset =
            Number(paymentAmount) / (Number(listing?.fundLimit) / capacity);

        let grossPayoutMonthlyPerLakh =
            Number(investmentTerms[0]?.grossPayoutMonthlyPerLakh) || 0;

        let monthlyPayout =
            (grossPayoutMonthlyPerLakh * paymentAmount) / 100000;

        let installments = Number(investmentTerms[0]?.installments) || 0;
        let tds = monthlyPayout * TDS_PERCENTAGE;
        let netPayout = monthlyPayout;
        let grossReturns = monthlyPayout * installments;
        let investmentTermDetails =
            investmentTerms[0]?.investmentTermDetailsDisplays;

        let projectData = getProjectDetailsData(listing);
        projectData.img = IMAGE;
        projectData.onDocumentClick = downloadHandler;

        const closePaymentSummaryModal = () => {
            setShowPaymentSummary(false);
        };

        const sideBarData = {
            setPaymentAmount,
            paymentAmount,
            walletBalance,
            solarAsset,
            IRR,
            investmentTerm,
            monthlyPayout,
            grossReturns,
            tds,
            netPayout,
            isInvestmentAvailable,
            listingMaxLimit,
            listingMinLimit,
            createOrderHandler,
            setShowPaymentTable,
            setPaymentAmountError,
            handleShowModal,
            paymentAmountError,
            onKycStatusClick,
            investmentTermDetails,
        };
        content = (
            <>
                <Space xxsp={["8rem 0 4rem"]}>
                    <div id="projectDetailsCard">
                        <Container>
                            <ReturnButton
                                onClick={() => history.push(PROJECTS)}
                            />

                            <Flex.Container justifyContent="space-between">
                                <ProjectDetailsCard data={projectData} />

                                {/* @DESC::  SideBar   */}
                                <Flex.Item
                                    width="40%"
                                    tabletWidth="100%"
                                    mobileWidth="100%"
                                >
                                    <HideTablet>
                                        <DetailsSideBar data={sideBarData} />
                                    </HideTablet>
                                </Flex.Item>
                                {/* desc  */}
                                <ShowTablet>
                                    <ProjectDrawer>
                                        <DetailsSideBar data={sideBarData} />
                                    </ProjectDrawer>
                                </ShowTablet>
                            </Flex.Container>
                        </Container>
                    </div>
                </Space>
                <ModalAlt
                    isOpen={showPaymentTable}
                    onClose={() => setShowPaymentTable(false)}
                    title="Payment Schedule"
                    innerTimes={false}
                >
                    <PaymentTable
                        monthlyPayout={monthlyPayout}
                        totalMonths={installments}
                        termDetails={investmentTermDetails}
                        paymentAmount={paymentAmount}
                    />
                </ModalAlt>
                <ModalAlt
                    isOpen={showModal}
                    onClose={handleShowModal}
                    innerTimes={false}
                >
                    <Space xxsm={["1rem 0 1.5rem"]} stm={["2rem 0 3rem"]}>
                        <P align="center">
                            Dear Investor Investment is going live in 3-4 Days.
                            You will be notified once Investment is{" "}
                            <Span weight="b" color="primary">
                                LIVE
                            </Span>
                        </P>
                    </Space>
                    <Space xxsm={["0 0 2rem"]} stm={["0 0 3rem"]}>
                        <P weight="b" align="center">
                            Thanks for using srot platform.
                        </P>
                    </Space>

                    <Flex.Container justifyContent="center">
                        <Button
                            type="solid"
                            backgroundColor="error"
                            size="s"
                            onClick={handleShowModal}
                        >
                            OK
                        </Button>
                    </Flex.Container>
                </ModalAlt>
                <ModalAlt
                    topPadding="20px"
                    isOpen={showPaymentSummary}
                    onClose={closePaymentSummaryModal}
                    times={false}
                >
                    <Space xxsp={["10px 0"]}>
                        <Text align="center" size="xl" weight="600">
                            Payment Summary
                        </Text>
                    </Space>
                    <Space
                        xlp={["1.3rem 1.5rem"]}
                        stp={["1rem 1.2rem"]}
                        xxsp={[".7rem .5rem"]}
                    >
                        <Card
                            padding="20px 40px"
                            backgroundColor="grey200"
                            borderRadius="10px"
                        >
                            <Flex.Container
                                style={{ paddingBottom: "10px" }}
                                justifyContent="space-between"
                            >
                                <Text size="m" color="black" weight="500">
                                    Amount
                                </Text>
                                <Text
                                    size="m"
                                    color="black"
                                    // weight="500"
                                    fontFamily="Montserrat"
                                >
                                    {twoDecimalLocal(Number(paymentAmount))}
                                </Text>
                            </Flex.Container>
                            <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Duration
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {Number(installments) / 12} Years
                                </Text>
                            </Flex.Container>
                            <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Solar Asset
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {twoDecimalLocal(Number(solarAsset))} KWp
                                </Text>
                            </Flex.Container>
                            <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Monthly Payout
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {twoDecimalLocal(Number(monthlyPayout))}
                                </Text>
                            </Flex.Container>
                            {/* <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Taxes
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {twoDecimalLocal(Number(tds))}
                                </Text>
                            </Flex.Container> */}
                            <Space xxsm={["5px 0 15px"]}>
                                <Line backgroundColor="black" height="1px" />
                            </Space>
                            <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Net Payout
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {twoDecimalLocal(Number(netPayout))}
                                </Text>
                            </Flex.Container>

                            <Space xxsm={["5px 0 15px"]}>
                                <Line backgroundColor="black" height="1px" />
                            </Space>
                            <Flex.Container
                                justifyContent="space-between"
                                style={{ paddingBottom: "10px" }}
                            >
                                <Text size="m" color="black" weight="500">
                                    Amount to be paid
                                </Text>
                                <Text
                                    fontFamily="Montserrat"
                                    size="m"
                                    color="black"
                                    // weight="500"
                                >
                                    {twoDecimalLocal(Number(paymentAmount))}
                                </Text>
                            </Flex.Container>
                        </Card>
                    </Space>

                    <Space xxsm={["20px 0 0"]}>
                        <Button
                            type="solid"
                            fullWidth
                            disabled={paymentLoading}
                            isLoading={paymentLoading}
                            onClick={createOrderInvestorWallet}
                        >
                            PAY NOW
                        </Button>
                    </Space>
                </ModalAlt>
                <ListingSuccessModal
                    message={investSuccessMsg}
                    onClose={closeSuccessModal}
                ></ListingSuccessModal>

                <QuickKYComplete
                    openKycModal={openKycModal}
                    onClose={() => setOpenKycModal(false)}
                    onToggleKyc={onKycStatusClick}
                />
            </>
        );
    }
    return content;
};

export default ProjectDetailsWrap;
