import React from "react";
import styled from "styled-components";
import Section from "../components/section/Section";
import Text from "../components/text/Text";

const HeadingContainer = styled.div`
    padding: 180px 5% 120px;
    background: ${(props) => props.theme.color.secondary};
`;

const TermsAndConditions = () => (
    <>
        <HeadingContainer>
            <Text size="xml" align="center" weight="bold" color="white">
                Terms & Conditions
            </Text>
        </HeadingContainer>
        <Section height="auto">
            <Text style={{ paddingTop: "100px" }} weight="400" size="s">
                This document is published in accordance with the provisions of
                Rule 3(1) of the Information Technology (Intermediaries
                Guidelines) Rules, 2011 that require publishing the rules and
                regulations, privacy policy, and user agreement for access or
                usage of our website 'https://srot.app' (hereinafter referred to
                as the "Website"). The Website is an internet-based asset
                ownership portal owned and operated by Hypersrot Technologies
                Private Limited, a company incorporated under the Companies Act,
                2013 having its registered office at 1207/343, 9th Main Road,
                7th Sector, HSR Layout, Bangalore, Karnataka, India, 560102
                (hereinafter, referred to as "we", "us", "our" and "Srot"). Any
                Registered user or user who is a natural and legal person
                hereinafter, referred to as "you", "your", "user" If you have
                additional questions or require more information about our terms
                & conditions, do not hesitate to contact us through email at
                founders@srot.io The terms and conditions as contained in this
                agreement applies to the Website, your use of our Website and
                its related sites, services and tools. By using the Website, you
                imply to agree and accept these Terms & conditions. If you do
                not agree or are not willing to be bound by the terms and
                conditions please do not click on the ["Accept"] button and do
                not seek to obtain access to or otherwise use the Website. As
                long as you comply with the provisions of these Terms and
                conditions, we grant you a personal, non-exclusive,
                non-transferable, limited privilege to enter and use the
                Website. Your compliance with these Terms and conditions is a
                condition to your right to access the Website. Your breach of
                any provision of these Terms and conditions will automatically,
                without the requirement of notice or other action, revoke and
                terminate your right to access the Website.
            </Text>
            <br />
            <Text weight="600" size="s">
                Conditions:
            </Text>
            <Text weight="600" size="s">
                YOUR ACCOUNT:
            </Text>
            <Text weight="400" size="s">
                You may need to create an account by completing the registration
                process and by completing the KYC requirements given on the
                website. The use of the Website is offered to you conditioned on
                your ‘Acceptance’ of all the terms, conditions, and notices.
                Upon ‘Acceptance’, these Terms and conditions will be effective
                and binding upon you along with any amendments made by the
                website at its sole discretion and posted on the Website and you
                will not claim invalidity of these User Terms merely on the
                grounds that this MOU or acceptance is being concluded
                electronically. For the aforesaid purposes, 'Acceptance' will
                mean your affirmative action in clicking on the ["Accept"]
                button as provided on the registration page or ticking the box
                or any act which reflects your use of the Website or the
                services provided by the Website or any such other actions that
                imply your acceptance. You will be responsible for all
                activities that occur under your account. You are responsible
                for safeguarding your account, so use a strong password and
                limit its use to this account. We cannot and will not be liable
                for any loss or damage arising from your failure to comply with
                the above. You confirm and acknowledge that by registering on
                the Website, you authorize and give consent to srot sending
                promotional and marketing content to you through emails, phone
                calls, short message service, or other electronic and
                non-electronic modes of communication, and no such communication
                shall be deemed to be in violation of law relating to
                unsolicited commercial communication. You agree to provide your
                explicit consent to fetch your credit data from Experian CIC on
                a monthly basis.
            </Text>
            <br />
            <Text weight="600" size="s">
                WHO MAY USE THE SERVICES?
            </Text>
            <Text weight="400" size="s">
                You may use the Services only if you agree to form a binding
                contract with Hypersrot Technologies Pvt. Ltd. and are not a
                person barred from receiving services under the laws of the
                applicable jurisdiction. In any case, you must be at least 18
                years old to use the Services. If you are accepting these Terms
                and using the Services on behalf of a company, organization,
                government, or other legal entity, you represent and warrant
                that you are authorized to do so and have the authority to bind
                such entity to these Terms, in which case the words "you" and
                "your" as used in these Terms shall refer to such entity.
            </Text>
            <br />
            <Text weight="600" size="s">
                SECURITY:
            </Text>
            <Text weight="400" size="s">
                We value your trust in providing us with your Personal
                Information, thus we are striving to use commercially acceptable
                means of protecting it. But remember that no method of
                transmission over the internet, or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its
                absolute security.
            </Text>
            <br />
            <Text weight="600" size="s">
                CHILDREN'S INFORMATION
            </Text>
            <Text weight="400" size="s">
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity. Hypersrot Technologies Pvt. Ltd. does not knowingly
                collect any Personal Identifiable Information from children
                under the age of 13. If you think that your child provides this
                kind of information on our website, we strongly encourage you to
                contact us immediately and we will do our best efforts to
                promptly remove such information from our records. Information
                We Collect We believe you should always know what data we
                collect from you and how we use it.
            </Text>
            <br />
            <Text weight="600" size="s">
                REPRESENTATIONS AND WARRANTIES
            </Text>
            <Text weight="400" size="s">
                By Using this Website, you represent and warrant that: 
                <ol>
                    <li>
                        You are a citizen of India or, in case of juridical
                        persons, are formed under the applicable laws of India;
                    </li>
                    <li>
                        You are 18 years of age or older and that your use of
                        the Website will not violate any applicable law or
                        regulation; 
                    </li>
                    <li>
                        During registration as a User on the Website, you have
                        provided information, which is true, accurate, current,
                        complete and updated about yourself; and
                    </li>
                    <li>
                        You will use the Website solely for your personal and
                        non-commercial use. Any use of this Website or its
                        content other than for personal purposes is strictly
                        prohibited.
                    </li>
                </ol>
                The Website, including all Information, is owned by or licensed
                to Hypersrot and is subject to and protected by various
                intellectual property rights, including but not limited to the
                copyright, trade secrets, trademarks, service marks, brand
                names, and other proprietary rights whether under contract,
                statute or any similar provisions (“IP Rights”). All IP Rights
                are and will remain the exclusive property of Hypersrot, its
                respective third-party licensors, or third parties to whom it is
                attributed and in using the Website individual users will not
                obtain any rights, title, or other interest in or to any
                information on the Website or related IP Rights. Subject to the
                above, individual users are entitled to view the Information on
                the Website and to copy and print such information for personal
                use. Individual users are not permitted to sell or distribute or
                otherwise deal with the Information on the Website or any
                deviations of such information without the prior written consent
                of Hypersrot.
            </Text>
            <br />
            <Text weight="600" size="s">
                RISK TERMS:
            </Text>
            <Text weight="400" size="s">
                You solely assume all the risks involved in the purchase, sale,
                and management of the assets, and you agree to not hold srot
                responsible for delays, defaults, frauds, or other risks in
                relation to the management of the assets under any circumstances
                whatsoever. You understand that risks involved while transacting
                on the srot Platform are: Delay or default in Payment: Delay or
                default in payment of the lease/ license fee or sale of the
                assets Dispute: Dispute between srot, the user, and the
                lessee/consumer regarding the assets resulting in payment delays
                or return of assets Bankruptcy: Lessee/consumer declaring itself
                as bankrupt/insolvent or the initiation of any insolvency
                proceedings against the lessee Fraud Risk: Fraudulent act
                involving the lessees/consumers of the assets leading to failure
                in payment of lease rent and/or return of the leased asset Asset
                Performance: Lower performance of assets due to technical,
                natural, external or any other factors resulting in the lower
                revenue generation by assets at consumer site. You understand
                that Hypersrot Technologies Private Limited (“srot”) is not a
                stock exchange, as recognized by the Securities and Exchange
                Board of India under the Securities Contract (Regulations) Act,
                1956. The srot Platform is not a fundraising platform.
            </Text>
            <br />
            <Text weight="600" size="s">
                NO WARRANTY; LIMITATION ON LIABILITY
            </Text>
            <Text weight="400" size="s">
                By using the website, you expressly agree that such use is at
                your sole risk. The website and related services are provided on
                an "as is", "as available" and "with all faults" basis. Neither
                Hypersrot nor any of its respective officers, directors,
                employees, agents, third-party content providers, designers,
                contractors, distributors, merchants, sponsors, licensors, or
                the like (collectively, "associates") warrant that use of the
                website or related services will be uninterrupted or error-free.
                Neither Hypersrot nor its associates warrant the accuracy,
                integrity, completeness, availability, or timeliness of the
                content provided on the website or the materials or services
                offered on the website now or in the future. Hypersrot and its
                Associates specifically disclaim all warranties, whether
                expressed or implied, including but not limited to warranties of
                title, merchantability, fitness for a particular purpose, or
                non-infringement of the website, information on the website, or
                the results obtained from the use of the website or related
                services. Hypersrot and its associates accept no responsibility
                for updating any part of the website or the content therein.
                Under no circumstances will Hypersrot or its associates be
                liable to you or anyone else for any direct, consequential,
                incidental, special, exemplary, punitive, or other indirect
                damages, including but not limited to lost profits, trading
                losses, unauthorized access, systems failure, communications
                line failure, internet failure or damages that result from use
                or loss of use of the website, content, inconvenience or delay.
                This is true even if Hypersrot has been advised of the
                possibility of such damages or losses.
            </Text>
            <br />
            <Text weight="600" size="s">
                NOTICES
            </Text>
            <Text weight="400" size="s">
                All notices and other communications required or permitted
                hereunder to be given to a party will be in writing, in the
                English language, and will be sent an e-mail, or mailed by
                prepaid internationally-recognized courier or otherwise
                delivered by hand or by messenger, addressed to such party’s
                address as set forth below or at such other address as the party
                will have furnished to the other party in writing in accordance
                with this provision: If to srot: founders@srot.io If to You: At
                the email address provided by You.
            </Text>
            <br />
            <Text weight="600" size="s">
                REFUND & CANCELLATION POLICY:
            </Text>
            <Text weight="400" size="s">
                Srot is an alternative investment platform, refunds and
                cancellations are not applicable. Investing in opportunities
                listed on the website is your independent call and you can
                choose to withdraw from investing at any time while the
                opportunity is in "funding" state. Your mere browsing of our
                website, perusal of the investment opportunity listed, or
                showing interest to invest in the investment opportunity(ies)
                listed on the Website, https://srot.app, does not create any
                contractual right/obligation whatsoever unless and until you
                make the payment mentioned on Website. Further, we do not
                warrant the subsequent availability of an investment opportunity
                for which payment is not completed. <br />
                You can withdraw the money which is not invested in any
                opportunity(ies) from your wallet, except the joining, referral
                bonus, or any other type of bonus and the same will be credited
                to your bank account given by you within 15 working days.
            </Text>
            <br />
            <Text weight="600" size="s">
                CHANGES TO THESE TERMS
            </Text>
            <Text weight="400" size="s">
                We reserve the right to update (change, modify, add and/or
                delete) these User Terms from time to time, at our sole
                discretion, to reflect company initiatives, changes in the law
                or technology, or changes in our practices. When we update these
                User Terms, we will intimate you of the amendments on your email
                address or on the Website. If you do not agree to the
                amendments, please do not use the Website any further. By
                continuing to use the Website, you will be deemed to have
                consented to the latest version of these User Terms. If you have
                additional questions or require more information about our terms
                & conditions, do not hesitate to contact us through email at
                founders@srot.io
            </Text>
            <br />
            <Text weight="600" size="s">
                Arbitration:
            </Text>
            <Text weight="400" size="s">
                If any dispute arises between you and srot during your use of
                the Website or thereafter, in connection with the validity,
                interpretation, implementation, or alleged breach of any
                provision of these Terms and conditions, or the documents they
                incorporate by reference, the dispute will be referred to a sole
                Arbitrator who will be an independent and neutral third party
                identified by srot. The place of arbitration will be Pune,
                Maharashtra. The arbitration proceedings will be in the English
                language and will be governed by Arbitration & Conciliation Act,
                1996.
            </Text>
            <br />
            <Text weight="600" size="s">
                Governing Law:
            </Text>
            <Text style={{ paddingBottom: "100px" }} weight="400" size="s">
                These User Terms or the documents they incorporate by reference
                will be governed and construed in accordance with the laws of
                India. Subject to the clause of Arbitration above, all disputes
                arising under these User Terms between you and srot will be
                subject to the exclusive jurisdiction of courts in Pune,
                Maharashtra, India.
            </Text>
        </Section>
    </>
);

export default TermsAndConditions;
