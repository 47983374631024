import {
    completeKyc,
    firstSlide,
    howItWorks,
    investOpportunities,
    loadFunds,
    secondSlide,
    thirdSlide,
} from "../assets";
import { SINGUP } from "./routes";

export const carouselData = [
    {
        heading: "Complete Your Profile KYC",
        list: [
            "Go to Profile",
            "Select KYC",
            "Upload PAN & Aadhar",
            "Enter your Bank Details",
            "Acknowledge & Submit",
        ],
        width: ["70%", "50%", "60%"],
        background: {
            link: completeKyc,
            color: "#fff",
        },
    },
    {
        heading: "Invest into Opportunities",
        list: [
            "Go to Opportunity",
            "Select Open Opportunity",
            "Enter Investment Amount",
            "Select tenure: 2.5Y or 5Y",
            "Sign Agreement & Invest",
        ],
        width: ["70%", "50%", "60%"],
        background: {
            link: loadFunds,
            color: "#fff",
        },
    },
    {
        heading: "Load Funds into the Wallet",
        list: [
            "Go to Wallet > Deposit",
            "Enter the Amount",
            "Complete the Payment",
        ],
        width: ["70%", "50%", "60%"],
        background: {
            link: investOpportunities,
            color: "#fff",
        },
    },
    {
        heading: "If you still have open questions, go to the FAQ section.",
        link: {
            text: "faq",
        },
        width: ["90%", "90%", "100%"],
        background: {
            link: howItWorks,
            color: "#fff",
        },
    },
];
export const helperData = [
    {
        heading: "Complete Your Profile KYC",
        className: "box  box1 animate",
        width: ["70%", "50%", "60%"],
        background: {
            link: firstSlide,
            color: "#fff",
        },
    },
    {
        heading: "Invest into Opportunities",
        className: "box  box2 animate",

        width: ["70%", "50%", "60%"],
        background: {
            link: secondSlide,
            color: "#fff",
        },
    },
    {
        heading: "Load Funds into the Wallet",
        className: "box  box3 animate",
        width: ["70%", "50%", "60%"],
        background: {
            link: thirdSlide,
            color: "#fff",
        },
    },
];

export const mobileContent = [
    {
        id: 1,
        title: "Get ₹ 1000 Joining Bonus, Use Code",
        btnText: "LAUNCH",
        link: `${SINGUP}?referral_code=LAUNCH`,
    },
    {
        id: 2,
        title: "Free Webinar for Platform Demo",
        btnText: "Register Now",
        link: SINGUP,
    },
    {
        id: 1,
        title: "Earn 250% Higher returns than Bank FD",
        btnText: "SIGN UP",
        link: SINGUP,
    },
];
