/* eslint-disable import/no-anonymous-default-export */

const householdHouseServiceFromGovernmentAns = 1.2;
export const householdHouseElectricityMultiplier = 0.0012;

export default [
    {
        category: "Lifestyle",
        id: "lifeStyle",
        questions: [
            {
                id: "lifeStyleExpenses",
                text: "Your monthly Expense for Clothing / Electronics / Furniture/ Leisure/culture/health ?",
                title: "Expenses",
                answers: [
                    {
                        text: "< Rs. 5000 per Month",
                        coEmission: 2.8,
                    },
                    {
                        text: "< Rs. 15000 per Month",
                        coEmission: 3.57,
                    },
                    {
                        text: "< Rs. 25000 per Month",
                        coEmission: 4.48,
                    },
                    {
                        text: "> Rs. 45000 per Month",
                        coEmission: 6.23,
                    },
                ],
            },
            {
                id: "lifeStyleFood",
                text: "What type of food do you consume?",
                title: "Food Habits",
                answers: [
                    {
                        text: "Only Vegan",
                        coEmission: 1.1,
                    },
                    {
                        text: "Vegetarian",
                        coEmission: 1.53,
                    },
                    {
                        text: "Non-veg alternatively",
                        coEmission: 1.92,
                    },
                    {
                        text: "Mostly Non-veg",
                        coEmission: 2.67,
                    },
                ],
            },
        ],
    },
    {
        category: "Mobility",
        id: "mobility",
        questions: [
            {
                id: "mobilityDailyCommute",
                text: "How do you commute daily?",
                title: "Transport",
                answers: [
                    {
                        text: "Usually, Walk occasionally Public transport",
                        coEmission: 0.313,
                    },
                    {
                        text: "More Usage of Public Transport",
                        coEmission: 0.42,
                    },
                    {
                        text: "Usually car occasionally Pubilc Transport",
                        coEmission: 1.79,
                    },
                    {
                        text: "Personal Car",
                        conditionalAnswers: [
                            {
                                text: "Car Diesel",
                                coEmission: 4.39,
                            },
                            {
                                text: "Car Petrol",
                                coEmission: 3.98,
                            },
                            {
                                text: "Car CNG",
                                coEmission: 2.4,
                            },
                        ],
                    },
                    // new data added from here
                    {
                        text: "Only 2 Wheeler",
                        coEmission: 2.1,
                    },
                    {
                        text: "Electric car or 2 wheeler",
                        coEmission: 0,
                    },
                ],
            },
            {
                id: "mobilityLongDistance",
                text: "Do you travel long distances?",
                title: "Long Distance Travel",
                categories: [
                    {
                        id: "mobilityAirLongDistance",
                        text: "Air Travel",
                        answers: [
                            {
                                text: "No Air travel",
                                coEmission: 0,
                            },
                            {
                                text: "Domestic once or twice /year",
                                coEmission: 0.98,
                            },

                            {
                                text: "Moderate (5-10 travels / year)",
                                coEmission: 3.31,
                            },
                            {
                                text: "High (more than 10 travel / year)",
                                coEmission: 8.125,
                            },
                        ],
                    },
                    {
                        id: "mobilityRailwayLongDistance",
                        text: "Railways",
                        answers: [
                            {
                                text: "Less than 5 trips / year",
                                coEmission: 0.5,
                            },
                            {
                                text: "More than 5 trips / year",
                                coEmission: 1.2,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        category: "Household",
        id: "household",
        questions: [
            {
                id: "householdFamilyMembers",
                text: "How many members of your Family live together?",
                title: "House Type",
                answers: [
                    {
                        text: "2-4 members",
                        coEmission: 0.11,
                    },
                    {
                        text: "more then 5 members",
                        coEmission: 1.44,
                    },
                ],
            },
            // {
            //     id: "householdHouseConstructionType",
            //     text: "How old is your house?",
            //     title: "Construction Type",
            //     answers: [
            //         {
            //             text: "New & Energy Efficient < 10 yrs",
            //             coEmission: 0.05,
            //         },
            //         {
            //             text: "Older Structure > 10yr",
            //             coEmission: 0.86,
            //         },
            //     ],
            // },
            // {
            //     id: "householdHouseEnergyType",
            //     text: "Type of energy used at your home?",
            //     title: "Household Energy Type",
            //     answers: [
            //         {
            //             text: "Use Renewable energy at home",
            //             coEmission: 0,
            //         },
            //         {
            //             text: "Conventional Energy",
            //             coEmission: 3,
            //         },
            //     ],
            // },
            // new added
            {
                id: "householdHouseElectricity",
                text: "Electricity Usage",
                title: "Household Electricity",
                answers: [
                    {
                        text: "Electricity Bill Monthly",
                        type: "input",
                        multiplier: householdHouseElectricityMultiplier,
                    },
                ],
            },
            {
                id: "householdHouseCookingFoodType",
                text: "How do you cook your food?",
                title: "Household Food Type",
                answers: [
                    {
                        text: "LPG / Pipeline Gas",
                        coEmission: 0.246,
                        type: "checkbox",
                    },
                    {
                        text: "Electric stove or microwave",
                        coEmission: 0.345,
                        type: "checkbox",
                    },
                ],
            },
            {
                id: "householdHouseServiceFromGovernment",
                text: "Services from Government",
                title: "Services from Government",
                answers: [
                    {
                        text: "Contribution due to usage of Public services like roads, watersupply, railways, hospitals, waste segregation etc.",
                        checked: true,
                        coEmission: householdHouseServiceFromGovernmentAns, // values needed to be change
                    },
                ],
            },
        ],
    },
];

export const defaultQuessionareAnswer = {
    householdHouseServiceFromGovernment: householdHouseServiceFromGovernmentAns,
};
