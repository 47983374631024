import React, { Fragment, useEffect, useRef } from "react";
import styled from "styled-components";
import Space from "../components/Spacing/Space";
import BulletList from "../components/bullet-list/BulletList";
import Image from "../components/image/Image";
import { Flex } from "../components/styles/Elements.styles";
import { H2, H3, H4, P } from "../components/styles/Typography.styles";

const ContainerReverse = styled(Flex.Container)``;

const SrotInvestment = ({ id, data, visibilityHandler }) => {
    const sortInvestmentRef = useRef();
    useEffect(() => {
        let target = sortInvestmentRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [sortInvestmentRef, visibilityHandler]);

    const { paragraph, subHeadings, pricing } = data?.block1;
    return (
        <div
            id={id}
            ref={sortInvestmentRef}
            data-title="SROT INVESTMENT COMPARISON"
        >
            <Flex.Container justifyContent="space-between">
                <Flex.Item xl="50%" xxs="100%">
                    <Flex.Container justifyContent="flex-start">
                        <Flex.Item xl="90%" xxs="100%">
                            <Flex.Container justifyContent="center">
                                <PricingContent
                                    subHeadings={subHeadings}
                                    paragraph={paragraph}
                                />
                            </Flex.Container>
                        </Flex.Item>
                    </Flex.Container>
                </Flex.Item>
                <Space padding={["0 0 0 0"]}>
                    <Flex.Item xl="45%" xxs="100%">
                        {/* @DESC:: Pricing Card @habib610 Thu June 27,2024 */}
                        <PricingTable data={pricing} />
                    </Flex.Item>
                </Space>
            </Flex.Container>
            <Space xxsp={["20px 0 0"]}>
                <Flex.Container justify="space-between">
                    <Space xxsp={["8% 0 0 0"]}>
                        <Flex.Item xl="50%" xxs="100%">
                            <PricingContent
                                subHeadings={data?.block2.subHeadings}
                                paragraph={data?.block2.paragraph}
                                heading={data?.block2.heading}
                            />
                        </Flex.Item>
                    </Space>

                    <Flex.Item xl="45%" xxs="100%">
                        <Flex.Container justifyContent="flex-end">
                            <Flex.Item xl="90%" xxs="100%">
                                <Flex.Container justifyContent="center">
                                    {/* @TODO => Change this image @habib610 Thu June 27,2024 */}

                                    <Space xlm={["5% 0px"]} xxsp={["10% 0"]}>
                                        <Image src={data.block2.img} />
                                    </Space>
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                    </Flex.Item>
                </Flex.Container>
            </Space>
        </div>
    );
};

export default SrotInvestment;

const Highlight = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    .box {
        height: 10px;
        width: 10px;
        background-color: ${(props) => props.theme.color[props.color]};
    }
    ${H2} {
        color: ${(props) => props.theme.color[props.color]};
    }
`;

const PricingContent = ({ subHeadings, paragraph, heading }) => {
    return (
        <Space xlm={["0px 0px 5% 0px"]} xxsm={["10% 0"]}>
            {/* @DESC:: Pricing Details @habib610 Thu June 27,2024 */}
            <div>
                {subHeadings.map((item, i) => (
                    <Highlight color={i === 0 ? "black800" : "primary"} key={i}>
                        <div className="box"></div>
                        <H2 className="text">{item}</H2>
                    </Highlight>
                ))}
                {heading && (
                    <BannerText>
                        <H3 color="white" align="center">
                            {heading}
                        </H3>
                    </BannerText>
                )}

                <Space>
                    <div>
                        {paragraph.map((item, i) => (
                            <BulletList color="darkGrey" key={i} title={item} />
                        ))}
                    </div>
                </Space>
            </div>
        </Space>
    );
};

const PricingTable = ({ data }) => {
    return (
        <>
            <Space xxsm={["0 0 2rem"]}>
                <Flex.Container>
                    <Flex.Item xxs="30%" st="32%"></Flex.Item>
                    <Flex.Item
                        style={{ position: "relative" }}
                        xxs="40%"
                        st="36%"
                    >
                        <Flex.Container
                            justify="center"
                            align="center"
                            direction="column"
                        >
                            <div>
                                <ImageStyled src={data.heading[1].icon} />
                            </div>
                            <Space xxsp={"0 .5rem"}>
                                <H4 color="white" align="center">
                                    {data.heading[1].name}
                                </H4>
                            </Space>
                        </Flex.Container>
                        <GradientHighlight></GradientHighlight>
                    </Flex.Item>
                    <Flex.Item xxs="30%" st="32%">
                        <Flex.Container
                            justify="center"
                            align="center"
                            direction="column"
                        >
                            <div>
                                <ImageStyled src={data.heading[2].icon} />
                            </div>

                            <H4 align="center">{data.heading[2].name}</H4>
                        </Flex.Container>
                    </Flex.Item>
                </Flex.Container>
            </Space>

            <Flex.Container>
                {data?.data.map((item) => (
                    <Fragment key={item?.id}>
                        <FlexCol
                            xxs="30%"
                            st="32%"
                            style={{ paddingLeft: ".5rem" }}
                        >
                            <P weight="b">{item?.name}</P>{" "}
                        </FlexCol>
                        <FlexCol xxs="40%" st="36%">
                            <P weight="b" align="center">
                                {item?.investment}
                            </P>{" "}
                        </FlexCol>
                        <FlexCol xxs="30%" st="32%">
                            <P align="center">{item?.deposit}</P>{" "}
                        </FlexCol>
                    </Fragment>
                ))}
            </Flex.Container>
        </>
    );
};

const FlexCol = styled(Flex.Item)`
    padding: 1rem 0;
    border-top: 1px solid ${(props) => props.theme.color.grey600};
`;
const BannerText = styled.div`
    background-color: ${(props) => props.theme.color.black};
    color: ${(props) => props.theme.color.white};
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    box-shadow: 5px 5px ${(props) => props.theme.color.cyan};
    margin: 1rem 0 3rem;
`;

const GradientHighlight = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    height: 390px;

    background: linear-gradient(
        187.23deg,
        #356bdb 22.31%,
        #c4ddf9 42.52%,
        #deeafb 55.21%,
        #ffffff 74.12%
    );

    z-index: -10;
    /* opacity: 0.3; */
    border-radius: 1.5rem;
`;

const ImageStyled = styled(Image)`
    height: 30px;
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        height: 35px;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        height: 50px;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        height: 60px;
    }
`;
