import React from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container";
import Text from "../../components/text/Text";
import { projectHeroData as data } from "../../constants/projectsConstants";

const Section = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.color.black800};
    /* min-height: 48vh; */
    align-items: flex-end;
    padding: 6rem 0 3rem;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: 4rem 0 0;
        .removeMobile {
            display: none;
        }
    }
`;

const ProjectsHero = () => {
    return (
        <div>
            <Section>
                <Container className="removeMobile">
                    <Space padding={["4rem 0 1rem"]}>
                        <Flex.Container alignItems="center" direction="column">
                            <Flex.Item
                                width="80%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <div>
                                    <Text
                                        lineHeight="1.2em"
                                        size="xml"
                                        align="center"
                                        color="white"
                                        fontFamily="Montserrat"
                                        weight="700"
                                    >
                                        {data.heading}
                                    </Text>
                                    <Space margin={["1.5rem 0 1rem"]}>
                                        <Text
                                            size="m"
                                            align="center"
                                            color="white"
                                            fontFamily="Montserrat"
                                        >
                                            {data.subHead}
                                        </Text>
                                    </Space>

                                    <Text
                                        size="m"
                                        align="center"
                                        color="white"
                                        fontFamily="Montserrat"
                                        weight="500"
                                    >
                                        {data.tagline}
                                    </Text>
                                </div>
                            </Flex.Item>
                            {/* <Flex.Item
                                width="100%"
                                tabletWidth="100%"
                                mobileWidth="100%"
                            >
                                <Space margin={["3rem 0 0"]}>
                                    <TagsContainer bg="white">
                                        {data.tags.map((tag) => (
                                            <TagItem
                                                {...tag}
                                                sizeS="s"
                                                color="primary"
                                                size="l"
                                            />
                                        ))}
                                    </TagsContainer>
                                </Space>
                            </Flex.Item> */}
                        </Flex.Container>
                    </Space>
                </Container>
            </Section>
        </div>
    );
};

export default ProjectsHero;
