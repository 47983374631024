import { FaMapMarkerAlt } from "react-icons/fa";
import { pdfDownload } from "../assets";
import { PROJECTS } from "./routes";

export const projectDocumentsData = [
    {
        id: 1,
        name: "Leasing Agreements",
        link: "/docs/Investor_Leasing_Agreement.pdf",
        icon: pdfDownload,
    },
];

export const projectHeroData = {
    heading: "Our Projects",
    subHead: `Every investment small or large, is a step towards saving our "Mother Earth". Join our community of "Green Warriors" who are striving forward to save the planet.`,
    tagline: "Be the Change you want to see !!",
    tags: [
        {
            id: 1,
            value: "63.70",
            unit: "KW",
            name: "Digital Solar Installed",
        },
        {
            id: 2,
            value: "83,219.50",
            unit: "KWh",
            name: "Clean Energy Delivered",
        },
        {
            id: 3,
            value: "₹9,57,024.25",
            unit: "",
            name: "Credits Generated",
        },
        {
            id: 4,
            value: "70,736.58",
            unit: "Kgs",
            name: "Carbon Avoided",
        },
    ],
};
export const projectBannerData = {
    heading: "Host panels for your workplace",
    subHead:
        "Got some sunny roof space? Commercial & Industrial businesses can now switch  to clean energy with no setup costs and save up on their power bills.",
    button: {
        name: "Start Onboarding",
        link: "/", /// todo: change
    },
};
export const projectCardData = {
    heading: "Our Projects",
    cards: [
        {
            id: 1,
            img: "https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            isOpen: true,
            status: "Funding",
            location: "Pune",
            name: "Songsbirds",
            link: PROJECTS,
            tags: [
                {
                    id: 1,
                    value: "100",
                    unit: "KWp",
                    name: "Solar Capacity",
                },
                {
                    id: 2,
                    value: "DEC 2022",
                    unit: "",
                    name: "Funding Date",
                },
            ],
            values: [
                {
                    id: 1,
                    name: "Total Investment",
                    value: "Rs. 56.9 L",
                },
                {
                    id: 2,
                    name: "Investment Raised",
                    value: "12 Lacs",
                },
                {
                    id: 3,
                    name: "Lock-in Period",
                    value: "12 Months",
                },
                {
                    id: 4,
                    name: "Return (IRR)",
                    value: "16.5%",
                },
                {
                    id: 5,
                    name: "Minimum Investment",
                    value: "50,000",
                },
            ],
            progress: 20,
        },
        {
            id: 2,
            img: "https://images.pexels.com/photos/1475938/pexels-photo-1475938.jpeg",
            isOpen: true,
            status: "Funding",
            location: "Pune",
            name: "F Residency",
            link: PROJECTS,
            tags: [
                {
                    id: 1,
                    value: "100",
                    unit: "KWp",
                    name: "Solar Capacity",
                },
                {
                    id: 2,
                    value: "DEC 2022",
                    unit: "",
                    name: "Funding Date",
                },
            ],
            values: [
                {
                    id: 1,
                    name: "Total Investment",
                    value: "Rs. 56.9 L",
                },
                {
                    id: 2,
                    name: "Investment Raised",
                    value: "12 Lacs",
                },
                {
                    id: 3,
                    name: "Lock-in Period",
                    value: "12 Months",
                },
                {
                    id: 4,
                    name: "Return (IRR)",
                    value: "16.5%",
                },
                {
                    id: 5,
                    name: "Minimum Investment",
                    value: "50,000",
                },
            ],
            progress: 20,
        },
        {
            id: 3,
            img: "https://images.pexels.com/photos/5331879/pexels-photo-5331879.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            isOpen: false,
            progress: 20,
        },
    ],
};
export const projectDetails = {
    investment: {
        balance: "55,001.24",
        investment: 10000,
        values: [
            {
                id: 1,
                name: "Solar Asset",
                value: "0.18KW",
            },
            {
                id: 2,
                name: "Targeted Rental Yield",
                value: "13.50%",
            },
            {
                id: 3,
                name: "Investment Term",
                value: "10 Years",
            },
            {
                id: 4,
                name: "Monthly Payout",
                value: "₹ 1678.4",
            },
            {
                id: 5,
                name: "Gross Returns",
                value: "₹ 16,2501.0",
            },
            {
                id: 6,
                name: "TDS",
                value: "₹ 2013.6",
            },
            {
                id: 7,
                name: "Net Payout",
                value: "₹ 16,7787.4",
            },
        ],
    },

    details: {
        heading: "About the Project",
        about: "Pioneer is our pilot project, the one that started it all. The 5KW system is hosted on the rooftops of a residential space in North Bangalore. Being closer to our place of work, made it ideal for testing and an initial release.",
        img: "https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        isOpen: true,
        status: "Funding",
        location: "Pune",
        name: "Songsbirds",
        tags: [
            {
                id: 1,
                value: "Songsbirds",
                name: "Pune",
                icon: FaMapMarkerAlt,
            },
            {
                id: 2,
                value: "100",
                unit: "KWp",
                name: "Solar Capacity",
            },
            {
                id: 2,
                value: "DEC 2022",
                unit: "KWp",
                name: "Solar Capacity",
            },
        ],
        values: [
            {
                id: 1,
                name: "Total Investment",
                value: "Rs. 56.9 L",
            },
            {
                id: 2,
                name: "Investment Raised",
                value: "12 Lacs",
            },
            {
                id: 3,
                name: "Lock-in Period",
                value: "12 Months",
            },
            {
                id: 4,
                name: "Return (IRR)",
                value: "16.5%",
            },
            {
                id: 5,
                name: "Minimum Investment",
                value: "50,000",
            },
        ],
        progress: 20,
    },
};
