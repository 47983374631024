import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ErrorFallback from "./components/error/ErrorFallback";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import {
    CONTACTUS,
    ERROR_SCREEN,
    FAQ,
    FORGET,
    LANDING,
    NOT_FOUND,
    OFFSET,
    PORTFOLIO,
    PRIVACY,
    PROFILE,
    PROJECTS,
    RESET_SCREEN,
    TERMS_N_CONDITIONS,
    THANK_YOU,
    VERIFY_EMAIL,
    WALLET,
} from "./constants/routes";
import ContactUS from "./contact-us/ContactUS";
import { useContextState } from "./context/ContextProvider";
import FrequentQuestion from "./faq/FrequentQuestion";
import Landing from "./home";
import useAuth from "./hooks/useAuth";
import NotFound from "./notfound";
import Offset from "./offset";
import Portfolio from "./portfolio";
import PortfolioDetails from "./portfolio/portfolio-details/PortfolioDetails";
import Privacy from "./privacy";
import Profile from "./profile";
import Projects from "./projects";
import ProjectDetails from "./projects/project-details";
import ForgetScreen from "./reset";
import ResetScreen from "./reset/ResetScreen";
import auth from "./services/auth";
import TermsAndConditions from "./terms-and-conditions";
import ThankYou from "./thankyou";
import VerifyEmail from "./verify-email";
import Wallet from "./wallet";

const routeConfigs = [
    {
        path: LANDING,
        RenderComponent: Landing,
        isAuthReq: false,
        exact: true,
    },
    {
        path: FAQ,
        RenderComponent: FrequentQuestion,
        isAuthReq: false,
    },
    {
        path: TERMS_N_CONDITIONS,
        RenderComponent: TermsAndConditions,
        isAuthReq: false,
    },
    {
        path: PRIVACY,
        RenderComponent: Privacy,
        isAuthReq: false,
    },
    {
        path: CONTACTUS,
        RenderComponent: ContactUS,
        isAuthReq: false,
    },
    {
        path: PROJECTS,
        RenderComponent: Projects,
        isAuthReq: false, // change
        exact: true,
    },
    {
        path: `${PROJECTS}/:id`,
        RenderComponent: ProjectDetails,
        isAuthReq: true,
        exact: true,
    },
    {
        path: PORTFOLIO,
        RenderComponent: Portfolio,
        isAuthReq: true, // change
        exact: true,
    },
    {
        path: `${PORTFOLIO}/:id/:key`,
        RenderComponent: PortfolioDetails,
        isAuthReq: true, // change
        exact: true,
    },

    {
        path: WALLET,
        RenderComponent: Wallet,
        isAuthReq: true,
    },
    {
        path: PROFILE,
        RenderComponent: Profile,
        isAuthReq: true,
    },
    {
        path: VERIFY_EMAIL,
        RenderComponent: VerifyEmail,
        isAuthReq: false,
    },
    {
        path: FORGET, // TODO  : check if we need to redirect ?
        RenderComponent: ForgetScreen,
        isAuthReq: false,
    },
    {
        path: RESET_SCREEN, // TODO  : check if we need to redirect ?
        RenderComponent: ResetScreen,
        isAuthReq: false,
    },

    {
        path: OFFSET,
        RenderComponent: Offset,
        isAuthReq: false,
        exact: true,
    },
    {
        path: ERROR_SCREEN,
        RenderComponent: ErrorFallback,
        isAuthReq: false,
    },
    {
        path: THANK_YOU,
        RenderComponent: ThankYou,
        isAuthReq: false,
    },
    {
        path: NOT_FOUND,
        RenderComponent: NotFound,
        isAuthReq: false,
    },
];

const RouteComponent = ({
    RenderComponent,
    isAuthReq,
    redirectIfAuth,
    ...restProps
}) => {
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(restProps?.location?.pathname, dispatch);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // Fetch user state on reload on a page when user is loggedin
        if (!state.user && isAuth) {
            auth.getUserProfile(dispatch);
        }
    }, [dispatch, state.user, isAuth]);

    useEffect(() => {
        if (isAuth !== null && !showContent) {
            setTimeout(() => {
                setShowContent(true);
            }, 5000);
        }
    }, [showContent, isAuth]);

    if (isAuth === null) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }
    /* @TODO => Commenting intro screen Wed May 29,2024 */

    // if (isAuth !== null && !showContent) {
    //     return (
    //         <div>
    //             <IntroScreen />
    //         </div>
    //     );
    // }

    return (
        <AnimatePresence>
            <Route
                {...restProps}
                render={(props) =>
                    isAuth ? (
                        redirectIfAuth ? (
                            <Redirect to={redirectIfAuth} />
                        ) : (
                            <RenderComponent {...props} />
                        )
                    ) : isAuthReq ? (
                        <>
                            <Redirect
                                // to={LANDING}
                                to={{
                                    pathname: LANDING,
                                    state: { from: props.location },
                                }}
                            />
                        </>
                    ) : (
                        <RenderComponent {...props} />
                    )
                }
            />
        </AnimatePresence>
    );
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Routes = () => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                {routeConfigs.map((routeConfig) => (
                    <RouteComponent {...routeConfig} />
                ))}
                <Redirect to={NOT_FOUND} />
            </Switch>
        </>
    );
};

export default Routes;
