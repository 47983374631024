import moment from "moment";
import React, { useEffect } from "react";
import { IMAGE } from "../../assets";
import { NoDataMessage } from "../../components/alert/AlertMessage";
import AlertNew from "../../components/alert/AlertNew";
import Flex from "../../components/flex/Flex";
import LoaderBox from "../../components/loading/LoaderBox";
import Space from "../../components/space/Space";
import Container from "../../components/styles/Container";
import { InlineText } from "../../components/text/HeadingText";
import Text from "../../components/text/Text";
import { OPPORTUNITIES_SUCCESS } from "../../constants/common";
import {
    OPPORTUNITY_ENDPOINT,
    PORTFOLIO,
    PORTFOLIO_ENDPOINT,
    PROJECTS,
} from "../../constants/routes";
import { twoDecimalLocal } from "../../helpers/helpers";
import useAuthHistory from "../../hooks/useAuthHistory";
import useNetwork from "../../hooks/useNetwork";
import ProjectCard, { CardContainer } from "../../projects/ProjectCard";
const YourInvestment = ({ onSetAssets }) => {
    const { setPath } = useAuthHistory();
    const { error, data, loading } = useNetwork(
        OPPORTUNITY_ENDPOINT,
        null,
        null,
        OPPORTUNITIES_SUCCESS
    );
    const {
        error: portFolioErr,
        data: portfolioData,
        loading: portfolioLoading,
    } = useNetwork(PORTFOLIO_ENDPOINT, "GET");

    useEffect(() => {
        if (portfolioData?.data?.investment) {
            onSetAssets(portfolioData?.data?.investment);
        }
    }, [onSetAssets, portfolioData?.data?.investment]);
    let content = null;
    if (loading || portfolioLoading) {
        content = (
            <Flex.Container jusTifyContent="center">
                <LoaderBox />
            </Flex.Container>
        );
    }
    if ((!loading && error) || (!portfolioLoading && portFolioErr)) {
        content = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    {portFolioErr || error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (
        (!loading && !error && data?.data?.assets?.length === 0) ||
        (!portfolioLoading &&
            !portFolioErr &&
            portfolioData?.data?.investment?.length === 0)
    ) {
        content = (
            <Flex.Container justifyContent="center">
                <NoDataMessage>
                    <Space margin={["0 .5rem 0 0 "]}>
                        <Text size="l">
                            You don’t have any investment. Checkout our open
                            projects
                        </Text>
                    </Space>

                    <InlineText
                        role="button"
                        onClick={() => setPath(PROJECTS)}
                        className="link"
                        size="l"
                    >
                        here
                    </InlineText>
                </NoDataMessage>
            </Flex.Container>
        );
    }

    if (
        !loading &&
        !error &&
        data?.data?.assets?.length > 0 &&
        !portfolioLoading &&
        !portFolioErr &&
        portfolioData?.data?.investment?.length > 0
    ) {
        let requiredData = portfolioData?.data?.investment.map((pData) => {
            let listingItem = data.data.assets.find(
                (item) => item.listingId === pData.listing_id
            );

            let plants = listingItem?.plants || [];
            let investmentTerms = listingItem?.investmentTerms || [];
            let startDate = pData?.investment_date * 1000 || 0;
            let invested = pData?.investment_amount
                ? Number(pData?.investment_amount)
                : 0;
            let assetOwned = pData?.solar_asset_owned
                ? Number(pData?.solar_asset_owned).toFixed(2)
                : 0;

            return {
                id: listingItem?.listingId,
                key: pData?.investment_id,
                name: plants[0]?.name || "",
                status: pData?.investment_status || "",
                city: plants[0]?.city || "",
                isOpen: true,
                thumbnail: listingItem?.thumbnail || IMAGE,
                invested: {
                    amount: invested,
                    owned: `${twoDecimalLocal(invested)} (${assetOwned} KWp)`,
                    name: "Investment Amount",
                },

                tagItem: [
                    {
                        id: 1,
                        value: (plants[0]?.capacity || 0) / 1000,
                        name: "Solar Capacity",
                        unit: "KWp",
                    },
                    {
                        id: 2,
                        value: `${moment(startDate).format(
                            "DD MMM YYYY"
                        )}`.toLocaleUpperCase(),
                        name: "Investment Date",
                        unit: "",
                    },
                ],
                details: [
                    {
                        name: "Investment Term",
                        value: `${
                            investmentTerms[0]?.installments / 12 || 0
                        } Years`,
                    },

                    {
                        name: "Return (IRR)",
                        value: `${
                            investmentTerms[0]?.targetedIRRPercentage || 0
                        } %`,
                    },
                ],
            };
        });

        content = (
            <CardContainer
                justifyContent={
                    requiredData.length > 3 ? "center" : "flex-start"
                }
            >
                {requiredData
                    .sort((a, b) => b.key - a.key)
                    .map((item) => (
                        <ProjectCard
                            key={item.key}
                            data={item}
                            link={`${PORTFOLIO}/${item.id}/${item.key}`}
                            id={item.id}
                            keyValue={item.key}
                        />
                    ))}
            </CardContainer>
        );
    }

    return (
        <Space margin={["4rem 0"]} mobilemargin={["2rem 0 4rem"]}>
            <div>
                <Container>{content}</Container>
            </div>
        </Space>
    );
};

export default YourInvestment;
