import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import Space from "../../components/space/Space";
import Text from "../../components/text/Text";

const HistoryBtn = styled(Text)`
    :hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const PaymentScheduleBtn = ({ margin = "0", onClick }) => {
    return (
        <Space margin={[margin]}>
            <Flex.Container justifyContent="center">
                <HistoryBtn
                    align="center"
                    color="primary"
                    size="s"
                    role="button"
                    onClick={onClick}
                >
                    <Space margin={["0 5px 0 0"]}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </Space>
                    View Payment Schedule
                </HistoryBtn>
            </Flex.Container>
        </Space>
    );
};

export default PaymentScheduleBtn;
