import styled from "styled-components";
import Flex from "../flex/Flex";

const FlexImg = styled(Flex.Item)`
    display: flex;
    justify-content: ${(props) => props.justify || "center"};
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        justify-content: ${(props) =>
            props.tabletJustify ? props.tabletJustify : "center"};
        margin-top: ${(props) =>
            props.tabletTMargin
                ? props.theme.spacing.tablet[props.tabletTMargin]
                : ""};
        margin-bottom: ${(props) =>
            props.tabletBMargin
                ? props.theme.spacing.tablet[props.tabletBMargin]
                : ""};
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        justify-content: ${(props) =>
            props.mobileJustify ? props.mobileJustify : "center"};

        margin-top: ${(props) =>
            props.mobileTMargin
                ? props.theme.spacing.mobile[props.mobileTMargin]
                : ""};
        margin-bottom: ${(props) =>
            props.mobileBMargin
                ? props.theme.spacing.mobile[props.mobileBMargin]
                : ""};
    }
`;

export default FlexImg;
