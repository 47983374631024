import {
    FaFacebookSquare,
    FaInstagramSquare,
    FaLinkedin,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaRegEnvelope,
    FaTwitterSquare,
    FaYoutubeSquare,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import {
    CONTACTUS,
    FAQ,
    LANDING,
    NOT_FOUND,
    PRIVACY,
    TERMS_N_CONDITIONS,
} from "../../constants/routes";
import Flex from "../flex/Flex";
import Image from "../image/Image";
import Line from "../line/Line";
import Link from "../link/Link";
import Text from "../text/Text";

import {
    heartbeat,
    industry,
    logoWhite,
    msmeWhite,
    startUpIndia,
} from "../../assets";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { TAB_BREAKPOINT } from "../../theme";
import Container from "../styles/Container";

export const FooterContainer = styled(Flex.Container)`
    background: ${(props) => props.bg || props.theme.color.black800};
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 50px 0 50px;
`;

const CenterSection = styled(Flex.Item)`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        order: 1;
        width: 100%;
        padding-top: 15px;
    }
`;
const CenterTopSection = styled(Flex.Item)`
    box-sizing: border-box;
    padding-right: 80px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        order: 0;
        width: 100%;
        padding-right: 0px;
        padding-bottom: 15px;
    }
`;

const SideSection = styled(Flex.Item)`
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 50%;
    }
`;

export const footerHeight = {
    default: "343px",
    tablet: "502px",
    mobile: "512px",
    smallMobile: "553px",
};

const Footer = ({ bg }) => {
    const location = useLocation();
    const { width } = useWindowDimensions();

    if (location.pathname === NOT_FOUND) return null;
    else if (location.pathname !== LANDING && width < TAB_BREAKPOINT)
        return null;

    return (
        <FooterContainer bg={bg}>
            <Container>
                <Flex.Item width="100%">
                    <Flex.Container>
                        <CenterTopSection width="50%">
                            <Image
                                style={{ marginBottom: "10px" }}
                                src={logoWhite}
                                height="40px"
                            />

                            <Text size="s" color="white">
                                To offset carbon emissions by Sustainable Green
                                Energy solutions and overcome the constraints of
                                Money, Space {"&"} Technology for Green Energy
                                adoption.
                            </Text>
                            <Text
                                weight="bold"
                                size="m"
                                color="white"
                                style={{ width: "100%", margin: "20px 0 10px" }}
                            >
                                Recognised By:
                            </Text>
                            <Flex.Container
                                justifyContent="space-between"
                                align-items="center"
                                style={{ width: "100%", margin: "0px 0" }}
                            >
                                <Flex.Item
                                    width="35%"
                                    tabletWidth="35%"
                                    mobileWidth="35%"
                                >
                                    <Image src={industry} />
                                </Flex.Item>
                                <Flex.Item
                                    width="20%"
                                    tabletWidth="20%"
                                    mobileWidth="20%"
                                >
                                    <Image src={msmeWhite} />
                                </Flex.Item>

                                <Flex.Item
                                    width="35%"
                                    tabletWidth="35%"
                                    mobileWidth="35%"
                                >
                                    <Image src={startUpIndia} />
                                </Flex.Item>
                            </Flex.Container>
                        </CenterTopSection>
                        <SideSection width="25%">
                            <Text weight="bold" size="m" color="white">
                                Quick Links
                            </Text>
                            <Link
                                isInline
                                size="s"
                                color="white"
                                style={{ padding: "25px 0 0" }}
                                to={LANDING}
                            >
                                HOME
                            </Link>
                            <br />
                            <Link
                                isInline
                                size="s"
                                color="white"
                                style={{ padding: "25px 0 0" }}
                                to={CONTACTUS}
                            >
                                CONTACT US
                            </Link>
                            <br />
                            <Link
                                isInline
                                size="s"
                                color="white"
                                style={{ padding: "25px 0 0" }}
                                to={FAQ}
                            >
                                FAQs
                            </Link>
                            <br />
                            <Link
                                size="s"
                                isInline
                                color="white"
                                style={{ padding: "25px 0 25px" }}
                                to={LANDING}
                            >
                                LANDING
                            </Link>
                        </SideSection>
                        <SideSection width="25%">
                            <Text weight="bold" size="m" color="white">
                                Company
                            </Text>
                            <RenderIcon
                                Icon={FaMapMarkerAlt}
                                transform="uppercase"
                                texts={[
                                    "1207/343/95h main road",
                                    "7th sector, HSR",
                                    "Bangalore 560102",
                                ]}
                            />
                            <RenderIcon
                                weight="bold"
                                Icon={FaRegEnvelope}
                                texts={["invest@srot.app"]}
                            />

                            <RenderIcon
                                Icon={FaPhoneAlt}
                                transform="uppercase"
                                texts={["+91 9970079570"]}
                            />
                        </SideSection>
                    </Flex.Container>
                </Flex.Item>
                <Flex.Item width="100%">
                    <Line
                        margin="0 0 20px 0"
                        height="2px"
                        width="100%"
                        backgroundColor="white"
                    />
                </Flex.Item>

                <Flex.Item width="100%">
                    <Flex.Container alignItems="center">
                        <SideSection width="33%">
                            <div>
                                <Link
                                    size="s"
                                    isInline
                                    color="white"
                                    style={{ marginRight: "20px" }}
                                    to={TERMS_N_CONDITIONS}
                                >
                                    Terms
                                </Link>
                                <Link
                                    size="s"
                                    isInline
                                    color="white"
                                    to={PRIVACY}
                                >
                                    Privacy
                                </Link>
                            </div>
                            <Text size="xs" color="white">
                                Copyright © 2021 Hypersrot Technologies Pvt Ltd.
                                All Rights Reserved
                            </Text>
                        </SideSection>
                        <CenterSection width="33%">
                            <Text
                                size="s"
                                color="white"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Made With
                                <Image
                                    width="25px"
                                    src={heartbeat}
                                    alt="with love"
                                    style={{ padding: "0 6px" }}
                                />
                                by Hypersrot
                            </Text>
                        </CenterSection>
                        <SideSection width="33%" style={{ textAlign: "right" }}>
                            <Link
                                isInline
                                isExternal
                                isInteractive
                                color="white"
                                style={{ paddingLeft: "5px" }}
                                size="m"
                                to={
                                    "https://www.linkedin.com/company/hypersrot/"
                                }
                            >
                                <FaLinkedin />
                            </Link>
                            <Link
                                isInline
                                isExternal
                                isInteractive
                                color="white"
                                style={{ paddingLeft: "5px" }}
                                size="m"
                                to={
                                    "https://www.facebook.com/Hypersrot-101576895575440"
                                }
                            >
                                <FaFacebookSquare />
                            </Link>
                            <Link
                                isInline
                                isExternal
                                isInteractive
                                color="white"
                                style={{ paddingLeft: "5px" }}
                                size="m"
                                to={"https://www.instagram.com/hypersrot/"}
                            >
                                <FaInstagramSquare />
                            </Link>
                            <Link
                                isInline
                                isExternal
                                isInteractive
                                color="white"
                                style={{ paddingLeft: "5px" }}
                                size="m"
                                to={"https://twitter.com/hypersrot"}
                            >
                                <FaTwitterSquare />
                            </Link>
                            <Link
                                isInline
                                isExternal
                                isInteractive
                                color="white"
                                style={{ paddingLeft: "5px" }}
                                size="m"
                                to={
                                    "https://www.youtube.com/channel/UCahJwUMoHRSIC0IOnNKf0Yg"
                                }
                            >
                                <FaYoutubeSquare />
                            </Link>
                        </SideSection>
                    </Flex.Container>
                </Flex.Item>
            </Container>
        </FooterContainer>
    );
};

export default Footer;
const IconBox = styled(Text)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;
const RenderIcon = ({ Icon, texts, weight = "normal", transform = "none" }) => {
    return (
        <Flex.Container
            alignItems="center"
            style={{ flexWrap: "nowrap", padding: "25px 0 0" }}
        >
            <IconBox size="m" color="white">
                <Icon />
            </IconBox>

            <div>
                {texts.map((item) => (
                    <Text
                        key={item}
                        color="white"
                        textTransform={transform}
                        transform={transform}
                        size="s"
                        weight={weight}
                    >
                        {item}
                    </Text>
                ))}
            </div>
        </Flex.Container>
    );
};
