import {
    bgBubble,
    circularRupee,
    firstSlide,
    homePhone,
    howItWorks,
    moneyIcon,
    risingGraph,
    secondSlide,
    thirdSlide,
} from "../assets";
import theme from "../theme";

const landingData = {
    carousel: [
        {
            width: ["50%", "60%", "50%"],

            background: {
                link: firstSlide,
            },
            justify: "flex-end",
        },
        {
            width: ["100%", "100%", "100%"],
            background: {
                link: secondSlide,
                color: "#fff",
            },
        },
        {
            width: ["98%", "98%", "98%"],
            background: {
                link: thirdSlide,
                color: "#fff",
            },
        },
    ],
    banner: {
        background: {
            bg: bgBubble,
            image: [homePhone],
        },
        heading: "Earn Rental Yield Upto",
        subHeadings: ["upto 20% IRR"],

        textEffects: [
            `<strong>Explore <span style="color: ${theme.color.primary};">Investment</span> </strong>`,
            `<strong>Explore <span style="color: ${theme.color.primary};">Diversification</span> </strong>`,
            `<strong>Explore <span style="color: ${theme.color.primary};">Fractional Ownership</span> </strong>`,
            `<strong>Explore <span style="color: ${theme.color.primary};">Higher Return</span> </strong>`,
            `<strong>Explore <span style="color: ${theme.color.primary};">Lowest Risk</span> </strong>`,
        ],
        paragraph: [
            "Think Green… Invest Green…",
            "With Srot Save Environment,",
            "Earn High & Stable Returns",
        ],
    },
    works: {
        background: {
            bg: "",
            image: [howItWorks],
        },
        heading: "How To Get Started?",
        inlineText: "It's Quick & Easy",
        subHeadings: [],
        paragraph: ["To Get Started, follow these steps"],
    },
    invOpportunity: {
        background: {
            bg: "",
            image: [],
        },
        heading: "Investment",
        inlineText: "Opportunity",
        rate: "14.51",
        yield: "12.50",
        paragraph: [
            "Residential Society in Pune",
            "Pre-leased Solar Asset",
            "15 Years Long Term Lease",
            "Raising 42 Lakhs for 75 KWp Solar Rooftop Plant",
            "30-60 Month Tenure",
            "Internal Rate of Return 14.51%",
            "Targeted Rental Yield 12.50%",
            "Invest from Rs 10,000 onwards",
        ],
    },
    investment: {
        background: {
            bg: "",
            image: [],
        },
        heading: "Srot Investment",
        inlineText: "Comparison",
        block1: {
            subHeadings: ["Safe", "Diversified", "Higher Returns"],
            paragraph: [
                "With SROT your investment is backed by physical Solar asset",
                "You have fractional ownership of assets",
                "Solar assets are pre-leased by consumers",
                "Solar asset's contracts are secured by 6 months deposit from consumers",
            ],
            pricing: {
                heading: [
                    {
                        id: 1,
                        icon: "",
                        name: "",
                    },
                    {
                        id: 2,
                        icon: circularRupee,
                        name: "SROT Investment",
                    },
                    {
                        id: 3,
                        icon: moneyIcon,
                        name: "Fixed Deposit",
                    },
                ],
                data: [
                    {
                        id: 1,
                        name: "IRR%",
                        investment: "Upto 20%",
                        deposit: "6-8%",
                    },
                    {
                        id: 2,
                        name: "Payouts",
                        investment: "Monthly",
                        deposit: "End of Life",
                    },
                    {
                        id: 3,
                        name: "Volatility",
                        investment: "No",
                        deposit: "No",
                    },
                    {
                        id: 4,
                        name: "Security",
                        investment: "High",
                        deposit: "High",
                    },
                    {
                        id: 5,
                        name: "Liquidity",
                        investment: "Moderate",
                        deposit: "High",
                    },
                ],
            },
        },
        block2: {
            subHeadings: ["Grow", "Your Wealth"],
            heading: "Your Investment of 1 Lakh",
            paragraph: [
                "Will grow with 20% IRR",
                "You will get 2,680 monthly payout",
                "Your investment is backed by 2KWp of solar asset",
                "In 5 years you will get 1.6 Lakhs returns from your investment",
            ],
            img: risingGraph,
        },
    },
};

export default landingData;
