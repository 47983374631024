import React from "react";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { logoWhite } from "../../assets";

import { SHOW_LOGIN_FORM } from "../../constants/contexConstant";
import {
    burgerNavLinks,
    LANDING,
    PROFILE,
    SINGUP,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { getNameAvatar } from "../../helpers/helpers";

import auth from "../../services/auth";
import Button from "../button/Button";
import Image from "../image/Image";
import Link from "../link/Link";
import Text from "../text/Text";

const Overlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    right: 0;
    z-index: 10;
    display: ${(props) => (props.show ? "block" : "none")};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: none;
    }
`;

const StyledMenu = styled.nav`
    background: ${(props) => props.theme.color.main};
    transform: ${({ isBurgerMenuOpen }) =>
        isBurgerMenuOpen ? "translateX(0)" : "translateX(100%)"};
    height: 100vh;
    width: 40vw;
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    display: flex;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 60vw;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 100vw;
    }
`;

const LogoImage = styled(Image)`
    top: 0px;
    left: 20px;
    position: absolute;
    margin-top: 7px;
    width: 130px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 120px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        width: 100px;
    }
`;

const MenuLinkComp = styled.div`
    margin: 20px 0;
`;

const LinkDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    /* margin-bottom: 30px; */
    margin-top: 30px;
`;
const MenuDiv = styled.div`
    margin-top: 55px;
    padding: 0px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 40px;
`;
const Avtar = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
`;
const AvtarIn = styled.div`
    padding: 5px;
`;

const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border: 2px solid ${(props) => props.theme.color.orange};
    box-sizing: border-box;
    color: ${(props) => props.theme.color.orange};
    border-radius: 100%;
    background-color: ${(props) =>
        props.backgroundColor ? props.theme.color[props.backgroundColor] : ""};
    margin-top: ${(props) => props.top || ""};
    margin-bottom: ${(props) => props.bottom || ""};
`;
export const UserAvatar = ({
    backgroundColor = "grey",
    fontSize = "xl",
    size = "100px",
    weight = "normal",
}) => {
    const { state } = useContextState();
    return (
        <Avatar size={size} backgroundColor={backgroundColor}>
            <Text
                color="white"
                weight={weight}
                lineHeight={size}
                size={fontSize}
            >
                {getNameAvatar(state?.user?.name)}
            </Text>
        </Avatar>
    );
};
const MenuLink = ({ name, link, pathname, toggleBurgerMenu }) => (
    <MenuLinkComp>
        <div
            onClick={() => {
                toggleBurgerMenu(false);
            }}
            style={{ display: "block" }}
        >
            <Link
                color={pathname === link ? "orange" : "white"}
                style={{ display: "block" }}
                to={link}
                isInteractive={true}
                size="m"
            >
                {name}
            </Link>
        </div>
    </MenuLinkComp>
);

const BurgerMenu = ({
    links,
    isBurgerMenuOpen,
    toggleBurgerMenu,
    location,
}) => {
    const history = useHistory();

    const handleLoginForm = (e) => {
        e.preventDefault();
        dispatch({ type: SHOW_LOGIN_FORM });
        toggleBurgerMenu(false);
    };
    const { state, dispatch } = useContextState();

    const handleAvatarClick = (e) => {
        e.preventDefault();
        history.push(PROFILE);
        toggleBurgerMenu(false);
    };
    const handleLogout = (e) => {
        e.preventDefault();

        auth.logout(dispatch)
            .then(() => {
                history.push(LANDING);
            })
            .catch(() => {});
    };
    const handleLaunch = (e) => {
        const text = e.target.textContent.toUpperCase();
        history.push(`${SINGUP}?referral_code=${text}`);
        toggleBurgerMenu(false);
    };

    return (
        <React.Fragment>
            <Overlay
                show={isBurgerMenuOpen}
                onClick={() => toggleBurgerMenu(!isBurgerMenuOpen)}
            />
            <StyledMenu isBurgerMenuOpen={isBurgerMenuOpen}>
                <Link to={LANDING}>
                    <LogoImage
                        onClick={() => toggleBurgerMenu(false)}
                        src={logoWhite}
                        width="100%"
                    />
                </Link>

                <MenuDiv>
                    {/* {!state?.user && (
                        <>
                            <Text
                                style={{
                                    fontSize: "1.15rem",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                weight="bold"
                                size="s"
                                color="white"
                            >
                                <InlineText>Get ₹ 100, Use Code</InlineText>
                                <HeaderBtn
                                    type="solid"
                                    color="white"
                                    backgroundColor="orange"
                                    fontSize="m"
                                    fontWeight="bold"
                                    onClick={handleLaunch}
                                    textTransform="uppercase"
                                >
                                    launch
                                </HeaderBtn>
                            </Text>
                            <Line
                                width="100%"
                                height="2px"
                                borderRadius="100px"
                                backgroundColor="white"
                                margin="20px 0 0 0"
                            />
                        </>
                    )} */}

                    {state?.user && (
                        <Avtar
                            role="button"
                            tabIndex="0"
                            onClick={handleAvatarClick}
                        >
                            <AvtarIn>
                                <UserAvatar size="45px" fontSize="m" />
                            </AvtarIn>
                            <Text
                                color="white"
                                size="l"
                                style={{ marginLeft: "3px" }}
                            >
                                {state?.user?.name}
                            </Text>
                        </Avtar>
                    )}

                    <div style={{ margin: "5px 0", width: "100%" }}>
                        {!state?.user ? (
                            <LinkDiv>
                                {burgerNavLinks.map((menuLink, i) => (
                                    <MenuLink
                                        key={i}
                                        name={menuLink.name}
                                        link={menuLink.path}
                                        pathname={location.pathname}
                                        toggleBurgerMenu={toggleBurgerMenu}
                                    />
                                ))}
                            </LinkDiv>
                        ) : (
                            <LinkDiv>
                                {links.map((menuLink, i) => (
                                    <MenuLink
                                        key={i}
                                        name={menuLink.name}
                                        link={menuLink.path}
                                        pathname={location.pathname}
                                        toggleBurgerMenu={toggleBurgerMenu}
                                    />
                                ))}
                            </LinkDiv>
                        )}
                    </div>

                    <Button
                        type="solid"
                        color="white"
                        backgroundColor="primary"
                        size="s"
                        style={{ width: "100%", border: "0" }}
                        onClick={state?.user ? handleLogout : handleLoginForm}
                    >
                        {state?.user ? "Logout" : " LogIn"}
                    </Button>
                </MenuDiv>
            </StyledMenu>
        </React.Fragment>
    );
};

export default withRouter(BurgerMenu);
