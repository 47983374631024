import React, { useEffect, useState } from "react";

import { AiOutlineArrowRight } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ModalAlt from "../../components/modal/ModalAlt";
import Space from "../../components/space/Space";
import { InlineText } from "../../components/text/HeadingText";
import Text from "../../components/text/Text";
import {
    BANK_VERIFICATION_POST_MSG,
    DOCMENT_UPLOAD_MSG,
    ERROR_MSG,
    validFileTypes,
} from "../../constants/common";
import { SET_ERROR } from "../../constants/contexConstant";
import {
    aadharRegEx,
    addressRegEx,
    emailRegEx,
    letterNumberRegEx,
    panRegEx,
    pinRegEx,
} from "../../constants/regularExpression";
import {
    BANK_VERIFICATION_ENDPOINT,
    DOCUMENT_UPLOAD_ENDPOINT,
    PROJECTS,
    UPDATE_EMAIL_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validBankInfo } from "../../helpers/fieldValidation";
import useAuthHistory from "../../hooks/useAuthHistory";
import BottomLineEmailInput from "../../profile/BottomLineEmailInput";
import BottomLineInput from "../../profile/BottomLineInput";
import { CheckBox } from "../../profile/DocumentInfo";
import UploadImage from "../../profile/UploadImage";
import auth from "../../services/auth";
import request from "../../services/request";

const QuickKYComplete = (props) => {
    const { state, dispatch } = useContextState();
    const { openKycModal, onClose, onToggleKyc } = props;
    const { setPath } = useAuthHistory();
    const { id } = useParams();
    let user = state.user;

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await auth.getUserProfile(dispatch);
        }, 10000);

        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        if (user !== state.user) {
            // User state has changed
            user = state.user;
            // Add additional logic if needed
        }
    }, [state.user, user]);
    const isKycCompleted =
        user?.kycTableDisplay?.isAadharUploaded === true &&
        user?.kycTableDisplay?.isPanUploaded === true &&
        user?.kycTableDisplay?.aadharNumber !== null &&
        user?.kycTableDisplay?.panNumber !== null;

    const [activeStep, setActiveStep] = useState(isKycCompleted ? 2 : 1);

    const [loading, setLoading] = useState(false);
    const [loadingEmailUpdate, setLoadingEmailUpdate] = useState(false);
    const [isChangeEmail, setIsChangeEmail] = useState(false);

    const [preview, setPreview] = useState({
        panFront: "",
        aadharFront: "",
        aadharBack: "",
    });
    const [error, setError] = useState({});
    const [oldEmail] = useState(user?.email);
    const [toc, setToc] = useState(false);

    const [formValues, setFormValues] = useState({
        email: user?.email,
        panNumber: "",
        aadharNumber: "",
        address: "",
        city: "",
        state: "",
        pin: "",
        panFront: null,
        aadharFront: null,
        aadharBack: null,
    });

    const handleEmailUpdateModal = (data) => {
        if (data === "YES") {
            // @TODO ==>   update email call and close modal Sun Jul  09
            onToggleKyc();
            setIsChangeEmail(true);
        } else {
            setIsChangeEmail(false);
            onToggleKyc();
        }
    };

    const handleOnChange = (e) => {
        const selectedFile = e.target.files[0];
        const fileName = selectedFile.name;
        const name = e.target.name;
        const addDot = fileName.length > 10 ? "..." : "";
        var extName = fileName.substring(fileName.lastIndexOf(".") + 1);
        if (validFileTypes.includes(extName)) {
            setPreview({
                ...preview,
                [name]: `${fileName.slice(0, 5)}${addDot}.${extName}`,
            });
            setError({ ...error, [name]: "" });
            setFormValues({ ...formValues, [name]: selectedFile });
        } else {
            setError({ ...error, [name]: "Invalid File Types!" });
            setPreview({ ...preview, [name]: "" });
            setFormValues({ ...formValues, [name]: "" });
        }
    };

    const clearInputFile = (name) => {
        setPreview({ ...preview, [name]: "" });
        setFormValues({ ...formValues, [name]: "" });
    };

    const handleChangeDetails = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormValues({ ...formValues, [name]: value });
    };

    const isPanValid =
        formValues.panNumber &&
        panRegEx.test(formValues.panNumber) &&
        !error.panNumber &&
        formValues?.panFront &&
        !error.panFront;

    const isAadharValid =
        formValues.aadharNumber &&
        aadharRegEx.test(formValues.aadharNumber) &&
        !error.aadharNumber &&
        formValues?.aadharNumber &&
        formValues.aadharFront &&
        formValues.aadharBack &&
        !error.aadharFront &&
        !error.aadharBack;

    const isAddressValid =
        formValues.address &&
        addressRegEx.test(formValues.address.replace(/\s/g, "")) &&
        formValues.city &&
        letterNumberRegEx.test(formValues.city) &&
        formValues.state &&
        letterNumberRegEx.test(formValues.state) &&
        formValues.pin &&
        pinRegEx.test(formValues.pin.replace(/\s/g, ""));

    const isValidEmail =
        formValues.email &&
        emailRegEx.test(formValues.email) &&
        user?.isEmailConfirmed &&
        oldEmail === formValues.email;

    const handleUpdateEmail = async () => {
        const body = {
            old_email: user?.email,
            new_email: formValues.email,
        };
        try {
            setLoadingEmailUpdate(true);
            await request.authPut({ endpoint: UPDATE_EMAIL_ENDPOINT, body });
            toast.success(
                "Email has been updated, you will be logged out shortly!"
            );
            setLoadingEmailUpdate(false);
            setIsChangeEmail(false);
            setTimeout(async () => {
                await auth.logout(dispatch);
                // history.push(LANDING);
                // @TODO ==>   setPath Call Sun Jul  09
                setPath(`${PROJECTS}/${id}`);
            }, 2000);
        } catch (error) {
            setLoadingEmailUpdate(false);
            toast.error(typeof error === "string" ? error : ERROR_MSG);
            setIsChangeEmail(false);
        }
    };

    const disableButton =
        !toc ||
        !isAadharValid ||
        !isPanValid ||
        !isValidEmail ||
        !isAddressValid ||
        loading;

    const handleUploadData = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        setLoading(true);

        formData.append("pan", formValues.panFront);
        formData.append("aadhar_front", formValues.aadharFront);
        formData.append("aadhar_back", formValues.aadharBack);
        formData.append("aadhar_number", formValues.aadharNumber);
        formData.append("pan_number", formValues.panNumber);
        formData.append("address", formValues.address);
        formData.append("city", formValues.city);
        formData.append("state", formValues.state);
        formData.append("pinCode", formValues.pin);
        try {
            await request.authPost({
                endpoint: DOCUMENT_UPLOAD_ENDPOINT,
                body: formData,
                isFormData: true,
            });

            auth.getUserProfile(dispatch);

            setLoading(false);
            toast.success(DOCMENT_UPLOAD_MSG);
            setActiveStep(2);
        } catch (error) {
            setLoading(false);
            dispatch({
                type: SET_ERROR,
                payload: error || ERROR_MSG,
            });
        }
    };

    return (
        <>
            <ModalAlt
                topPadding="50px"
                isOpen={openKycModal}
                onClose={onClose}
                innerTimes={false}
                title={activeStep === 1 ? "Personal Details" : "Bank Details"}
                width={["600px", "100%", "100%"]}
            >
                {activeStep === 1 ? (
                    <div>
                        <div>
                            <Flex.Container alignItems="center">
                                <Space margin={["0 .8rem 0 0"]}>
                                    <Icon
                                        size="m"
                                        component={FaCheckCircle}
                                        color={
                                            isValidEmail
                                                ? "lightGreen"
                                                : "grey350"
                                        }
                                    />
                                </Space>
                                <Text weight="700">Email</Text>{" "}
                                <Status
                                    bg={isValidEmail ? "lightGreen" : "danger"}
                                    size="xs"
                                >
                                    {isValidEmail ? "Verified" : "Not Verified"}
                                </Status>
                            </Flex.Container>
                            <BottomLineEmailInput
                                placeholder="admin@hypersrot.com"
                                margin="0 0 1rem "
                                onChange={handleChangeDetails}
                                name="email"
                                email={formValues.email}
                                error={error.email}
                                oldEmail={oldEmail}
                                onClick={() => handleEmailUpdateModal("YES")}
                            />
                        </div>
                        {/* Email Ended */}

                        <InputWithLabel
                            margin="0 0 1rem "
                            placeholder="PAN Number"
                            label="PAN Number"
                            value={formValues.panNumber}
                            onChange={handleChangeDetails}
                            name="panNumber"
                            isValid={isPanValid}
                            error={error.panNumber}
                        />
                        <Space margin={["0 0 1rem"]}>
                            <div>
                                <UploadImage
                                    label="Upload PAN"
                                    onChange={handleOnChange}
                                    name="panFront"
                                    media={preview.panFront}
                                    onClick={clearInputFile}
                                    errorMessage={error.panFront}
                                />
                            </div>
                        </Space>

                        <InputWithLabel
                            margin="0 0 1rem "
                            placeholder="Adhar Number "
                            label="Adhar Number"
                            value={formValues.aadharNumber}
                            onChange={handleChangeDetails}
                            name="aadharNumber"
                            isValid={isAadharValid}
                            error={error.aadharNumber}
                        />
                        <Space margin={["0 0 1rem"]}>
                            <Flex.Container justifyContent="space-between">
                                <UploadImage
                                    onChange={handleOnChange}
                                    name="aadharFront"
                                    media={preview.aadharFront}
                                    onClick={clearInputFile}
                                    label="Aadhar Front"
                                    errorMessage={error.aadharFront}
                                />
                                <UploadImage
                                    label="Adhar Back"
                                    onChange={handleOnChange}
                                    name="aadharBack"
                                    media={preview.aadharBack}
                                    onClick={clearInputFile}
                                    errorMessage={error.aadharBack}
                                />
                            </Flex.Container>
                        </Space>

                        <InputWithLabel
                            margin="0 0 .5rem "
                            placeholder="Enter your Address "
                            label="Address"
                            value={formValues.address}
                            name="address"
                            onChange={handleChangeDetails}
                            error={error.address}
                            isValid={isAddressValid}
                        />
                        <BottomLineInput
                            placeholder="City"
                            margin="0 0 .5rem"
                            name="city"
                            value={formValues.city}
                            error={error.city}
                            onChange={handleChangeDetails}
                        />
                        <Flex.Container justifyContent="space-between">
                            <Flex.Item
                                width="45%"
                                tabletWidth="48%"
                                mobileWidth="100%"
                            >
                                <BottomLineInput
                                    placeholder="State"
                                    margin="0 0 .5rem"
                                    value={formValues.state}
                                    name="state"
                                    onChange={handleChangeDetails}
                                    error={error.state}
                                />
                            </Flex.Item>
                            <Flex.Item
                                width="45%"
                                tabletWidth="48%"
                                mobileWidth="100%"
                            >
                                <BottomLineInput
                                    margin="0 0 .5rem"
                                    placeholder="PIN"
                                    value={formValues.pin}
                                    name="pin"
                                    onChange={handleChangeDetails}
                                    error={error.pin}
                                />
                            </Flex.Item>
                        </Flex.Container>

                        <Space margin={["1rem 0 0"]}>
                            <Flex.Container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <FlexItemFlex flex="1">
                                    <CheckBox
                                        value={toc}
                                        onChange={() => setToc((toc) => !toc)}
                                        type="checkbox"
                                    />{" "}
                                    <Text size="xs">
                                        <InlineText
                                            color="lightGreen"
                                            size="s"
                                            style={{ marginRight: "5px" }}
                                        >
                                            I agree,
                                        </InlineText>
                                        Please upload non-password protected
                                        file. <br /> Max file size 3MB
                                    </Text>
                                </FlexItemFlex>
                                <Button
                                    type="solid"
                                    backgroundColor="lightGreen"
                                    onClick={handleUploadData}
                                    disabled={disableButton}
                                >
                                    <Icon
                                        component={AiOutlineArrowRight}
                                        color="white"
                                        size="xl"
                                    />
                                </Button>
                            </Flex.Container>
                        </Space>
                    </div>
                ) : (
                    <BankDetails onSubmit={onClose} user={state.user} />
                )}
            </ModalAlt>
            <ModalAlt
                isOpen={isChangeEmail}
                onClose={handleEmailUpdateModal}
                width={["600px", "85%", "100%"]}
            >
                <Flex.Container
                    style={{
                        width: "100%",
                        background: "white",
                        padding: "2rem",
                        borderRadius: "10px",
                    }}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Text size="l" color="">
                        Are you sure to change email?
                    </Text>
                    <div style={{ marginTop: "1rem" }}>
                        <Button
                            onClick={handleEmailUpdateModal}
                            size="s"
                            style={{ marginRight: "20px" }}
                            color="primary"
                        >
                            No
                        </Button>
                        <Button
                            onClick={handleUpdateEmail}
                            type="solid"
                            size="s"
                            disabled={loadingEmailUpdate || loading}
                            isLoading={loadingEmailUpdate || loading}
                        >
                            Yes
                        </Button>
                    </div>
                </Flex.Container>
            </ModalAlt>
        </>
    );
};

export default QuickKYComplete;

const InputWithLabel = ({
    label,
    onChange,
    name,
    value,
    placeholder,
    isValid,
    margin,
    showCheck = true,
    error,
}) => {
    return (
        <div>
            <Flex.Container alignItems="center">
                {showCheck && (
                    <Space margin={["0 .8rem 0 0"]}>
                        <Icon
                            size="m"
                            component={FaCheckCircle}
                            color={isValid ? "lightGreen" : "grey350"}
                        />
                    </Space>
                )}

                <Text weight="700">{label}</Text>
            </Flex.Container>
            <BottomLineInput
                placeholder={placeholder}
                margin={margin}
                onChange={onChange}
                name={name}
                value={value}
                error={error}
            />
        </div>
    );
};

const BankDetails = ({ onSubmit }) => {
    const { state, dispatch } = useContextState();
    const [loading, setLoading] = useState(false);

    const [details, setDetails] = useState({
        fullName: "",
        ifsc: "",
        accNumber: "",
        tos: false,
    });
    const [error, setError] = useState({});
    const handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDetails({ ...details, [name]: value });
    };

    const handleSubmit = async () => {
        // @TODO -- Details to BackEnd -- Monday,17
        const getValidFormData = validBankInfo(details);
        setError(getValidFormData);

        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                await request.authPost({
                    endpoint: BANK_VERIFICATION_ENDPOINT,
                    body: {
                        accountName: details.fullName,
                        ifsc: details.ifsc,
                        accountNumber: details.accNumber,
                    },
                });

                auth.getUserProfile(dispatch);
                setLoading(false);
                toast.success(BANK_VERIFICATION_POST_MSG);
                onSubmit();
            } catch (err) {
                setLoading(false);
                const checkedErr = typeof err === "string" ? err : ERROR_MSG;
                toast.error(checkedErr);
            }
        }
    };

    return (
        <div>
            <InputWithLabel
                margin="0 0 1.5rem "
                label="Full name"
                placeholder="Enter your full Name"
                showCheck={false}
                value={details.fullName}
                name="fullName"
                onChange={handleOnChange}
                error={error.fullName}
            />

            <InputWithLabel
                margin="0 0 1.5rem "
                placeholder="Enter your account number"
                label="Account Number"
                showCheck={false}
                onChange={handleOnChange}
                name="accNumber"
                value={details.accNumber}
                error={error.accNumber}
            />
            <InputWithLabel
                margin="0 0 1.5rem "
                placeholder="Enter IFSC code"
                label=" IFSC Code"
                showCheck={false}
                onChange={handleOnChange}
                name="ifsc"
                value={details.ifsc}
                error={error.ifsc}
            />

            <Space margin={["1rem 0 0"]}>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex.Item flex="1">
                        <TosWrap>
                            <CheckBox
                                type="checkbox"
                                name="tos"
                                id="tos"
                                value={details.tos}
                                checked={details.tos}
                                onChange={(e) => {
                                    setDetails({
                                        ...details,
                                        tos: !details.tos,
                                    });
                                }}
                            />
                            <Text size="xs">
                                <InlineText
                                    color="lightGreen"
                                    size="s"
                                    style={{ marginRight: "5px" }}
                                >
                                    I agree,
                                </InlineText>
                                I agree, Above bank account belongs to me
                            </Text>
                        </TosWrap>

                        {error.tos && (
                            <Space margin={["2px 0 0 0"]}>
                                <Text align="left" size="xs" color="error">
                                    {error.tos} Error
                                </Text>
                            </Space>
                        )}
                    </Flex.Item>
                    <Button
                        type="solid"
                        color="white"
                        backgroundColor="lightGreen"
                        onClick={handleSubmit}
                        isLoading={loading}
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </Flex.Container>
            </Space>
        </div>
    );
};

const FlexItemFlex = styled(Flex.Item)`
    display: flex;
    flex-wrap: nowrap;
    margin-right: 1rem;
    /* align-items: center; */
    ${CheckBox} {
        height: auto;
        line-height: 1.3em;
        margin-top: 5px;
    }
`;
const TosWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-right: 1rem;
    /* align-items: center; */
    ${CheckBox} {
        height: auto;
        line-height: 1.3em;
        margin-top: 2px;
    }
`;
const Status = styled(Text)`
    display: inline-block;
    background-color: ${(props) => props.theme.color[props.bg]};
    margin-left: 1rem;
    border-radius: 3px;
    padding: 3px 7px;
    margin-top: -5px;
    font-weight: 700;
    letter-spacing: 1px;
    color: ${(props) =>
        props.bg === "danger"
            ? props.theme.color.error
            : props.theme.color.white};
    line-height: 1;
`;
