import styled from "styled-components";
import { webinar } from "../assets";
import Card from "../components/card/Card";

import { useEffect, useState } from "react";
import { FaEnvelopeOpen, FaPhone } from "react-icons/fa";
import { toast } from "react-toastify";
import Icon from "../components/Icon/Icon";
import Space from "../components/Spacing/Space";
import Button from "../components/button/Button";
import DropDownAlt from "../components/dropdown/DropdownAlt";
import { footerHeight } from "../components/footer/Footer";
import { HeaderHeight } from "../components/header/Header";
import Image from "../components/image/Image";
import Input from "../components/input/Input";
import Line from "../components/line/Line";
import ModalAlt from "../components/modal/ModalAlt";
import OtpInput from "../components/otp-input/OtpInput";
import { Flex } from "../components/styles/Elements.styles";
import { H4, P } from "../components/styles/Typography.styles";
import Text from "../components/text/Text";
import { ERROR_MSG } from "../constants/common";
import { SET_ERROR } from "../constants/contexConstant";
import { ENQUIRY_CONTACT_API_ENDPOINT } from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import { validateContactUsEnquiry } from "../helpers/fieldValidation";
import { OTPButton } from "../login/SignupModal";
import auth from "../services/auth";
import request from "../services/request";

export const FooterContainer = styled(Flex.Container)`
    background: ${(props) => props.theme.color.primary};
    padding: 1rem ${(props) => props.theme.spacing.default.horizontal};
`;
export const TextCenter = styled(Text)`
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: ${(props) => props.theme.fontSize.default.s};
    }
`;

const ContainerMain = styled(Flex.Container)`
    padding: calc(${HeaderHeight.smallLaptop} + 80px)
        ${(props) => props.theme.spacing.default.horizontal} 80px;
    background: ${(props) => (props.bg ? `url(${props.bg})` : "")};
    background-size: cover;
    min-height: calc(100vh - ${footerHeight.default});
    max-height: 100%;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        padding: calc(${HeaderHeight.smallLaptop} + 80px)
            ${(props) => props.theme.spacing.default.horizontal} 80px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        align-items: flex-start;
        padding: calc(${HeaderHeight.tablet} + 80px)
            ${(props) => props.theme.spacing.default.horizontal} 80px;
        min-height: calc(100vh - ${footerHeight.tablet});
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding-top: calc(${HeaderHeight.tablet} + 50px);
        min-height: calc(100vh - ${footerHeight.mobile});
        padding-bottom: 50px;
        flex-direction: column;
        justify-content: flex-start;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        min-height: calc(100vh - ${footerHeight.smallMobile});
    }
`;

const ContainerStyled = styled(ContainerMain)`
    padding: calc(${HeaderHeight.smallLaptop} + 35px)
        ${(props) => props.theme.spacing.default.horizontal} 50px;
    @media (max-width: ${(props) => props.theme.breakPoints.smallLaptop}) {
        padding: calc(${HeaderHeight.smallLaptop} + 35px)
            ${(props) => props.theme.spacing.default.horizontal} 50px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        padding: calc(${HeaderHeight.tablet} + 35px)
            ${(props) => props.theme.spacing.default.horizontal} 50px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding-top: calc(${HeaderHeight.tablet} + 50px);
    }
`;

const FlexContainer = styled(Flex.Container)`
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        flex-direction: column-reverse;
    }
`;
const FlexContent = styled(Flex.Item)`
    width: 50%;
    @media (max-width: 1040px) {
        width: 45%;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        width: 100%;
    }
`;
const FlexImage = styled(Flex.Item)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Form = styled.form`
    width: 100%;
    height: 100%;
    min-height: 400px;
    background-color: ${(props) => props.theme.color.grey400};
    padding: 4rem;
`;

const BottomFlexWrap = styled(Flex.Container)`
    background-color: ${(props) => props.theme.color.grey300};
    border-radius: 1rem;
    padding: 1rem 3rem;
    margin-top: 2rem;
    flex-direction: column-reverse;
    .title,
    .sub {
        display: flex;
        gap: 1rem;
    }
    .title {
        margin-bottom: 1rem;
    }
    .middle-wrap {
        margin: 3rem 0;
    }

    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        margin-top: 3rem;
        flex-direction: row-reverse;
        .middle-wrap {
            margin: 0;
        }
        .title,
        .sub {
            gap: 2rem;
        }
        .title {
            margin-bottom: 2rem;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        flex-direction: row;
        margin-top: 4.8rem;
        border-radius: 2rem;
        padding: 3rem 6rem;
        .title,
        .sub {
            gap: 2rem;
        }
        .middle-wrap {
            border: 1px solid ${(props) => props.theme.color.grey650};
            border-top: none;
            border-bottom: none;
            padding: 0 3rem;
        }
    }

    @media (min-width: ${(props) => props.theme.breakPoints.desktop}) {
        .middle-wrap {
            padding: 0 6rem;
        }
    }
`;

const OTP_LENGTH = 6;
const COUNTER_TIME = 30;

const queryData = [
    "Unable to Login or Signup",
    "Not able to Invest",
    "I'm facing issues with Payment",
    "Need help in understanding Srot Platform",
    "Callback I have some questions",
    "Other",
];
let bodyObject = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    query: queryData[0],
    message: "",
    otp: "",
};

const ContactUS = () => {
    const { dispatch } = useContextState();
    const [modalData, setModalData] = useState(null);
    const [isOTP, setIsOTP] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const [counter, setCounter] = useState(0);
    const [formData, setFormData] = useState(bodyObject);
    const [formError, setFormError] = useState({});

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value });
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const updateCounter = () => {
            if (counter === 0) return;
            else {
                let temp = counter - 1;
                setCounter(temp);
            }
        };
        let timer = setInterval(updateCounter, 1000);
        if (counter === 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [counter]);

    const checkMessage = formData.query === queryData[queryData.length - 1];

    const handleGetOTP = async (e) => {
        e.preventDefault();

        const getValidFormData = validateContactUsEnquiry(
            formData,
            checkMessage,
            isOTP
        );

        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            let body = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: `91${formData.phone}`,
                querySource: formData.query,
                queryMessage: checkMessage ? formData.message : formData.query,
            };
            if (isOTP) body.otp = formData.otp;

            try {
                await request.post({
                    endpoint: ENQUIRY_CONTACT_API_ENDPOINT,
                    body: body,
                });

                if (isOTP) {
                    setIsOTP(false);
                    setResendCount(0);
                    setCounter(0);
                    setLoading(false);
                    setModalData({
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                    });
                    setFormData(bodyObject);
                } else {
                    setIsOTP(true);
                    toast.success("OTP has been sent");
                    setResendCount((prev) => prev + 1);
                    setCounter(COUNTER_TIME);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                toast.error(typeof error === "string" ? error : ERROR_MSG);
            }
        }
    };

    const handleResentOTP = async (mode) => {
        const getValidFormData = validateContactUsEnquiry(formData);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);

            try {
                await auth.loginOTP({
                    username: `91${formData.phone}`,
                    type: "retry",
                    mode,
                });
                if (mode === "text") {
                    toast.success("OTP has been sent");
                } else {
                    toast.success("Call has been sent");
                }
                setResendCount((prev) => prev + 1);
                setCounter(COUNTER_TIME * (resendCount + 1));
                setLoading(false);
            } catch (error) {
                setLoading(false);

                if (error === "otp_expired") {
                    setResendCount(0);
                    setCounter(0);
                }
                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        } else {
        }
    };
    return (
        <>
            <ContainerStyled>
                <Flex.Item width="100%">
                    <Text
                        style={{ marginBottom: "20px" }}
                        weight="700"
                        size="xml"
                        color="darkGreen"
                    >
                        Let’s get in touch
                    </Text>
                </Flex.Item>

                <Flex.Item xxs="100%">
                    <Card borderRadius="20px" backgroundColor="grey300">
                        <FlexContainer justify="space-between">
                            <FlexContent>
                                <Form onSubmit={handleGetOTP}>
                                    <H4>
                                        Fill out your details and we’ll get back
                                        to you ASAP!
                                    </H4>
                                    <Space
                                        xxsm={["1rem 0 1.4rem"]}
                                        stm={["1.5rem 0 2rem"]}
                                        lgm={["2rem 0 2.4rem"]}
                                    >
                                        <Line
                                            backgroundColor="grey600"
                                            height="1px"
                                        />
                                    </Space>

                                    <Flex.Container
                                        gap="2rem"
                                        justify="space-between"
                                    >
                                        <Flex.Item xxs="100%" lg="48%" xl="46%">
                                            <Input
                                                disabled={isOTP}
                                                value={formData.firstName}
                                                errorMessage={
                                                    formError.firstNameError
                                                }
                                                onChange={handleChange}
                                                name="firstName"
                                                required
                                                label="First Name"
                                                placeholder="Your first name"
                                            />
                                        </Flex.Item>
                                        <Flex.Item xxs="100%" lg="48%" xl="46%">
                                            <Input
                                                value={formData.lastName}
                                                errorMessage={
                                                    formError.lastNameError
                                                }
                                                disabled={isOTP}
                                                onChange={handleChange}
                                                name="lastName"
                                                required
                                                label="Last Name"
                                                placeholder="Your last name"
                                            />
                                        </Flex.Item>
                                        <Flex.Item xxs="100%" lg="48%" xl="46%">
                                            <Input
                                                disabled={isOTP}
                                                value={formData.email}
                                                errorMessage={
                                                    formError.emailError
                                                }
                                                onChange={handleChange}
                                                name="email"
                                                label="Email"
                                                placeholder="Enter your email"
                                            />
                                        </Flex.Item>
                                        <Flex.Item xxs="100%" lg="48%" xl="46%">
                                            <Input
                                                disabled={isOTP}
                                                value={formData.phone}
                                                errorMessage={
                                                    formError.phoneError
                                                }
                                                onChange={handleChange}
                                                name="phone"
                                                required
                                                label="Phone Number"
                                                placeholder="Enter phone number"
                                            />
                                        </Flex.Item>
                                        <Flex.Item xxs="100%">
                                            <DropDownAlt
                                                disabled={isOTP}
                                                required
                                                label={"Query"}
                                                selected={formData.query}
                                                data={queryData}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        query: e,
                                                        message: "",
                                                    });
                                                }}
                                            />
                                        </Flex.Item>

                                        {checkMessage && (
                                            <Flex.Item xxs="100%">
                                                <Input
                                                    disabled={isOTP}
                                                    value={formData.message}
                                                    errorMessage={
                                                        formError.messageError
                                                    }
                                                    onChange={handleChange}
                                                    name="message"
                                                    label="Message"
                                                    required
                                                    componentType="textarea"
                                                    placeholder="Explain your query"
                                                />
                                            </Flex.Item>
                                        )}
                                        {isOTP && (
                                            <Space>
                                                <Flex.Item xxs="100%">
                                                    <OtpInput
                                                        label={false}
                                                        value={formData.otp}
                                                        errorMessage={
                                                            formError.otpError
                                                        }
                                                        shadow={false}
                                                        onChange={handleChange}
                                                        size={OTP_LENGTH}
                                                        isInputNum={true}
                                                    />
                                                    <Flex.Container
                                                        justify="space-evenly"
                                                        alignItems="center"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {counter === 0 ? (
                                                            <>
                                                                <OTPButton
                                                                    onClick={() =>
                                                                        handleResentOTP(
                                                                            "text"
                                                                        )
                                                                    }
                                                                    role="button"
                                                                    size="s"
                                                                    color="primary"
                                                                    weight="700"
                                                                >
                                                                    Get OTP Text
                                                                </OTPButton>
                                                                <OTPButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handleResentOTP(
                                                                            "voice"
                                                                        )
                                                                    }
                                                                    role="button"
                                                                    weight="700"
                                                                    size="s"
                                                                >
                                                                    Get OTP on
                                                                    Call
                                                                </OTPButton>
                                                            </>
                                                        ) : (
                                                            <P
                                                                weight="b"
                                                                color="primary"
                                                                size="s"
                                                            >
                                                                Resend OTP in{" "}
                                                                {counter}s
                                                            </P>
                                                        )}
                                                    </Flex.Container>
                                                </Flex.Item>
                                            </Space>
                                        )}

                                        <Flex.Item xxs="100%">
                                            <Button
                                                backgroundColor="primary"
                                                fullWidth
                                                type="submit"
                                                isLoading={loading}
                                                disabled={loading}
                                                onClick={handleGetOTP}
                                            >
                                                {isOTP ? "Submit" : "Get OTP"}
                                            </Button>
                                        </Flex.Item>
                                    </Flex.Container>
                                </Form>
                            </FlexContent>

                            <FlexImage>
                                <Space
                                    xlp={["50px 50px 40px 50px"]}
                                    tp={["35px 35px 25px 35px"]}
                                    xxsp={["20px 20px 15px 20px"]}
                                >
                                    <Image
                                        style={{ boxSizing: "border-box" }}
                                        src={webinar}
                                        width="100%"
                                    />
                                </Space>
                            </FlexImage>
                        </FlexContainer>
                    </Card>
                </Flex.Item>

                <Flex.Item xxs="100%" st="100%" t="100%" xl="100%">
                    <BottomFlexWrap justify="space-between">
                        <Space stm={["0 0 2rem"]} xlm={["0"]}>
                            <Flex.Item
                                xxs="100%"
                                st="100%"
                                t="100%"
                                xl="30%"
                                xxl="20%"
                            >
                                <P
                                    fontFamily="Montserrat"
                                    weight="b"
                                    className="title"
                                >
                                    <Icon component={FaPhone} /> +91 9970079570
                                </P>{" "}
                                <P
                                    fontFamily="Montserrat"
                                    weight="b"
                                    className="sub"
                                >
                                    <Icon component={FaEnvelopeOpen} />{" "}
                                    invest@srot.app
                                </P>
                            </Flex.Item>
                        </Space>

                        <Flex.Item
                            className="middle-wrap"
                            xxs="100%"
                            st="48%"
                            t="45%"
                            xl="30%"
                            xxl="35%"
                        >
                            <P weight="b" className="title">
                                General query
                            </P>
                            <P style={{ display: "flex" }}>
                                Trouble with Srot Platform? <br/><br/> Reach us at contact@srot.app and
                                we will get back to you shortly.
                            </P>
                        </Flex.Item>
                        <Flex.Item
                            className="last-wrap"
                            xxs="100%"
                            st="45%"
                            t="45%"
                            xl="30%"
                            xxl="35%"
                        >
                            <P weight="b" className="title">
                                Investor query
                            </P>
                            <P style={{ display: "flex" }}>
                                Have questions about investment? <br/><br/>
                                Reach us at invest@srot.app and we will get back to you
                                shortly.
                                
                            </P>
                        </Flex.Item>
                    </BottomFlexWrap>
                </Flex.Item>
            </ContainerStyled>
            <ModalAlt
                width={["100%", "600px", "600px"]}
                innerTimes={false}
                isOpen={modalData}
                onClose={() => setModalData(null)}
            >
                <P align="center">
                    Dear {modalData?.firstName} {modalData?.lastName}, we have
                    recorded your query. Our team will reach out to you in next
                    24hrs.
                </P>

                <Space xxsm={["2rem 0 0"]}>
                    <P weight="b" align="center">
                        Thanks for using srot platform.
                    </P>
                </Space>
            </ModalAlt>
        </>
    );
};

export default ContactUS;
