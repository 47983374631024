import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../components/button/Button";
import Flex from "../components/flex/Flex";
import Input from "../components/input/Input";
import Line from "../components/line/Line";
import ModalAlt from "../components/modal/ModalAlt";
import Space from "../components/space/Space";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
import { ALREADY_JOINED_WAITING_LIST, ERROR_MSG } from "../constants/common";
import {
    RESET_JOINABLE_WAITING_LIST,
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
    UPDATE_JOINABLE_WAITING_LIST,
} from "../constants/contexConstant";
import { decimalNumberRegEx } from "../constants/regularExpression";
import {
    PROJECTS,
    WAITING_LIST_JOIN_API_ENDPOINT,
    WAITING_LIST_STATUS_API_ENDPOINT,
} from "../constants/routes";
import { useContextState } from "../context/ContextProvider";
import {
    getCurrencyAmount,
    getErrorMessage,
    getPercentage,
    getURLQueryParams,
    numberWithCommas,
} from "../helpers/helpers";
import useAuthHistory from "../hooks/useAuthHistory";
import request from "../services/request";
import { TagItem, TagsContainer } from "./Tag";

const CardAltWrap = styled.div`
    min-height: 600px;
    .overly {
        width: 100%;
        height: ${(props) => (props.isEmpty ? "350px" : "300px")};
        position: relative;
        .contentWrapper {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.58);
            padding: 0.8rem;
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                .highlight {
                    background-color: ${(props) => props.theme.color.primary};
                    padding: 0 1rem;
                    border-radius: 0.5rem;
                    display: inline-flex;
                }
            }
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .cardBody {
        background-color: ${(props) => props.theme.color.black800};
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const CardComingSoon = (props) => {
    const {
        name,
        tagItem,
        startDate,
        id,
        thumbnail,
        listingMaxLimit,
        listingMinLimit,
        fundSize,
        waitingList,
    } = props?.data || {};

    const {
        state: { user, joinWaitingList },
        dispatch,
    } = useContextState();
    const { setPath } = useAuthHistory();
    const location = useLocation();
    const query = getURLQueryParams(location, "amount");
    const queryAmount = Number(query) || "";

    const [joinLoading, setJoinLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [amount, setAmount] = useState(queryAmount);
    const [amountErr, setAmountError] = useState("");

    const [ownInterest, setOwnInterest] = useState(
        Number(waitingList?.ownInterest)
    );
    const [shownInterest, setShownInterest] = useState(
        Number(waitingList?.shownInterest)
    );
    const [userInterested, setUserInterested] = useState(
        Number(waitingList?.userInterested)
    );

    useEffect(() => {
        setOwnInterest(Number(waitingList?.ownInterest));
        setShownInterest(Number(waitingList?.shownInterest));
        setUserInterested(Number(waitingList?.userInterested));
    }, [waitingList]);

    const comingDate = moment(startDate).diff(moment(), "days");

    const handleChange = (e) => {
        const value = `${e.target.value}`.replace(/\,/g, "");
        if (decimalNumberRegEx.test(value)) {
            setAmount(value);
            handleInputValue(value);
        } else {
            setAmount(amount);
            handleInputValue(amount);
        }
    };

    const handleInputValue = (value) => {
        if (isNaN(value) || Number(value) < listingMinLimit) {
            setAmountError(
                `Minimum value is ${listingMinLimit.toLocaleString()}`
            );
        } else if (Number(value) > listingMaxLimit) {
            setAmountError(
                `Maximum amount is ${listingMaxLimit.toLocaleString()}`
            );
        } else {
            setAmountError("");
        }
    };

    const handleCloseModal = () => {
        setAmount("");
        setIsOpenModal(false);
        dispatch({ type: RESET_JOINABLE_WAITING_LIST });
    };

    const handleUserAuthenticationCheck = () => {
        if (user === null) {
            dispatch({ type: UPDATE_JOINABLE_WAITING_LIST, payload: id });
            setPath(PROJECTS);
        } else {
            setIsOpenModal(true);
        }
    };

    const waitingListStatus = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });
            let res = await request.authGet({
                endpoint: `${WAITING_LIST_STATUS_API_ENDPOINT}/${joinWaitingList}`,
            });
            let ownInterestData = res?.data?.data?.ownInterest
                ? Number(res?.data?.data?.ownInterest)
                : 0;

            dispatch({ type: RESET_LOADER });
            if (ownInterestData >= listingMinLimit) {
                dispatch({
                    type: SET_ERROR,
                    payload: ALREADY_JOINED_WAITING_LIST,
                });

                setIsOpenModal(false);
            } else {
                setIsOpenModal(true);
            }
            dispatch({ type: RESET_JOINABLE_WAITING_LIST });
        } catch (error) {
            toast.error(getErrorMessage(error));
            dispatch({ type: RESET_LOADER });
            dispatch({ type: RESET_JOINABLE_WAITING_LIST });
            setIsOpenModal(false);
        }
    }, [dispatch, joinWaitingList, listingMinLimit]);

    useEffect(() => {
        if (
            user !== null &&
            joinWaitingList !== null &&
            joinWaitingList === id
        ) {
            waitingListStatus();
        }
    }, [id, joinWaitingList, user, waitingListStatus]);

    const handleJoinWaitingList = () => {
        setJoinLoading(true);
        let endpoint = WAITING_LIST_JOIN_API_ENDPOINT;
        const body = {
            listingId: id,
            amount: Number(amount),
        };
        const promise = request.authPost({ endpoint, body });
        promise
            .then((res) => {
                setOwnInterest(Number(res?.data?.amount));
                setShownInterest(shownInterest + Number(res?.data?.amount));
                setUserInterested(userInterested + 1);
                setJoinLoading(false);
                toast.success(
                    "Thanks for showing interest, you have joined waiting list successfully !!"
                );
                handleCloseModal();
            })
            .catch((err) => {
                const checkedErr = typeof err === "string" ? err : ERROR_MSG;
                toast.error(checkedErr);
                setJoinLoading(false);
            });
    };

    const getProgress = () => {
        let progress = `${getPercentage(fundSize, shownInterest)}%`;
        return (
            <Progress className="progressWrapper" width={progress}>
                <div className="line" />
            </Progress>
        );
    };
    return (
        <>
            <CardAltWrap
                className="card"
                style={{ display: "flex", flexDirection: "column" }}
                isEmpty={shownInterest === 0}
            >
                <div className="overly">
                    <img src={thumbnail} alt={name} />
                    <div className="contentWrapper">
                        <div className="content">
                            <Flex.Container
                                justifyContent="flex-end"
                                direction="column"
                                alignItems="flex-end"
                            >
                                <Text
                                    size="m"
                                    color="white"
                                    fontFamily="Montserrat"
                                    weight="700"
                                    align="right"
                                    className="highlight"
                                >
                                    COMING SOON
                                </Text>
                                <Text
                                    size="s"
                                    color="white"
                                    fontFamily="Montserrat"
                                    weight="700"
                                    align="right"
                                >
                                    in {comingDate}{" "}
                                    {comingDate > 1 ? " days" : " day"}
                                </Text>
                            </Flex.Container>
                            <div>
                                <Text
                                    size="m"
                                    color="white"
                                    fontFamily="Montserrat"
                                    weight="700"
                                    align="right"
                                >
                                    {getCurrencyAmount(fundSize)}
                                </Text>
                                <Text
                                    size="m"
                                    color="white"
                                    fontFamily="Montserrat"
                                    weight="700"
                                    align="right"
                                    lineHeight="1.2em"
                                >
                                    Fund Size
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cardBody">
                    <Space margin={["1rem .8rem 0"]}>
                        <TagsContainer borderRadius=".5rem" bg="grey300">
                            {tagItem.map((item) => (
                                <TagItem
                                    key={item.id}
                                    name={item.name}
                                    value={item.value}
                                    unit={item.unit}
                                    size="s"
                                    sizeS="xs"
                                    margin=".5rem 0"
                                />
                            ))}
                        </TagsContainer>
                    </Space>
                    <Space margin={["1rem 2rem"]}>
                        {ownInterest >= listingMinLimit ? (
                            <Button
                                type="solid"
                                size="s"
                                disabled
                                backgroundColor="cyan"
                            >
                                Subscribed
                            </Button>
                        ) : (
                            <Button
                                type="solid"
                                size="s"
                                backgroundColor="cyan"
                                onClick={handleUserAuthenticationCheck}
                            >
                                Subscribe
                            </Button>
                        )}
                    </Space>
                    {shownInterest !== 0 ? (
                        <div>
                            {getProgress()}

                            <Footer>
                                <Flex.Container
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <Flex.Item
                                        width="40%"
                                        tabletWidth="40%"
                                        mobileWidth="40%"
                                    >
                                        <div className="textContent">
                                            <InlineText
                                                weight="700"
                                                lineHeight="1.3em"
                                                size="m"
                                                color="white"
                                            >
                                                {userInterested}
                                            </InlineText>
                                            <Text
                                                size="xs"
                                                lineHeight="1.3em"
                                                color="white"
                                            >
                                                {userInterested > 1
                                                    ? "Investors are interested"
                                                    : "Investor are interested"}
                                            </Text>
                                        </div>
                                    </Flex.Item>
                                    <Line
                                        margin="5px"
                                        width="2px"
                                        height="30px"
                                        backgroundColor="white"
                                    />
                                    <Flex.Item
                                        width="40%"
                                        tabletWidth="40%"
                                        mobileWidth="40%"
                                    >
                                        <div className="textContent">
                                            <InlineText
                                                weight="700"
                                                lineHeight="1.3em"
                                                size="m"
                                                color="white"
                                            >
                                                {getCurrencyAmount(
                                                    shownInterest
                                                )}
                                            </InlineText>
                                            <Text
                                                size="xs"
                                                lineHeight="1.3em"
                                                color="white"
                                            >
                                                Shown interest
                                            </Text>
                                        </div>
                                    </Flex.Item>
                                </Flex.Container>
                            </Footer>
                        </div>
                    ) : null}
                </div>
            </CardAltWrap>
            <ModalAlt
                isOpen={isOpenModal}
                onClose={handleCloseModal}
                backgroundColor="primary"
                innerTimes={false}
                width={["50%", "100%", "100%"]}
            >
                <Space margin={["1rem 0 3rem"]}>
                    <Text size="xl" align="center" color="white">
                        Join Waiting List
                    </Text>
                </Space>
                <Flex.Container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex.Item flex="1" tabletWidth="100%" mobileWidth="100%">
                        <Text color="white" weight="bold" size="m">
                            Amount
                        </Text>
                    </Flex.Item>
                    <Flex.Item
                        width="65%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <Input
                            onChange={handleChange}
                            backgroundColor="white"
                            fontFamily="Montserrat"
                            placeholder="Enter amount "
                            value={numberWithCommas(amount)}
                            errorMessage={amountErr}
                            errorType="alert"
                        />
                    </Flex.Item>
                </Flex.Container>
                <Space margin={["3rem 0 1rem"]}>
                    <Flex.Container justifyContent="center">
                        <Flex.Item
                            width="50%"
                            tabletWidth="50%"
                            mobileWidth="100%"
                        >
                            <Button
                                type="solid"
                                fullWidth
                                backgroundColor="cyan"
                                disabled={
                                    amountErr ||
                                    isNaN(amount) ||
                                    joinLoading ||
                                    !amount
                                }
                                isLoading={joinLoading}
                                onClick={handleJoinWaitingList}
                            >
                                Join
                            </Button>
                        </Flex.Item>
                    </Flex.Container>
                </Space>
            </ModalAlt>
        </>
    );
};

const Footer = styled.div`
    background-color: ${(props) => props.theme.color.primary};
    .textContent {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        ${InlineText} {
            margin-right: 5px;
        }
    }
`;

const Progress = styled.div`
    background-color: ${(props) => props.theme.color.lightGrey};
    .line {
        background-color: ${(props) => props.theme.color.cyan};
        width: ${(props) => props.width};
        height: 5px;
    }
`;

export default CardComingSoon;
