import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Space from "../../components/Spacing/Space";
import AlertNew from "../../components/alert/AlertNew";
import BulletList from "../../components/bullet-list/BulletList";
import { FullCarousel } from "../../components/carousel/Carousel";
import { Flex } from "../../components/styles/Elements.styles";
import { H2, Span } from "../../components/styles/Typography.styles";
import { OPPORTUNITY_ENDPOINT, PROJECTS } from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useNetwork from "../../hooks/useNetwork";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getProjectData } from "../../projects/OurProjects/OurProjects";
import ProjectCard from "../../projects/ProjectCard";

const Container = styled.div`
    margin-bottom: 100px;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        margin-bottom: 50px;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        margin-bottom: 50px;
    }
`;

const FUNDING = "FUNDING";

const getBulletListData = (listing) => {
    return [
        {
            id: 1,
            name: `Residential Society in ${listing?.city}, ${listing?.state}`,
        },
        {
            id: 2,
            name: `Pre-leased Solar Asset with ${listing?.details[2].value} Lease Plan`,
        },
        {
            id: 3,
            name: `Raising ${listing?.details[0]?.value} for ${listing.tagItem[0].value} KWp Solar Rooftop Plant`,
        },
        {
            id: 4,
            name: `Investment Term of ${listing?.details[2].value} `,
        },
        {
            id: 5,
            name: `Investment Internal Rate of Return ${listing?.details[3].value}`,
        },
        {
            id: 6,
            name: `Invest from Rs ${listing?.details[4].value} onwards`,
        },
    ];
};

const InvOpportunity = ({ data, id, visibilityHandler }) => {
    const { width } = useWindowDimensions();
    const opportunityRef = useRef();

    useEffect(() => {
        let target = opportunityRef.current;
        visibilityHandler && visibilityHandler.observe(target);
    }, [opportunityRef, visibilityHandler]);

    /* @DESC:: newly added data @habib610 Thu June 27,2024 */
    const { state } = useContextState();

    const {
        error,
        data: projectData,
        loading,
    } = useNetwork(OPPORTUNITY_ENDPOINT, null, null, state.user !== null);

    let content = null;

    if (!loading && error) {
        content = (
            <Flex.Container justify="center">
                <AlertNew backgroundColor="danger" color="error">
                    {error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (!loading && !error && data?.data?.assets?.length === 0) {
        content = (
            <Flex.Container justify="center">
                <AlertNew backgroundColor="primary" color="white">
                    Projects Not Available
                </AlertNew>
            </Flex.Container>
        );
    }
    let listItem = [];
    if (!loading && !error && projectData?.data?.assets?.length > 0) {
        listItem = projectData?.data?.assets

            .filter(
                (item) =>
                    item.listingStatus === FUNDING &&
                    Date.now() > item?.startDate * 1000
            )
            .sort((a, b) => a.listingId - b.listingId)
            .sort((a, b) => a.startDate - b.startDate);
        content = (
            <Space xxsm={["4rem 0 "]} lgm={["4rem 0 8rem"]}>
                <div>
                    <FullCarousel>
                        {listItem.map((item) => {
                            let listingValues = getProjectData(item);

                            let reducedDetailsListing = {
                                ...listingValues,
                                details:
                                    width >= 1536
                                        ? listingValues.details
                                        : listingValues.details.slice(0, 2),
                            };

                            return (
                                <Flex.Container
                                    align="center"
                                    justify="space-around"
                                >
                                    <Flex.Item xxs="100%" lg="48%" xl="45%">
                                        <div>
                                            <Space
                                                xxsm={["0 0 2rem"]}
                                                lgm={["0 0 2.5rem"]}
                                                xlm={["0 0 3rem"]}
                                            >
                                                <H2
                                                    color="grey"
                                                    textTransform="capitalize"
                                                    align="left"
                                                >
                                                    {data.heading}{" "}
                                                    <Span color="lightGrey">
                                                        {data.inlineText}
                                                    </Span>
                                                </H2>
                                            </Space>

                                            {getBulletListData(
                                                listingValues
                                            ).map((item) => (
                                                <BulletList
                                                    key={item.id}
                                                    title={item.name}
                                                    color="darkGrey"
                                                />
                                            ))}
                                        </div>
                                    </Flex.Item>
                                    <CardFlex
                                        xxs="100%"
                                        style={{ padding: "2rem " }}
                                        lg="48%"
                                        xl="45%"
                                    >
                                        <ProjectCard
                                            data={reducedDetailsListing}
                                            link={`${PROJECTS}/${item.listingId}`}
                                        />
                                    </CardFlex>
                                </Flex.Container>
                            );
                        })}
                    </FullCarousel>
                </div>
            </Space>
        );
    }

    return (
        <Space xxsm={["0 0 4rem"]} lgm={["0 0 5rem"]} xlm={["0 0 8rem"]}>
            <Container
                ref={opportunityRef}
                id={id}
                data-title="INVESTMENT OPPORTUNITY"
            >
                {content}
            </Container>
        </Space>
    );
};

export default InvOpportunity;

const CardFlex = styled(Flex.Item)`
    .card {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        overflow: hidden;
    }
`;
