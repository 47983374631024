import { BsXCircleFill } from "react-icons/bs";
import styled from "styled-components";
import Flex from "../components/flex/Flex";
import Image from "../components/image/Image";
import { InlineText } from "../components/text/HeadingText";
import Text from "../components/text/Text";
const EscapeBtn = styled(InlineText)`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        font-size: 1.4rem;
    }
`;

const Heading = styled(Text)`
    display: block;
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: 1.4rem;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: none;
    }
`;
const FlexItem = styled(Flex.Item)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoPlayerHeader = ({ title, logo, escape, onClick }) => {
    return (
        <Flex.Container
            style={{ paddingBottom: ".54rem " }}
            justifyContent="end"
            alignItems="center"
        >
            <FlexItem flex="1">
                {title && (
                    <Heading size="l" color="white" align="center">
                        {title}
                    </Heading>
                )}
                <Image
                    src={logo}
                    alt="srot_logo_green"
                    height="3.4rem"
                    tabletHeight="3.2rem"
                    mobileHeight="2.3rem"
                    style={{ margin: "0 10px" }}
                />
            </FlexItem>
            {escape && (
                <Flex.Item>
                    <EscapeBtn
                        onClick={onClick}
                        role="button"
                        size="xl"
                        color="white"
                    >
                        <BsXCircleFill />
                    </EscapeBtn>
                </Flex.Item>
            )}
        </Flex.Container>
    );
};
export default VideoPlayerHeader;
