import { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { MOBILE_BREAKPOINT } from "../../theme";
import { P } from "../styles/Typography.styles";
import { InlineText } from "../text/HeadingText";
import Text from "../text/Text";

const DropWrapper = styled.div`
    position: relative;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    :hover {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }
    border-radius: ${(props) =>
        props.isRounded
            ? props.theme.sizes.xl10
            : props.radius
            ? props.theme.sizes[props.radius]
            : props.theme.sizes.xxs};
    border: solid 2px
        ${(props) =>
            props.disabled
                ? props.theme.color.grey300
                : props.isError
                ? props.theme.color.error
                : props.theme.color.lightGrey};
    margin: ${(props) => props.margin || ""};
    background: ${(props) =>
        props.backgroundColor
            ? props.theme.color[props.backgroundColor]
            : props.disabled
            ? props.theme.color.grey300
            : props.theme.color.white};
    line-height: 1.3;
    min-height: ${(props) => (props.size === "xs" ? "35px" : "40px")};
    box-sizing: border-box;
    padding: ${(props) =>
        props.size === "s"
            ? ".7rem 1.8rem"
            : props.size === "xs"
            ? ".5rem 1.4rem"
            : "1.2rem 1.8rem"};
    padding-right: 20px;

    .options-wraper {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 5;
        margin-top: 8px;
    }
    .bottomSelectIcon {
        width: 30px;
        transition: all ease 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transform: ${(props) =>
            props.isShow ? " rotateZ(180deg)" : " rotateZ(0deg)"};
    }
`;
const OptionWrapper = styled.div`
    cursor: pointer;
    background-color: ${(props) => props.theme.color.darkGrey};
    border-radius: 0px 0 10px 10px;
    transition: all ease 0.3s;
    max-height: ${(props) => (props.isShow ? "200px" : "0px")};
    overflow: ${(props) => (props.isShow ? "unset" : "hidden")};
    overflow-y: ${(props) => (props.isShow ? "scroll" : "hidden")};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    ::-webkit-scrollbar {
        width: 10px;
        background-color: ${(props) => props.theme.color.iconBgGrey};
        opacity: 0.3;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.lightGrey};
        border-radius: 10px;
    }
`;
const Options = styled(Text)`
    padding: 0.25em 10px;
    transition: all 0.2s;
    border-bottom: 1px solid ${(props) => props.theme.color.white};
    color: ${(props) =>
        props.theme.color[props.isHighlighted ? "darkGrey" : "black"]};
    background-color: ${(props) =>
        props.theme.color[props.isHighlighted ? "iconBgGrey" : "white"]};
    :hover {
        background-color: ${(props) => props.theme.color.iconBgGrey};
        color: ${(props) => props.theme.color.darkGrey};
    }
`;

const Label = styled.label`
    display: block;
    margin-bottom: 0.8rem;
    .sup {
        color: ${(props) => props.theme.color.red100};
        font-size: 2.5rem;
        margin-left: -0.3rem;
        margin-top: 1rem;
    }
`;
const DropDownAlt = ({
    data,
    label,
    required,
    selected,
    onChange,
    disabled,
    margin = "0",
    fontSize = "m",
    borderColor = "secondary",
}) => {
    const [isSelected, setIsSelected] = useState(false);

    const showBottomLineDropMenu = () => {
        setIsSelected((prev) => !prev);
    };
    const { width } = useWindowDimensions();

    return (
        <div>
            {label && (
                <Label>
                    <P>
                        {label}
                        {required && <span className="sup">*</span>}
                    </P>
                </Label>
            )}
            <DropWrapper
                margin={margin}
                role="button"
                tabIndex="0"
                disabled={disabled}
                onClick={disabled ? null : showBottomLineDropMenu}
                isShow={isSelected}
                borderColor={borderColor}
                className="drop-wrapper"
            >
                <P weight="rg" color="darkGrey">
                    {width <= MOBILE_BREAKPOINT && selected.length > 23
                        ? `${selected.slice(0, 24)}...`
                        : selected}
                </P>
                <OptionWrapper isShow={isSelected} className="options-wraper">
                    {data.map((list, index) => (
                        <Options
                            size={fontSize}
                            isHighlighted={list === selected}
                            role="button"
                            tabIndex={index}
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange(data[index]);
                                setIsSelected(false);
                            }}
                            key={index}
                        >
                            {list}
                        </Options>
                    ))}
                </OptionWrapper>
                <InlineText className="bottomSelectIcon" size="m">
                    <AiFillCaretDown />
                </InlineText>
            </DropWrapper>
        </div>
    );
};

export default DropDownAlt;
