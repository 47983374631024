import { faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import Text from "../components/text/Text";
import theme from "../theme";
import { EditBtn, SendBtn } from "./BottomLineEmailInput";
import { BottomContainer, BottomInputWrapper } from "./BottomLineInput";

const RefInput = ({
    inputRef,
    onChange,
    value,
    label,
    oldValue,
    currentValue,
    margin = "0px 0 40px 0",
    Component = "input",
    error,
    onClick,
    icon = true,
    ...rest
}) => {
    const handleFocus = () => {
        inputRef.current.focus();
    };

    const onClickHandler = useCallback(() => {
        if (error || !currentValue) return;
        onClick();
    }, [onClick, error, currentValue]);
    return (
        <>
            <BottomContainer margin={margin}>
                <Text color="darkSecondary" size="m">
                    {label}
                </Text>

                <BottomInputWrapper size="m">
                    <Component
                        onChange={onChange}
                        value={currentValue}
                        autoComplete="off"
                        style={{ paddingRight: "30px" }}
                        ref={inputRef}
                        {...rest}
                    />
                    {icon && (
                        <>
                            {oldValue !== currentValue ? (
                                <SendBtn
                                    color="white"
                                    role="button"
                                    onClick={onClickHandler}
                                    size="m"
                                    disabled={
                                        oldValue === currentValue ||
                                        currentValue === ""
                                    }
                                >
                                    <FontAwesomeIcon
                                        color={theme.color.primary}
                                        icon={faPaperPlane}
                                    />
                                </SendBtn>
                            ) : (
                                <EditBtn
                                    onClick={handleFocus}
                                    color="white"
                                    role="button"
                                    size="m"
                                >
                                    <FontAwesomeIcon
                                        color={theme.color.black}
                                        icon={faEdit}
                                    />
                                </EditBtn>
                            )}
                        </>
                    )}
                </BottomInputWrapper>
                {/* {error && <ErrorText size="s">{error}</ErrorText>} */}
            </BottomContainer>
        </>
    );
};

export default RefInput;
