import React from "react";
import styled from "styled-components";
import Text from "../text/Text";

const InvestmentStatusTable = styled.div`
    display: grid;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.32);
    margin-top: 3rem;
    grid-template-columns: ${(props) =>
        `repeat(${props.repeat}, minmax(80px, 1fr))`};

    justify-content: space-between;
    > div {
        padding: 0.5rem 1rem;
    }
    align-content: center;
    overflow-x: auto;
    height: 100%;
    .column {
        background-color: ${(props) => props.theme.color.grey200};
        text-align: center;
        word-wrap: break-word;
        .cancelIcon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            transition: all ease-in 0.3s;
            opacity: 0.71;
            :hover {
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.32);
                cursor: pointer;
                opacity: 1;
            }
        }
    }
    .head {
        background-color: ${(props) => props.theme.color.grey400};
    }
    .more {
        grid-column: 1 / -1;
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
        > div {
            padding: 0.5rem 0.5rem;
        }
    }
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        > div {
            padding: 0.5rem 0.1rem;
        }
    }
`;
const GridTable = ({ heading, repeat = 8, rows = "auto", children }) => {
    return (
        <InvestmentStatusTable repeat={repeat} rows={rows} className="grid">
            {heading.map((item) => {
                let isSpillable = item.includes("/");

                return (
                    <div className="column head" key={item}>
                        {isSpillable ? (
                            <>
                                <Text
                                    weight="500"
                                    fontFamily="Montserrat"
                                    size="s"
                                    align="center"
                                >
                                    {item.split("/")[0]}
                                </Text>
                                <Text
                                    weight="500"
                                    fontFamily="Montserrat"
                                    size="s"
                                    align="center"
                                >
                                    {item.split("/")[1]}
                                </Text>
                            </>
                        ) : (
                            <Text
                                align="center"
                                weight="500"
                                fontFamily="Montserrat"
                                size="s"
                            >
                                {item}
                            </Text>
                        )}
                    </div>
                );
            })}
            {children}
        </InvestmentStatusTable>
    );
};

export default GridTable;
