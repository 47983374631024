import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    SET_REDIRECT_PATH,
    SHOW_LOGIN_FORM,
} from "../constants/contexConstant";
import { useContextState } from "../context/ContextProvider";
import useAuth from "./useAuth";

const useAuthHistory = () => {
    const { isAuth } = useAuth();
    const { state, dispatch } = useContextState();
    const history = useHistory();

    const [path, setPath] = useState(null);

    useEffect(() => {
        // Fetch user state on reload on a page when user is loggedin
        const init = async () => {
            if (path) {
                if (!state.user && !isAuth) {
                    dispatch({ type: SET_REDIRECT_PATH, payload: path });
                    dispatch({ type: SHOW_LOGIN_FORM });
                } else {
                    history.push(path);
                }
                setPath(null);
            }
        };
        init();
    }, [dispatch, history, isAuth, path, state.user]);

    return { path, setPath };
};

export default useAuthHistory;
