import fileDownload from "js-file-download";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IMAGE } from "../../assets";
import Space from "../../components/Spacing/Space";
import AlertNew from "../../components/alert/AlertNew";
import Button from "../../components/button/Button";
import Circle from "../../components/circle/Circle";
import Flex from "../../components/flex/Flex";
import Line from "../../components/line/Line";
import ModalAlt from "../../components/modal/ModalAlt";
import Container from "../../components/styles/Container";
import GridTable from "../../components/table/GridTable";
import { InlineText } from "../../components/text/HeadingText";
import Text from "../../components/text/Text";
import { ERROR_MSG, OPPORTUNITY_DETAILS_SUCCESS } from "../../constants/common";
import {
    GENERATE_DOC_ENDPOINT,
    OPPORTUNITY_DETAILS_ENDPOINT,
    PORTFOLIO,
    PORTFOLIO_INVESTMENT_ID,
    PROFILE_API_ENDPOINT,
    SIGN_DOCUMENT_DOWNLOAD_ENDPOINT,
    VERIFY_GENERATE_DOC,
} from "../../constants/routes";
import { getProjectDetailsData } from "../../helpers/projectHelper";
import useAuthHistory from "../../hooks/useAuthHistory";
import useNetwork from "../../hooks/useNetwork";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReturnButton } from "../../projects/ProjectDetailsWrap";
import { CardContainer } from "../../projects/project-details/DetailsSidebar";
import ProjectDetailsCard from "../../projects/project-details/ProjectDetailsCard";
import QuickKYComplete from "../../projects/project-details/QuickKYComplete";
import { signDocument } from "../../services/digio";
import request from "../../services/request";
import theme from "../../theme";

const TimelineItem = styled.div`
    padding: 0 0.5rem 2rem 1rem;
    position: relative;
    ::after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: ${(props) =>
            props.isActive
                ? props.theme.color.cyan
                : props.theme.color.grey600};
        border-radius: 50%;
        display: ${(props) => (props.circleOnly ? "block" : "none")};
        top: 2px;
        left: -10px;
        position: absolute;
    }
    .circleIcon {
        display: flex;
        top: 2px;
        left: -10px;
        position: absolute;
        width: 20px;
        height: 20px;
    }
    ::before {
        content: "";
        width: 3px;
        height: 100%;
        background-color: ${(props) =>
            props.isActive
                ? props.theme.color.cyan
                : props.theme.color.grey600};

        display: block;
        top: 2px;
        left: -2px;
        position: absolute;
    }

    :last-child {
        ::before {
            display: none;
        }
    }
`;

let getTimelineDate = (listing, invStatDate, installMents) => {
    let startDate = invStatDate ? Number(invStatDate * 1000) : "";
    let procuringDate = listing?.procuringDate
        ? Number(listing?.procuringDate * 1000)
        : "";
    let installationDate = listing?.installationDate
        ? Number(listing?.installationDate * 1000)
        : "";
    let activationDate = listing?.activationDate
        ? Number(listing?.activationDate * 1000)
        : "";
    let endDate =
        activationDate && installMents
            ? moment(activationDate).add(installMents, "M").valueOf()
            : "";
    return [
        {
            id: 1,
            isCompleted: startDate !== "" && Date.now() >= startDate,
            status: "Paid",
            date: startDate && moment(startDate).format("Do MMM YYYY"),
            summary: "Hosting site survey and Host financial vetting completed",
        },
        {
            id: 2,
            isCompleted: procuringDate !== "" && Date.now() >= procuringDate,
            status: "Procuring",
            date: procuringDate && moment(procuringDate).format("Do MMM YYYY"),
            summary: "Project constructed and installed by our EPC partner.",
        },
        {
            id: 3,
            isCompleted:
                installationDate !== "" && Date.now() >= installationDate,
            status: "Installing",
            date:
                installationDate &&
                moment(installationDate).format("Do MMM YYYY"),
            summary:
                "Project Activation and Integration of project monitoring services.",
        },
        {
            id: 4,
            isCompleted: activationDate !== "" && Date.now() >= activationDate,
            status: "Active",
            date:
                activationDate && moment(activationDate).format("Do MMM YYYY"),
            summary:
                "This digital solar project is open for reserving capacity.",
        },
        {
            id: 5,
            isCompleted: endDate !== "" && Date.now() >= endDate,
            status: "Closed",
            date: endDate && moment(endDate).format("Do MMM YYYY"),
            summary:
                "This digital solar project is open for reserving capacity.",
        },
    ];
};

const portfolioHeader = [
    "Id",
    "Date",
    "Amount",
    "Solar",
    "Monthly",
    "Lifetime",
    "Status",
];
const PortfolioDetails = () => {
    const { id, key } = useParams();
    const [body] = useState({
        id,
    });

    const [showModal, setShowModal] = useState(false);
    const { setPath } = useAuthHistory();
    const handleShowModal = () => {
        setShowModal(!showModal);
    };
    const downloadHandler = useCallback(async (link) => {
        try {
            const element = document.createElement("a");
            element.href = link;
            element.download = link.split("/")[2];
            document.body.appendChild(element);
            element.click();
        } catch (err) {
            console.log(err);
        }
    }, []);

    const [portfolioLoading, setPortfolioLoading] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [portFolioErr, setPortfolioErr] = useState("");
    const [viewPayouts, setViewPayouts] = useState([]);

    useEffect(() => {
        const sendRequest = async () => {
            setPortfolioLoading(true);
            try {
                let res = await request.authGet({
                    endpoint: `${PORTFOLIO_INVESTMENT_ID}/${key}`,
                });
                setPortfolioData(res);
                setPortfolioLoading(false);
                setPortfolioErr("");
            } catch (err) {
                const checkedErr = typeof err === "string" ? err : ERROR_MSG;
                setPortfolioErr(checkedErr);
                toast.error(checkedErr);
                setPortfolioLoading(false);
            }
        };

        sendRequest();
    }, []);

    const { error, data, loading } = useNetwork(
        OPPORTUNITY_DETAILS_ENDPOINT,
        "POST",
        body,
        OPPORTUNITY_DETAILS_SUCCESS
    );
    const [openKycModal, setOpenKycModal] = useState(false);
    const onKycStatusClick = () => {
        setOpenKycModal((openKycModal) => !openKycModal);
    };
    const verifyGenerateDocumentHandler = async () => {
        try {
            const verifyResponse = await request.authPost({
                endpoint: VERIFY_GENERATE_DOC,
                body: {
                    investmentId: key,
                },
                errorData: true,
            });
            if (
                verifyResponse.status === "SUCCESS" &&
                verifyResponse.data.investment
            ) {
                setPortfolioData(verifyResponse);
            }
        } catch (error) {}
    };
    const singDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authPost({
                endpoint: GENERATE_DOC_ENDPOINT,
                body: {
                    investmentId: key,
                    signType: "electronic",
                },
                errorData: true,
            });

            const identifier = response?.data?.investor_identifier;
            await signDocument(
                response?.data?.document_id,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            const maxLimit =
                data?.data?.listing?.listingMaxLimit === undefined ||
                isNaN(data?.data?.listing?.listingMaxLimit)
                    ? 0
                    : Number(data?.data?.listing?.listingMaxLimit);

            if (error?.data?.code === "EXCEEDED") {
                toast.error(
                    `Exceeded MAX Investment Limit, you can max invest ₹${(
                        maxLimit - Number(error?.data?.amountInvested)
                    ).toLocaleString()} only`
                );

                toast.info(
                    `Want to invest above ${maxLimit}, drop a mail to founders@srot.io`
                );
                return;
            }
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [key]);

    const signGeneratedDocumentHandler = useCallback(async () => {
        try {
            const response = await request.authGet({
                endpoint: PROFILE_API_ENDPOINT,
                errorData: true,
            });

            let res = await request.authGet({
                endpoint: `${PORTFOLIO_INVESTMENT_ID}/${key}`,
            });

            let identifier;
            if (
                res?.data?.investment &&
                res?.data?.investment?.investorIdentifierType === "email"
            ) {
                identifier = response?.data?.investor_profile.email;
            } else {
                let phoneNumber = response?.data?.investor_profile?.phoneNumber;
                if (phoneNumber.startsWith("91")) {
                    phoneNumber = phoneNumber.substring(2);
                }
                identifier = phoneNumber.slice(-10);
            }

            await signDocument(
                res?.data?.investment?.digioDocId,
                identifier,
                verifyGenerateDocumentHandler
            );

            return;
        } catch (error) {
            const maxLimit =
                data?.data?.listing?.listingMaxLimit === undefined ||
                isNaN(data?.data?.listing?.listingMaxLimit)
                    ? 0
                    : Number(data?.data?.listing?.listingMaxLimit);

            if (error?.data?.code === "EXCEEDED") {
                toast.error(
                    `Exceeded MAX Investment Limit, you can max invest ₹${(
                        maxLimit - Number(error?.data?.amountInvested)
                    ).toLocaleString()} only`
                );

                toast.info(
                    `Want to invest above ${maxLimit}, drop a mail to founders@srot.io`
                );
                return;
            }
            toast.error(
                error?.message && typeof error?.message === "string"
                    ? error?.message
                    : ERROR_MSG
            );
            return;
        }
    }, [key]);

    const downloadInvestedAgreement = useCallback(async (investmentId) => {
        try {
            const response = await request.authPost({
                endpoint: SIGN_DOCUMENT_DOWNLOAD_ENDPOINT,
                body: {
                    investmentId: investmentId,
                },
                checkStatusCode: true,
            });
            const file = await response.blob();
            fileDownload(file, `investment-${investmentId}-doc.pdf`);
            toast.success("Document downloaded successfully!");
        } catch (err) {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        }
    }, []);

    let content = null;
    if (!loading && error) {
        content = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    {error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (!loading && !error && !data?.data?.listing?.listingId) {
        content = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    Projects Details Not Available
                </AlertNew>
            </Flex.Container>
        );
    }
    if (
        !loading &&
        !error &&
        data?.data?.listing?.listingId &&
        !portfolioLoading &&
        !portFolioErr &&
        portfolioData
    ) {
        const listing = data?.data?.listing || {};
        const installMents =
            Number(portfolioData?.data?.investment?.installments) || null;

        let projectData = getProjectDetailsData(listing);
        projectData.img = IMAGE;
        projectData.onDocumentClick = downloadHandler;

        let investedItem = portfolioData?.data?.investment;

        let startDate = "";
        if (investedItem) {
            startDate = investedItem?.investment_date;
        }

        content = (
            <>
                <Flex.Container justifyContent="space-between">
                    <ProjectDetailsCard data={projectData} />
                    <Flex.Item
                        width="40%"
                        tabletWidth="100%"
                        mobileWidth="100%"
                    >
                        <CardContainer>
                            <Space xlm={["0  0 2rem"]}>
                                <Text
                                    lineHeight="1.2em"
                                    weight="700"
                                    fontFamily="Montserrat"
                                >
                                    Timeline
                                </Text>
                            </Space>

                            {getTimelineDate(
                                listing,
                                startDate,
                                installMents
                            ).map((item) => (
                                <TimelineItem
                                    key={item.id}
                                    isActive={item.isCompleted}
                                    circleOnly
                                >
                                    <Flex.Container justifyContent="space-between">
                                        <Space xlm={["0 .5rem 0 0"]}>
                                            <Flex.Item flex="1">
                                                <Text
                                                    size="m"
                                                    weight="700"
                                                    fontFamily="Montserrat"
                                                    lineHeight="1.2em"
                                                >
                                                    {item.status}
                                                </Text>
                                                <Space xlm={[".2rem 0 0 0"]}>
                                                    <Text
                                                        color="lightGrey"
                                                        size="s"
                                                        fontFamily="Montserrat"
                                                    >
                                                        {item.summary}
                                                    </Text>
                                                </Space>
                                            </Flex.Item>
                                        </Space>

                                        <Text
                                            size="s"
                                            lineHeight="1.2em"
                                            fontFamily="Montserrat"
                                        >
                                            {item.date}
                                        </Text>
                                    </Flex.Container>
                                </TimelineItem>
                            ))}
                        </CardContainer>
                    </Flex.Item>
                </Flex.Container>
            </>
        );
    }
    let portfolioContent = null;

    if (!portfolioLoading && portFolioErr) {
        portfolioContent = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    {error}
                </AlertNew>
            </Flex.Container>
        );
    }
    if (!portfolioLoading && !portFolioErr && !portfolioData) {
        portfolioContent = (
            <Flex.Container jusTifyContent="center">
                <AlertNew color="error" backgroundColor="danger">
                    Portfolio Details Not Available
                </AlertNew>
            </Flex.Container>
        );
    }

    if (
        !loading &&
        !error &&
        data?.data?.listing?.listingId &&
        !portfolioLoading &&
        !portFolioErr &&
        portfolioData
    ) {
        let investedItem = portfolioData?.data?.investment;

        if (!investedItem) {
            portfolioContent = (
                <Flex.Container jusTifyContent="center">
                    <AlertNew color="error" backgroundColor="danger">
                        Portfolio Details Not Available
                    </AlertNew>
                </Flex.Container>
            );
        } else {
            portfolioContent = (
                <GridTableOverRide>
                    <GridTable
                        repeat={7}
                        heading={portfolioHeader}
                        rows="auto auto 150px"
                    >
                        <Text
                            className="column"
                            fontFamily="Montserrat"
                            size="s"
                        >
                            {investedItem?.investment_id}
                        </Text>

                        <div className="column">
                            <Text fontFamily="Montserrat" size="s">
                                {moment(
                                    Number(
                                        investedItem?.investment_date * 1000
                                    ) || 0
                                ).format("DD-MM-YYYY")}
                            </Text>
                        </div>
                        <div className="column">
                            <Text fontFamily="Montserrat" size="s">
                                {investedItem?.investment_amount}
                            </Text>
                        </div>
                        <div className="column">
                            <Text fontFamily="Montserrat" size="s">
                                {Number(
                                    investedItem?.solar_asset_owned
                                ).toFixed(2)}
                            </Text>
                        </div>

                        <Text
                            className="column"
                            fontFamily="Montserrat"
                            size="s"
                        >
                            {Number(investedItem?.monthly_net_payout).toFixed(
                                2
                            )}
                        </Text>

                        <Text
                            className="column"
                            fontFamily="Montserrat"
                            size="s"
                        >
                            {investedItem?.pending_installments}
                        </Text>

                        <Text
                            className="column"
                            fontFamily="Montserrat"
                            size="s"
                        >
                            {investedItem?.investment_status}
                        </Text>
                        <div style={{ padding: "2rem 1rem" }} className="more">
                            <ProgressSteps
                                signGeneratedDocumentHandler={
                                    signGeneratedDocumentHandler
                                }
                                onPayoutsClick={() =>
                                    setViewPayouts(investedItem?.payout_orders)
                                }
                                onDownloadClick={() =>
                                    downloadInvestedAgreement(
                                        investedItem?.investment_id
                                    )
                                }
                                singDocumentHandler={singDocumentHandler}
                                onKycStatusClick={onKycStatusClick}
                                kycStatus={investedItem?.investmentKYCStatus}
                                investmentId={investedItem?.investment_id}
                                digioStatus={investedItem?.digioStatus}
                                payoutOrders={investedItem?.payout_orders}
                                handleShowModal={handleShowModal}
                            />
                        </div>
                    </GridTable>
                </GridTableOverRide>
            );
        }
    }

    return (
        <Space xxsm={["9rem 0 4rem"]}>
            <div>
                <Container>
                    <ReturnButton onClick={() => setPath(PORTFOLIO)} />

                    {content}
                    {portfolioContent}
                    <QuickKYComplete
                        openKycModal={openKycModal}
                        onClose={() => setOpenKycModal(false)}
                        onToggleKyc={onKycStatusClick}
                    />
                </Container>
                <ModalAlt
                    isOpen={showModal}
                    onClose={handleShowModal}
                    innerTimes={false}
                >
                    <Space padding="30px 0 50px">
                        <Text align="center" size="l" weight="400">
                            Payouts are not generated yet. Please try again
                            later.
                        </Text>
                    </Space>

                    <Flex.Container justifyContent="center">
                        <Button
                            type="solid"
                            backgroundColor="error"
                            size="s"
                            onClick={handleShowModal}
                        >
                            OK
                        </Button>
                    </Flex.Container>
                </ModalAlt>
                <ModalAlt
                    width={["100%", "100%", "75%"]}
                    isOpen={viewPayouts.length > 0}
                    onClose={() => setViewPayouts([])}
                    innerTimes={false}
                >
                    <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                        <GridTable repeat={7} heading={payoutsHeader}>
                            {viewPayouts.length > 0 &&
                                viewPayouts
                                    .sort(
                                        (a, b) =>
                                            a.payout_number - b.payout_number
                                    )
                                    .map((item, i) => (
                                        <React.Fragment key={i}>
                                            <PayoutsColumn
                                                value={item?.payout_number}
                                            />
                                            <PayoutsColumn
                                                value={moment(
                                                    Number(
                                                        item?.payout_date * 1000
                                                    ) || 0
                                                ).format("DD/MM/YYYY")}
                                            />
                                            <PayoutsColumn
                                                value={item?.gross_payout}
                                            />
                                            <PayoutsColumn
                                                value={item?.capital_payout}
                                            />
                                            <PayoutsColumn
                                                value={item?.profit_payout}
                                            />
                                            <PayoutsColumn
                                                value={
                                                    item?.remuneration_payout
                                                }
                                            />
                                            <PayoutsColumn
                                                value={
                                                    item?.status === "CREATED"
                                                        ? "Pending"
                                                        : item?.status ===
                                                          "PROCESSED"
                                                        ? "Paid"
                                                        : "Failed"
                                                }
                                            />
                                        </React.Fragment>
                                    ))}
                        </GridTable>
                    </div>
                </ModalAlt>
            </div>
        </Space>
    );
};

const PayoutsColumn = ({ value }) => {
    return (
        <div className="column">
            <Text fontFamily="Montserrat" weight="500" size="s">
                {value}
            </Text>
        </div>
    );
};

const payoutsHeader = [
    "S.NO",
    "Date",
    "Payout",
    "Capital Payout",
    "Profit Payout",
    "Remuneration Payout",
    "Status",
];

export default PortfolioDetails;
const TextButton = styled.button`
    border: none;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 30%);
    background-color: ${(props) =>
        props.bg ? props.theme.color[props.bg] : ""};
    padding: 0.5rem;
    :hover {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }
`;

const CircleExtend = styled(Circle)`
    position: relative;
    padding: 0;
    font-size: 1.5rem;
    background-color: ${(props) =>
        props.isActive ? props.theme.color.cyan : props.theme.color.grey600};
    color: ${(props) =>
        props.isActive ? props.theme.color.white : props.theme.color.grey600};
    span {
        position: absolute;
        top: 30px;
        color: black;
        width: max-content;
    }
`;
const InlineLabel = styled(InlineText)`
    display: inline-block;
    color: ${(props) => props.theme.color.primary};
    @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
        display: ${(props) => (props.isActive ? "inline-block" : "none")};
    }
`;

const ProgressSteps = ({
    onPayoutsClick,
    onDownloadClick,
    onKycStatusClick,
    kycStatus,
    digioStatus,
    singDocumentHandler,
    signGeneratedDocumentHandler,
    payoutOrders,
    handleShowModal,
}) => {
    const activeStatus =
        kycStatus === "PENDING"
            ? 0
            : digioStatus === "SIGNED"
            ? payoutOrders && payoutOrders.length > 0
                ? 3
                : 2
            : 1;
    const { width } = useWindowDimensions();

    const steps = [
        {
            step: 1,
            label: "",
            topLabel: "KYC Status",
            component: () =>
                kycStatus === "PENDING" ? (
                    <TextButton onClick={onKycStatusClick} bg={"babyPink"}>
                        <Text
                            role="button"
                            color={"black"}
                            size="s"
                            align="center"
                        >
                            Complete Your KYC
                        </Text>
                    </TextButton>
                ) : (
                    <TextButton disabled={true} bg={"success"}>
                        <Text
                            role="button"
                            color="black"
                            size="s"
                            align="center"
                        >
                            KYC Completed
                        </Text>
                    </TextButton>
                ),
        },
        {
            step: 2,
            label: "",
            topLabel: "Investment Agreement",
            component: () =>
                digioStatus !== "SIGNED" ? (
                    <TextButton
                        onClick={
                            digioStatus === "GENERATED"
                                ? signGeneratedDocumentHandler
                                : singDocumentHandler
                        }
                        bg={
                            kycStatus === "PENDING"
                                ? ""
                                : digioStatus === "GENERATED"
                                ? "orange"
                                : "babyPink"
                        }
                        disabled={kycStatus === "PENDING"}
                    >
                        {digioStatus === "GENERATED" ? (
                            <Text
                                role="button"
                                color={
                                    kycStatus === "PENDING"
                                        ? "lightGrey"
                                        : "black"
                                }
                                size="s"
                                align="center"
                            >
                                Continue LLP Agreement
                            </Text>
                        ) : (
                            <Text
                                role="button"
                                color={
                                    kycStatus === "PENDING"
                                        ? "lightGrey"
                                        : "black"
                                }
                                size="s"
                                align="center"
                            >
                                Sign LLP Agreement
                            </Text>
                        )}
                    </TextButton>
                ) : (
                    <Flex.Container
                        style={{ display: "inline-flex" }}
                        direction="column"
                        justifyContent="center"
                    >
                        <TextButton disabled={true} bg={"success"}>
                            <Text
                                role="button"
                                color="black"
                                size="s"
                                align="center"
                            >
                                LLP Agreement Signed
                            </Text>
                        </TextButton>
                        <TextButton
                            style={{ boxShadow: "none" }}
                            onClick={onDownloadClick}
                        >
                            <Text
                                role="button"
                                decoration="underline"
                                color="lightGrey"
                                size="xxs"
                                align="center"
                            >
                                (Click to Download)
                            </Text>
                        </TextButton>
                    </Flex.Container>
                ),
        },

        {
            step: 3,
            label: "",
            topLabel: "Investment Payouts",
            component: () =>
                payoutOrders && payoutOrders.length > 0 ? (
                    <TextButton
                        onClick={onPayoutsClick}
                        disabled={digioStatus !== "SIGNED"}
                        bg={digioStatus !== "SIGNED" ? "" : "success"}
                    >
                        <Text
                            role="button"
                            color={
                                digioStatus !== "SIGNED" ? "lightGrey" : "black"
                            }
                            size="s"
                            align="center"
                        >
                            Payouts Generated
                        </Text>
                    </TextButton>
                ) : (
                    <TextButton
                        onClick={handleShowModal}
                        disabled={digioStatus !== "SIGNED"}
                        bg={digioStatus !== "SIGNED" ? "" : "orange"}
                    >
                        <Text
                            role="button"
                            color={
                                digioStatus !== "SIGNED" ? "lightGrey" : "black"
                            }
                            size="s"
                            align="center"
                        >
                            Payouts Not Generated
                        </Text>
                    </TextButton>
                ),
        },
    ];

    return (
        <>
            {width > 768 ? (
                <Flex.Container
                    style={{ padding: "3rem 0 4rem", marginBottom: "1rem" }}
                    alignItems="center"
                >
                    {steps.map((item, index) => (
                        <>
                            <CircleExtend
                                isActive={activeStatus >= item.step}
                                size="25px"
                            >
                                <BiCheck />
                                <InlineLabel
                                    style={{
                                        left: index === 0 ? 0 : null,
                                        right:
                                            steps.length - 1 === index
                                                ? 0
                                                : null,
                                        top: -30,
                                        color: theme.color.black800,
                                    }}
                                    align={
                                        index === 0
                                            ? "left"
                                            : index === steps.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                    size="s"
                                >
                                    {item.topLabel}
                                </InlineLabel>
                                <InlineLabel
                                    isActive={item.step === activeStatus}
                                    size="s"
                                    color="cyan"
                                    style={{
                                        left: index === 0 ? 0 : null,
                                        right:
                                            steps.length - 1 === index
                                                ? 0
                                                : null,
                                        color: theme.color.black800,
                                    }}
                                    align={
                                        index === 0
                                            ? "left"
                                            : index === steps.length - 1
                                            ? "right"
                                            : "center"
                                    }
                                >
                                    {item.component
                                        ? item.component()
                                        : item.topLabel}
                                </InlineLabel>
                            </CircleExtend>
                            {index < steps.length - 1 && (
                                <Line
                                    backgroundColor={
                                        activeStatus >= item.step
                                            ? "cyan"
                                            : "grey600"
                                    }
                                    flex="1"
                                    height="4px"
                                />
                            )}
                        </>
                    ))}
                </Flex.Container>
            ) : (
                <div style={{ marginLeft: "1rem" }}>
                    {steps.map((item) => (
                        <TimelineItem
                            key={item.id}
                            isActive={activeStatus >= item.step}
                        >
                            <CircleExtend
                                className="circleIcon"
                                isActive={activeStatus >= item.step}
                            >
                                <BiCheck />
                            </CircleExtend>
                            <Flex.Container justifyContent="space-between">
                                <Space xlm={["0 .5rem 0 0"]}>
                                    <Flex.Item flex="1">
                                        <Text
                                            size="m"
                                            fontFamily="Montserrat"
                                            lineHeight="1.2em"
                                        >
                                            {item.topLabel}
                                        </Text>
                                        <Space xlm={[".2rem 0 0 0"]}>
                                            <Text
                                                color="lightGrey"
                                                size="s"
                                                fontFamily="Montserrat"
                                            >
                                                {item.component &&
                                                    item.component()}
                                            </Text>
                                        </Space>
                                    </Flex.Item>
                                </Space>
                            </Flex.Container>
                        </TimelineItem>
                    ))}
                </div>
            )}
        </>
    );
};

const GridTableOverRide = styled.div``;
